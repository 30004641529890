import React from "react";
import { FormGrid } from "../primitives/Forms";
import { FormControlsForFields } from "../schemed";
import { FieldType } from "../../hooks/useSchema";
import { FormattedMessage } from "react-intl";

export interface Props {
  values: {label: string, value: string}[];
  value?: string;
  onChange: (id: string) => void;
}

export function SelectDictionary({values, value, onChange}: Props) {
  const sectionSchema = {
    type: FieldType.select,
    label: <FormattedMessage id="dictionaries.selectDictionary"/>,
    valueDict: values.reduce((r,{ value, label}) => ({ ...r, [value]: label }), {}),
    values
  }

  return (
    <FormGrid>
        <FormControlsForFields
          fields={[["dicts"]]}
          data={{ dicts: value}}
          schema={({
            dicts: sectionSchema
          })}
          onChange={(_, {dicts}) => onChange(dicts)}
        />
    </FormGrid>
  )
}