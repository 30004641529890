import React, { ReactElement, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { TeamReview } from './useTeamReview';
import { Team } from '../Public/useTeamRegistrationStageOne';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { FieldDefinition, FormControlsForFields, TableForFields } from '../../toolympus/components/schemed';
import { mergeSchema, useSchema } from '../../toolympus/hooks/useSchema';
import { PseudoLink } from '../../toolympus/components/primitives/Common.styles';
import { downloadFile } from '../../toolympus/api/core';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { Blank } from '../Common.styles';
import { Tooltip } from '../../toolympus/components/primitives/Tooltip';
import { GoToChatButton } from './Common';
import { DocumentInfo, getTeamDocumentName } from './DocumentsControls';
import { RecordsTags } from '../../toolympus/components/Tags';
import { ActionRow, OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';


interface Props {
    data: TeamReview;
    conflicts: RecordsTags;
}

const defaultRejectDetails = {
    approval_status: null,
    reject_explanation: "",
};

export const TeamReviewPopup = (props: Props) => {
    const { isOpen, close, approve, reject, data: team, isLoading, schema, update, hasChanges, save, downloadDocument, comments } = props.data;

    const [isApproving, setIsApproving] = useState<boolean>(false);
    const [isRejecting, setIsRejecting] = useState<boolean>(false);

    const [isEditingMembers, setIsEditingMembers] = useState<boolean>(false);
    const [isEditingCoaches, setIsEditingCoaches] = useState<boolean>(false);

    const [rejectDetails, setRejectDetails] = useState<Pick<Team, 'approval_status' | 'reject_explanation'>>(defaultRejectDetails);

    const { member: memberSchema, coach: coachSchema } = useSchema();

    const doReject = () => {
        const { approval_status, reject_explanation } = rejectDetails || defaultRejectDetails;
        if(approval_status && reject_explanation && reject_explanation.trim().length) {
            reject(approval_status !== "rejected", reject_explanation).then(x => { setIsRejecting(false); });
        }
    }

    const teamControls = (fields: FieldDefinition[], withArea?: boolean) => (
        team && <FormControlsForFields
            data={team}
            schema={schema}
            fields={fields}
            onChange={(o,c) => update(c)}
            withArea={withArea}
            />
    )

    const doClose = () => {
        setIsEditingCoaches(false);
        setIsEditingMembers(false);
        close();
    }

    const doSave = () => {
        setIsEditingCoaches(false);
        setIsEditingMembers(false);
        save();
    }

    return (
        <>
        <Dialog open={isOpen} onClose={doClose} fullWidth maxWidth="lg">
            <DialogContent>
                <DialogTitle>
                    Команда
                    <Tooltip text="Комментарии">
                        {comments.button as ReactElement<any, any>}
                    </Tooltip>
                    <Tooltip text="Чат" withWrapper>
                        <GoToChatButton to={`/admin/chat-org/${team?._id}`} />
                    </Tooltip>
                    {isLoading && <LoadingIndicator />}
                </DialogTitle>
                <FormGrid columns="1fr 1fr" style={{ alignItems: 'flex-start' }}>
                    {teamControls([["email", { disabled: true }], ["number", { disabled: true }]])}
                    {team && team.pdf_application_uploaded ? <PseudoLink onClick={() => downloadFile(`/api/admin/team/pdf/${team._id}`, `team_${team.email}.pdf`)}>скачать скан заявки</PseudoLink> : <div/>}

                    {team && team.approval_status ? teamControls([["approval_status", { disabled: true }]]) : <div />}
                    <div/>
                    {team && team.reject_explanation ? teamControls([["reject_explanation", { autoRows: true }]]) : <div />}
                </FormGrid>

                <Blank />
                <Blank />
                <Typography variant="h6">Процессуальные документы</Typography>
                <DocumentInfo
                    key="doc1"
                    label="Жалоба"
                    document={team?.documents?.doc1}
                    download={id => downloadDocument(id, getTeamDocumentName("doc1", team))}
                    />
                <DocumentInfo
                    key="doc2"
                    label="Отзыв"
                    document={team?.documents?.doc2}
                    download={id => downloadDocument(id, getTeamDocumentName("doc2", team))}
                    />
                <Blank />
                {team && <PseudoLink onClick={() => downloadFile(`/api/assignment/documents/admin/feedback/${team._id}`, `Команда_${team.number || ""}.pdf`)}>скачать оценочные листы</PseudoLink>}
                <Blank />
                {team && !!team.finals_stage && <PseudoLink onClick={() => downloadFile(`/api/assignment/documents/admin/feedback-rounds/${team._id}`, `Команда_${team.number || ""}_(устные раунды).pdf`)}>скачать оценочные листы за раунды</PseudoLink>}
                

                <Blank />
                <Blank />
                <Typography variant="h6">Учебное заведение</Typography>
                <FormGrid columns="1fr 1fr"
                    areas={[
                        Array(2).fill("university").join(" "),
                        "department city",
                    ]}>
                    {teamControls([
                        ["university"],
                        ["department"],
                        ["city"]
                        ], true)}
                </FormGrid>

                <Blank />
                <Typography variant="h6">Конфликты</Typography>
                {team && props.conflicts.displayForRecord(team?._id)}

                <Blank />
                <Blank />
                <Typography variant="h6">Участники <IconButton size="small" onClick={() => setIsEditingMembers(true)}><EditOutlined /></IconButton></Typography>
                {team && <TableForFields
                    data={team.members}
                    schema={memberSchema}
                    onChange={(o,c) => props.data.updateArray("members", team.members.indexOf(o), c)}
                    onRowClick={() => setIsEditingMembers(true)}
                    fields={[
                        ["lastname"],
                        ["firstname"],
                        ["middlename"],
                        ["email"],
                        ["phone"],
                        ["study_year"],
                        ["is_contact"],
                        ["participates_finals"],
                    ].map(([f]) => [f, { editable: isEditingMembers }])}
                    rowButtons={r => <>
                        <IconButton size="small" onClick={() => props.data.updateArrayRemove("members", team.members.indexOf(r))}><DeleteOutlined /></IconButton>
                    </>}
                    />}

                <ActionRow>
                    <OccupyFreeSpace />
                    <Button onClick={() => props.data.updateArrayAdd("members")}>добавить</Button>
                </ActionRow>

                <Blank />
                <Blank />


                <Typography variant="h6">Тренеры <IconButton size="small" onClick={() => setIsEditingCoaches(true)}><EditOutlined /></IconButton></Typography>
                {team && <TableForFields
                    data={team.coaches}
                    schema={coachSchema}
                    onChange={(o,c) => props.data.updateArray("coaches", team.coaches.indexOf(o), c)}
                    onRowClick={() => setIsEditingCoaches(true)}
                    fields={[
                        ["lastname"],
                        ["firstname"],
                        ["middlename"],
                        ["email"],
                        ["phone"],
                        ["position"],
                        ["is_contact"],
                    ].map(([f]) => [f, { editable: isEditingCoaches }])}
                    rowButtons={r => <>
                        <IconButton size="small" onClick={() => props.data.updateArrayRemove("coaches", team.coaches.indexOf(r))}><DeleteOutlined /></IconButton>
                    </>}
                    />}

                    <ActionRow>
                        <OccupyFreeSpace />
                        <Button onClick={() => props.data.updateArrayAdd("coaches")}>добавить</Button>
                    </ActionRow>

            </DialogContent>
            <DialogActions>
                <Button onClick={doClose}>
                    закрыть
                </Button>
                {!hasChanges && <Button variant="contained" color="secondary" onClick={() => { setIsRejecting(true); setRejectDetails(defaultRejectDetails); }}>
                    отказать
                </Button>}
                {!hasChanges && <Button variant="contained" color="primary" onClick={() => setIsApproving(true)}>
                    утвердить
                </Button>}
                {hasChanges && <Button variant="contained" color="primary" onClick={doSave}>
                    сохранить
                </Button>}
            </DialogActions>
        </Dialog>

        <Dialog open={isApproving} onClose={() => setIsApproving(false)} fullWidth maxWidth="sm">
            <DialogContent>
                <Typography>Команде будет присвоен номер, обновлён статус и отправлено уведомление об утверждении.</Typography>
                <Typography>Продолжить?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsApproving(false)}>
                    отмена
                </Button>
                <Button variant="contained" color="primary" onClick={() => approve().then(() => { setIsApproving(false); })}>
                    утвердить
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={isRejecting} onClose={() => setIsRejecting(false)} fullWidth maxWidth="sm">
            <DialogContent>
                <Typography>У команды будет обновлён статус в личном кабинете и будет отправлено уведомление с указанным пояснением.</Typography>
                <FormGrid columns="1fr">
                    <FormControlsForFields
                        data={rejectDetails}
                        fields={[
                            ["approval_status"],
                            ["reject_explanation"],
                        ]}
                        schema={mergeSchema(schema, {
                            approval_status: { label: "Отказать", values: [{ value: "needs_fixes", label: "для доработки" }, { value: "rejected", label: "окончательно" }]},
                        })}
                        onChange={(o,c) => setRejectDetails({ ...o, ...c })}
                        />
                </FormGrid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsRejecting(false)}>
                    отмена
                </Button>
                <Button variant="contained" color="primary" onClick={doReject}>
                    отказать
                </Button>
            </DialogActions>
        </Dialog>
        </>
    );
}
