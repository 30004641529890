import { IconButton, List, makeStyles, withTheme } from '@material-ui/core';
import styled from '@emotion/styled';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Message } from './types';
import { useUser } from '../../userContext/UserContext';
import { useFormats } from '../schemed';
import { utc } from '../timezone';
import { MarkdownDisplay } from '../schemed/Markdown';
import { EditOutlined } from '@material-ui/icons';

export { SelectUserDialog } from "./SelectUsersDialog";

export const ChatsList = styled(List)`
    width: 100%;
    overflow-y: auto;
`;

export const MessengerContainer = styled.div<{chatsWidth: number}>`
    display: grid;
    grid-template-columns: ${props => props.chatsWidth}px 1fr;
    height: 100%;
    width: 100%;
`;
MessengerContainer.defaultProps = {className: 'MessengerContainer'};

export const ChatContainer = styled.div`
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    gap: 15px;
    height: 100%;
    max-height: 100%;
    overflow-y: hidden;
`;
ChatContainer.defaultProps = {className: 'ChatContainer'};

export const MessagesList = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
`;
MessagesList.defaultProps = {className: 'MessagesList'};

export const MessageEnterer = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 5px;
    align-items: center;
    padding-bottom: 3px;
`;
MessageEnterer.defaultProps = {className: 'MessageEnterer'};

export const MessageTextWrapper = styled.div`
    & p {
        margin: 0 0 5px;
    }
`;

export const MessageContent = withTheme(styled.div`
    position: relative;
    max-width: 60%;

    ${props => props.theme.breakpoints.down("xs")} {
        max-width: 100%;
    }

    & .edit-button {
        position: absolute;
        right: 0;
        bottom: 0;
        display: none;
    }

    &:hover {
        & .edit-button {
            display: block;
        }
    }
`);

const useStyles = makeStyles(theme => ({
    container: ({isUser}: {isUser: boolean}) => ({
        display: 'flex',
        padding: '10px 0',
        flexDirection: isUser? 'row' : 'row-reverse',
        width: '100%',
    }),

    avatar: ({isUser}: {isUser: boolean}) => ({
        height: 50,
        width: 50,
        flexShrink: 0,
        objectFit: 'cover',
        objectPosition: 'center',
        marginLeft: isUser? 0 : 10,
        marginRight: isUser? 10 : 1,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 20,
        fontWeight: 500,
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    }),

    name: ({isUser}: {isUser: boolean}) => ({
        alignSelf: isUser ? 'flex-start' : 'flex-end',
        justifyContent: isUser ? 'flex-start' : 'flex-end',
        marginBottom: 5,
        color: theme.palette.primary.main,
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'baseline',
        fontWeight: 300,
    }),

    time: {
        color: theme.palette.text.secondary,
        marginLeft: '1.5em',
        fontSize: '0.8em',
        fontWeight: 300,
        minWidth: 80,
    },
}));

type MessageItemType = (props: {
    authorName: string,
    message: Message,
    editMessage?: (message: Message) => void,

}) => JSX.Element

export const renderMessageText = (text: string): ReactNode => {
    return text.startsWith("!md")
        ? <MarkdownDisplay text={text.replace(/!md\s*/, "")} />
        : text.split('\n').map((line,idx) => <p key={idx} className={text}>{line}</p>);
}

export const stripMessageTextFormatting = (text: string): string => {
    return text.startsWith("!md")
        ? text.replace(/!md\s*/, "").replaceAll(/[*_[]+/g, "").replaceAll(/\]/g, " ")
        : text;
}

export const MessageItem: MessageItemType = ({ message, authorName, editMessage }) => {
    const { formatDatetimeShort } = useFormats();
    const { user } = useUser();
    const isMyMessage = !!user && user._id === message.author_id;
    const styles = useStyles({isUser: isMyMessage });

    const ref = useRef<HTMLImageElement | null>(null);
    const [avatarError, setAvatarError] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (ref.current) {
                ref.current.onerror = () => setAvatarError(true);
            }
        }, 0);
    }, []);

    return <div className={`${styles.container} message`}>
        {avatarError ? 
            <div className={`${styles.avatar} avatar`}>
                {authorName[0].toUpperCase() + (authorName.split(' ').length === 2)? (authorName[authorName.length - 1].toLocaleLowerCase()) : ''}
            </div>
            :
            <img ref={ref} className={styles.avatar} src={'/api/avatar/' + message.author_id} alt=""/>
        }
        <MessageContent className="content">
            <div className={styles.name}>
                {authorName}
                <div className={styles.time}>
                    {formatDatetimeShort(utc.toLocal(message.time))}
                </div>
            </div>
            <MessageTextWrapper className="text">
                {renderMessageText(message.text)}
            </MessageTextWrapper>
            {isMyMessage && editMessage && (
                <IconButton className="edit-button" size="small" onClick={() => editMessage(message)}>
                    <EditOutlined />
                </IconButton>)}
        </MessageContent>
    </div>
}