import { apiFetch, downloadFile, FetchTypes } from "../../toolympus/api/core";
import { OpenRegistrationReviewData, useOpenRegistrationReview } from "../../toolympus/components/Contests/Registrations";
import { FieldType, mergeSchema, useSchema } from "../../toolympus/hooks/useSchema";
import { Team } from "../Public/useTeamRegistrationStageOne";
import { FormComments, useFormComments } from "./useComments";

export interface TeamReview extends OpenRegistrationReviewData<Team> {
    approve: () => Promise<any>;
    reject: (isForFixes: boolean, explanation: string) => Promise<any>;
    comments: FormComments;

    downloadDocument: (document_id: string, filename: string) => void;
}

export const useTeamReview = (): TeamReview => {    
    const { team: teamSchema } = useSchema();
    const schema = mergeSchema(teamSchema, {
        reject_explanation: { type: FieldType.textlong },
        approval_status: { label: "Утверждена?"},
    })

    const data = useOpenRegistrationReview<Team>({
        apiPath: "/api/admin/team",
        schema,
    })
    
    const comments = useFormComments('team', data.id);
    
    const apiPath = `/api/admin/team/${data.id}`;
    const approve = () => {
        return apiFetch<Team>(`${apiPath}/approve`, FetchTypes.PUT, {
            approval_status: "approved" })
            .then(t => data.setData(t));
    }

    const reject = (isForFixes: boolean, reject_explanation: string) => {
        return apiFetch<Team>(`${apiPath}/approve`, FetchTypes.PUT, {
            approval_status: isForFixes ? "needs_changes" : "rejected",
            reject_explanation })
            .then(t => data.setData(t));
    }

    return {
        ...data,
        approve,
        reject,

        downloadDocument: (id, filename) => downloadFile(`/api/admin/document/${id}`, filename),

        comments,
    }
}