import { downloadFile } from "../../toolympus/api/core";
import { ScoringData, useScoring } from "../../toolympus/components/Contests/ScoringSheets";
import { LoadedData, useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { DocumentAccess, DocumentNames } from "../Team/useTeamDocuments";

export interface JudgeDocument extends DocumentAccess {
    _id: string;
    is_scored: boolean;
    team_number: string;
}

export interface JudgeDocumentsData extends LoadedData<JudgeDocument[]> {
    download: (d: JudgeDocument) => void;
}

const download = (d: JudgeDocument) => downloadFile(`/api/judge/document/${d.document_id}`, `${DocumentNames[d.document_kind as 'doc1' | 'doc2']}_команда_${d.team_number}.docx`);

export const useJudgeDocuments = (): JudgeDocumentsData => {
    const data = useLoadedData<JudgeDocument[]>("/api/assignment/documents/judge", []);

    return {
        ...data,
        download,
    };
}

export interface JudgeDocumentScoringData {
    document: JudgeDocument;
    isLoading: boolean;
    download: () => void;
    scoring: ScoringData;
}

export const useJudgeDocumentScoring = (id: string): JudgeDocumentScoringData => {
    const dataAll = useJudgeDocuments();
    const scoring = useScoring(`/api/scoring/score`, 'documents', id);
    const doc = dataAll.data.find(d => d._id === id);

    return {
        document: doc || {} as JudgeDocument,
        isLoading: dataAll.isLoading || !doc,
        download: () => doc && download(doc),
        scoring,
    }
}