import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Checkbox, Typography, withTheme } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActionRow, OccupyFreeSpace } from '../../primitives/ActionRow';
import { LoadingIndicator } from '../../primitives/LoadingIndicator';
import { GridStageData } from './useGridStage';
import { Player, Round } from './types';
import { FieldDefinition, TableForFields, useFormats } from '../../schemed';
import { utc } from '../../timezone';
import { SearchField } from '../../primitives/SearchField';
import { EditRoundPopup } from './EditRoundPopup';
import { EditRoundData } from './useEditRound';
import { SelectPlayer } from './useSelectPlayer';
import { SimpleActions } from '../../StatusAction';
import { ButtonOptionPicker } from '../../primitives/ButtonOptionPicker';
import { ClipboardButton } from '../../primitives/Buttons';
import { ProblemMessages, ProblemsContainer, RoundsContainerCSS, RoundCSS, RoundsSelectedText } from './Common';
import { QRCodesPopup } from './QRCodesPopup';
import { Check, EditOutlined } from '@material-ui/icons';
import { useGridSettings } from './GridSettingsContext';
import { MassEditRoundsPopup } from './MassEditRoundsPopup';

interface Props {
    data: GridStageData;
}

const RoundWrapper = withTheme(styled.li`
    ${props => RoundCSS(props)}

    & > .players-row {
        & .winner {
            border-bottom: 3px solid ${props => props.theme.palette.success.main};
        }
        & .overassigned {
            border-bottom: 3px solid ${props => props.theme.palette.error.main};
        }
    }

    & > .judges-row > .grid {
        display: grid;
        grid-template-columns: 1fr max-content;
        gap: 0 1rem;
        align-items: center;
        padding-left: 0.25rem;

        & .position {
            display: inline-block;
            min-width: 0.75rem;
        }
    }

    &.problem {
        background: #cc000020;
    }
    
    & > .problems {    
        padding: 0;
        color: ${props => props.theme.palette.error.dark};
    }

    & .item-problem {
        border-bottom: 2px solid ${props => props.theme.palette.error.dark};
    }
`);

const RoundsContainer = withTheme(styled.ul`
    ${props => RoundsContainerCSS(props)}
`);


interface RoundProps {
    round: Round;
    editRound: EditRoundData;
    selectPlayer: SelectPlayer;
    showDetails?: boolean;
    showScores?: boolean;
    isSelected: boolean;
    setIsSelected: (r: Round, isSelected: boolean) => void;
}

export const genArbiterPositions = (n: number | undefined) => {
    return Array.from(Array(n || 3)).map((_,p) => p+1);
}

const RoundDisplay = ({ round, editRound, selectPlayer, showDetails, showScores, isSelected, setIsSelected }: RoundProps) => {
    const {1: player1, 2: player2} = round.players;
    const { formatDatetimeShort } = useFormats();
    const { arbiter_links_enabled: links_enabled, judges_per_stage_round, get_arbiter_class } = useGridSettings();

    const getArbiterClass = get_arbiter_class || (() => "");

    const isPlayerOverassigned = (p: Player | null) => p && selectPlayer.roundsPerTeam && (selectPlayer.playersInfo[p._id]?.rounds_assigned || 0) > selectPlayer.roundsPerTeam;
    const isP1Overassinged = isPlayerOverassigned(player1);
    const isP2Overassinged = isPlayerOverassigned(player2);

    const hasProblems = round.problems && Object.keys(round.problems).length;

    const arbitersWithProblems = (round?.problems?.missing_scores?.arbiters_ids || []) as string[];
    const arbiterHasProblems = (id: string) => arbitersWithProblems.includes(id);

    const ArbiterDisplay = ({ position }: { position: number }) => {
        const arb = round.arbiters[position];
        return <>
            <Typography><span className="position">{position}.</span> <span className={`${arbiterHasProblems(arb?._id) ? 'item-problem' : ''} ${getArbiterClass(arb, ''+position)}`}>{arb ? arb.display_name : "-"}</span></Typography>
            {links_enabled && arb && arb.access_url && arb.access_url.length ? <ClipboardButton value={arb.access_url} stopPropagation /> : <div />}
        </>
    };


    return <RoundWrapper
        className={`round ${hasProblems || isP1Overassinged || isP2Overassinged ? "problem" : ""} selectable ${isSelected ? "selected" : ""}`}
        onClick={() => editRound.startEditing(round)}>
        <div className="select-checkbox">
            <Checkbox
                size="small"
                checked={isSelected}
                onChange={v => setIsSelected(round, v.target.checked)}
                onClick={e => { e.stopPropagation(); }}
                disableRipple
                />
        </div>
        <div className="title-row">
            <Typography key="title" variant="caption">{round.title}</Typography>
            <Typography key="time" variant="caption">{round.time ? formatDatetimeShort(utc.toLocal(round.time)) : ""}</Typography>
        </div>

        <div className="players-row">
            <div>
                <Typography key="p1" className={`player player1 ${round.winner === 1 ? "winner" : ""} ${isP1Overassinged ? "overassigned" : ""}`}
                    onClick={e => { e.stopPropagation(); selectPlayer.open({ round, position: 1 }, player1)}}>
                    {player1 ? player1.display_name : "-"}
                </Typography>
                {showScores && <Typography key="p1-score" className="score score1">
                    {round.player1_score ? round.player1_score.toFixed(2) : "-"}
                </Typography>}
            </div>

            <Typography key="vs" variant="caption"><FormattedMessage id="contests.rounds.round.labels.vs" /></Typography>
            <div>
                <Typography key="p2" className={`player player2 ${round.winner === 2 ? "winner" : ""} ${isP2Overassinged ? "overassigned" : ""}`}
                    onClick={e => { e.stopPropagation(); selectPlayer.open({ round, position: 2 }, player2)}}>
                    {player2 ? player2.display_name : "-"}
                </Typography>
                {showScores && <Typography key="p2-score" className="score score2">
                    {round.player2_score ? round.player2_score.toFixed(2) : "-"}
                </Typography>}
            </div>
        </div>

        {showDetails ?
            <div className="judges-row">
                <Typography key="arbs" variant="caption"><FormattedMessage id="contests.rounds.round.labels.arbiters" /></Typography>
                <div className="grid">
                    {genArbiterPositions((judges_per_stage_round || {})[round.stage_code]).map(p => <ArbiterDisplay key={p} position={p} />)}
                </div>
            </div>
        :
            <div className="judges-row">
                <Typography key="arbs" variant="caption">
                    <FormattedMessage id="contests.rounds.round.labels.arbiters" />: {Object.keys(round.arbiters)
                        .sort()
                        .map(aIdx => {
                            const a = round.arbiters[aIdx as unknown as number];
                            return <span className={`${arbiterHasProblems(a._id) ? 'item-problem' : ''} ${getArbiterClass(a, aIdx)}`}>{a.display_name}</span>
                        })
                        .reduce((r,a) => r.length === 0 ? [a] : [...r, <span key={r.length}>, </span>, a], [] as JSX.Element[])}
                </Typography>
            </div>}

        {showDetails && <div className="description">
            {(round.description || "").split("\n").map((line, i) => <Typography key={i} style={{ fontSize: '0.85em' }}>{line}</Typography>)}
        </div>}

        {showDetails && hasProblems && <div className="problems">
            <ProblemMessages problems={round.problems} />
        </div>}
    </RoundWrapper>;
}

const ViewLSKeyBase = "__tooly_grid_stage_view__";

export const GridStageForm = (props: Props) => {
    const {
        isLoading,
        stage,
        rounds,
        filter,
        setFilter,
        editRound,
        selectPlayer,
        selectArbiter,
        autoAssignArbiters,
        actions,
        isActionExecuting,
        massEditRounds,
        results,
        scoresQRCodesDialog,
    } = props.data;
    const { formatMessage } = useIntl();

    const ViewLSKey = `${ViewLSKeyBase}_${stage.code}`;

    const [view, setViewX] = useState(localStorage.getItem(ViewLSKey) || "short" as "short" | "detailed" | "results");
    const setView = (v: typeof view) => {
        localStorage.setItem(ViewLSKey, v);
        setViewX(v);
    }

    useEffect(() => {
        setViewX(localStorage.getItem(ViewLSKey) || "short" as "short" | "detailed" | "results");
    }, [ViewLSKey]);

    const { get_results_extra_columns } = useGridSettings();
    const resultsExtra = get_results_extra_columns ? (get_results_extra_columns(stage.code) || { columns: [], getValue: () => null }) : { columns: [], getValue: () => null };
    const resultsExtraColumns = resultsExtra.columns;
    const getResultsExtraValue = resultsExtra.getValue;

    return (<>
        <ActionRow>
            <Typography><FormattedMessage id="contests.rounds.config.stages.view" /></Typography>
            <ButtonOptionPicker
                options={[
                    [formatMessage({ id: "contests.rounds.config.stages.view_short" }), "short"],
                    [formatMessage({ id: "contests.rounds.config.stages.view_detailed" }), "detailed"],
                    [formatMessage({ id: "contests.rounds.config.stages.view_results" }), "results"],
                ]}
                selected={view}
                setSelected={setView}
            />
            {isLoading && <LoadingIndicator />}
            <OccupyFreeSpace />
            <SearchField filter={filter} setFilter={setFilter} noButton small />

            {view === "results" && <ClipboardButton getValue={results.getTsv} />}
            <SimpleActions actions={actions} isLoading={isActionExecuting} />
        </ActionRow>
        
        {stage.problems && Object.keys(stage.problems).length > 0 && (
            <ProblemsContainer>
                <ProblemMessages problems={stage?.problems} />
            </ProblemsContainer>
        )}

        {massEditRounds.selectedRounds.length > 0 && <ActionRow>
            <Typography><RoundsSelectedText rounds={massEditRounds.selectedRounds} /></Typography>
            <Button size="small" variant="outlined" color="primary" startIcon={<EditOutlined />} onClick={() => massEditRounds.startEdit()}><FormattedMessage id="common.edit" /></Button>
            <Button size="small" onClick={() => massEditRounds.selectMultiple(rounds)} ><FormattedMessage id="common.select_all" /></Button>
            <Button size="small" onClick={() => massEditRounds.clear()} ><FormattedMessage id="common.clear" /></Button>
            </ActionRow>}

        {(view === "short" || view === "detailed") &&
            <RoundsContainer className="rounds-container">
                {rounds.map(r => (
                    <RoundDisplay
                        key={r._id}
                        round={r}
                        editRound={editRound}
                        selectPlayer={selectPlayer}
                        showDetails={view === "detailed"}
                        showScores={stage.is_launched}
                        isSelected={massEditRounds.isRoundSelected(r)}
                        setIsSelected={massEditRounds.setRoundSelected}
                        />))}
            </RoundsContainer>}

        {view === "results" &&
            <TableForFields
                data={results.data}
                schema={results.schema}
                fields={[
                    ["sort_order"],
                    ["display_name"],
                    ["score", { precision: 2 }],
                    ...resultsExtraColumns,
                    ...(stage.kind === "rating"
                        ? [["wins"], ["losses"]] as FieldDefinition[]
                        : [["is_round_winner"]] as FieldDefinition[]),
                    ["pass"],
                ]}
                fieldElement={f => {
                    if(f === "sort_order") {
                        return r => <>{r.sort_order + 1}</>;
                    }
                    if(f === "pass") {
                        return r => (
                            <Button
                                size="small"
                                color="primary"
                                style={{ width: 'auto', minWidth: 0 }}
                                variant={r.pass ? "contained" : "outlined"}
                                endIcon={r.pass ? <Check /> : null}
                                disabled={stage.is_finished}
                                onClick={() => results.updatePass(r.party_id, !r.pass)}
                                >
                                <FormattedMessage id={r.pass ? "contests.rounds.player_standing.pass_yes" : "contests.rounds.player_standing.pass_no"} />
                            </Button>)
                    }
                    if(resultsExtraColumns.find(([c]) => c === f)) {
                        return r => getResultsExtraValue(f, r) as JSX.Element;
                    }
                }}
            />}

        <EditRoundPopup data={editRound} />
        <MassEditRoundsPopup data={massEditRounds} />
        {selectPlayer.popup}
        {selectArbiter.popup}
        {autoAssignArbiters.popup}
        <QRCodesPopup state={scoresQRCodesDialog} rounds={rounds} />
    </>);
}
