import React from 'react';
import { Typography, Button, useTheme,  } from '@material-ui/core';
import { FormGrid, cols } from '../../toolympus/components/primitives/Forms';
import { useMediaBreakpoint } from '../../toolympus/components/primitives/useMedia';
import { FieldDefinition, FormControlsForFields } from '../../toolympus/components/schemed';
import { Divider, PageWrapper } from '../Common.styles';
import { useJudgeProfile } from './useJudgeProfile';
import { PageContainer } from '../../toolympus/components/Pages';
import { Judge, JudgeRegistration } from '../Public/useJudgeRegistration';
import { Status } from '../../toolympus/components/StatusAction';
import { JudgeTimeslotsSelection } from './JudgeTimeslotsSelection';

const judgeStatus = (judge: JudgeRegistration) => {
    return (judge as Judge).approval_status || "review";
}

const JudgeStatus = ({ judge }: { judge: JudgeRegistration }) => {
    const { palette } = useTheme();
    
    return <Status
                chipStyles={{ textTransform: 'uppercase' }}
                status={judgeStatus(judge)}
                labels={{
                    review: "заявка рассматривается",
                    rejected: "заявка рассматривается",
                    approved: "заявка одобрена",
                }}
                hints={{
                    review: { },
                    rejected: { },
                    approved: { color: palette.success.main, },
                }}
                />

}

export const JudgeProfilePage = () => {
    const profile = useJudgeProfile();
    const { data, update, errors, schema, hasChanges, isLoading, save, } = profile;
    const isMobile = useMediaBreakpoint("down", "xs");

    const controls = (fields: FieldDefinition[], withArea?: boolean) => (
        <FormControlsForFields
            data={data}
            schema={schema}
            fields={fields}
            onChange={(o,c) => update(c)}
            errors={errors}
            withArea={withArea}
            />
    );

    return (
        <PageWrapper>
            <FormGrid columns={cols("1fr", isMobile ? 1 : 3)}>
                {controls([
                    ["email", { disabled: true }],
                ], false)}
            </FormGrid>
            <Divider key="email-divider" />
            <JudgeTimeslotsSelection profile={profile} />
            <FormGrid columns={isMobile ? "1fr" : "2fr 1fr"}>
                <PageContainer apiPath="/api/page" slug="judge-profile-instructions" />
                <JudgeStatus judge={data} /> 
            </FormGrid>
            <Divider key="explanation-block-divider" />
            <FormGrid
                columns={Array(isMobile ? 1 : 6).fill("1fr").join(" ")}
                areas={isMobile ? [
                        "lastname",
                        "firstname",
                        "middlename",
                        "phone",
                        "city",
                        "education",
                        "job",
                        "experience",
                        "affiliation",
                    ]
                    : [
                    `${cols("lastname", 2)} ${cols("firstname", 2)} ${cols("middlename", 2)}`,
                    `${cols("phone", 3)} ${cols("city", 3)}`,
                    cols("education", 6),
                    cols("job", 6),
                    cols("experience", 6),
                    cols("affiliation", 6),
                ]}>
                {controls([
                    ["lastname"],
                    ["firstname"],
                    ["middlename"],
                    ["phone"],
                    ["city"],
                    ["education"],
                    ["job"],
                    ["experience"],
                    ["affiliation"],
                ], true)}
                
            </FormGrid>

            <FormGrid
                columns={cols("1fr", isMobile ? 1 : 2)}
                areas={isMobile ? [
                    "experience_moots",
                    "experience_femida_title",
                    "experience_femida_member",
                    "experience_femida_coach",
                    "experience_femida_judge_written", 
                    "experience_femida_judge_oral",
                    "judge_intention_title",
                    "judge_intention_written",
                    "judge_intention_oral",
                    "judge_written_works_nbr_title",
                    "judge_written_works_nbr",
                    "empty2",
                    "travel_compensation_required",
                    "personal_data_consent",
                ] : [
                    cols("experience_moots", 2),
                    cols("experience_femida_title", 2),
                    "experience_femida_member experience_femida_coach",
                    "experience_femida_judge_written experience_femida_judge_oral",
                    cols("judge_intention_title", 2),
                    "judge_intention_written judge_intention_oral",
                    cols("judge_written_works_nbr_title", 2),
                    "judge_written_works_nbr empty1",
                    cols("travel_compensation_required", 2),
                    cols("personal_data_consent", 2),
                ]}>
                {controls([
                    ["experience_moots"],
                    ["experience_femida_member"],
                    ["experience_femida_coach"],
                    ["experience_femida_judge_written"],
                    ["experience_femida_judge_oral"],
                    ["judge_intention_written"],
                    ["judge_intention_oral"],
                    ["judge_written_works_nbr"],
                    ["travel_compensation_required"],
                    ["personal_data_consent"],
                ], true)}
                <Typography variant="caption" style={{ gridArea: "experience_femida_title" }}>Опыт участия в Конкурсе "Хрустальная Фемида"</Typography>
                <Typography variant="caption" style={{ gridArea: "judge_intention_title" }}>Вы хотели бы принять участие в качестве судьи</Typography>
                <Typography variant="caption" style={{ gridArea: "judge_written_works_nbr_title" }}>Пожалуйста, укажите, сколько письменных работ Вы сможете оценить (как правило, мы просим судей проверить не менее двух работ: одну жалобу и один отзыв)</Typography>
                <Typography style={{ gridArea: "empty2" }}> </Typography>
            </FormGrid>

            <FormGrid columns={cols("1fr", isMobile? 1 : 3)}>
                <div/>
                <div/>
                <Button variant="contained" color="primary" disabled={!hasChanges || isLoading} onClick={save}>сохранить</Button>
            </FormGrid>
        </PageWrapper>
    );
}
