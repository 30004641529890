import styled from '@emotion/styled';
import { Button, DialogActions, Typography, withTheme } from '@material-ui/core';
import React from 'react';
import { Dialog, FormGrid } from '../../../toolympus/components/primitives';
import { FormControl } from '../../../toolympus/components/schemed';
import { FieldType } from '../../../toolympus/hooks/useSchema';
import { TeamRoundSpeakersData } from './useTeamRoundSpeakers';

interface Props {
    data: TeamRoundSpeakersData;
}

const Error = withTheme(styled.p`
    color: ${props => props.theme.palette.secondary.main};
`);

export const TeamRoundSpeakersDialog = (props: Props) => {
    const { data } = props;

    return (
        <Dialog
            isOpen={data.dialog.isOpen}
            close={data.dialog.close}
            dialogTitle="Участники"
            noFullscreen>
            <Typography>Вы выступаете за <strong>{data.position === 1 ? "заявителя" : data.position === 2 ? "госорган" : "..."}</strong>.</Typography>

            <Typography>Выберите троих участников: двоих выступающих и одного, играющего вспомогательную роль.</Typography>
            <Typography>Для выступающих участников укажите время выступления. Каждый должен выступать не менее 15 и не более 30 минут.</Typography>
            <Typography>Устные заявления команд не должны превышать 45 минут, включая время для ответов на сопутствующие вопросы судей и не включая время на заключительную реплику.</Typography>

            <FormGrid columns="1fr 1fr">
                <FormControl
                    schema={{
                        ...data.speakerSchema,
                        label: "Участник 1",
                    }}
                    field="speaker1"
                    row={{ speaker1: data.getSpeakerMemberValue("speaker1")}}
                    onChange={(o,c) => data.updateSpeakerMember("speaker1", c.speaker1)}
                    />
                <FormControl
                    schema={{
                        type: FieldType.number,
                        label: "Время, м"
                    }}
                    field="time"
                    row={{ time: data.speakers.speaker1.time }}
                    onChange={(o,c) => data.updateSpeakerTime("speaker1", c.time)}
                    error={!data.isSpeakerTimeValid("speaker1")}
                    />

                <FormControl
                    schema={{
                        ...data.speakerSchema,
                        label: "Участник 2",
                    }}
                    field="speaker2"
                    row={{ speaker2: data.getSpeakerMemberValue("speaker2")}}
                    onChange={(o,c) => data.updateSpeakerMember("speaker2", c.speaker2)}
                    />

                <FormControl
                    schema={{
                        type: FieldType.number,
                        label: "Время, м"
                    }}
                    field="time"
                    row={{ time: data.speakers.speaker2.time }}
                    onChange={(o,c) => data.updateSpeakerTime("speaker2", c.time)}
                    error={!data.isSpeakerTimeValid("speaker2")}
                    />

                <FormControl
                    schema={{
                        ...data.speakerSchema,
                        label: "Участник 3",
                    }}
                    field="speaker3"
                    row={{ speaker3: data.getSpeakerMemberValue("speaker3")}}
                    onChange={(o,c) => data.updateSpeakerMember("speaker3", c.speaker3)}
                    />
            </FormGrid>

            {!data.isSpeakersSelected && <Error key="speakers">Укажите участников</Error>}
            {!data.isTotalTimeValid && <Error key="total-time">Общее время выступления не должно превышать 45 минут. Время каждого выступающего от 15 до 30 минут.</Error>}

            <DialogActions>
                <Button disabled={!data.isSpeakersSelected || !data.isTotalTimeValid} variant="contained" color="primary" onClick={() => data.save()}>сохранить</Button>
            </DialogActions>
        </Dialog>
    );
}
