import React from 'react';
import styled from '@emotion/styled';
import { withTheme } from '@material-ui/core';
import { 
    CaptionAndLinksBlock, 
    LeftPane, 
    Link, 
    RegistrationPageWrapper, 
    RegistrationPageRightPane,
    Logo, 
} from './Common';
import { PageContainer } from '../../toolympus/components/Pages';

const Links = withTheme(styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 16px 0;
    
    & > * {
        padding: 0 8px;
    }
    & > :not(:first-child) {
        border-left: 1px solid ${props => props.theme.palette.primary.main};
    }

    ${props => props.theme.breakpoints.down("sm")} {
        flex-flow: column;
        & > * {
            padding: 0.25em 0;
        }
        & > :not(:first-child) {
            border-left: none;
        }
    }
`);

const RightPane = withTheme(styled(RegistrationPageRightPane)`
    justify-content: flex-start;

    & > :last-child {
        width: 100%;
    }

    ${props => props.theme.breakpoints.down("md")} {
        & > * {
            max-width: max-content;
        }
    }
`);

const PageWrapper = withTheme(styled(RegistrationPageWrapper)`
    ${props => props.theme.breakpoints.down("md")} {
        flex-flow: column;
    }
`);

interface Props {
    slug: string;
}

export const PublicInfoPageBySlug = ({ slug }: Props) => {
    return (
        <PageWrapper>
            <LeftPane pad="small" />
            <RightPane>
                <CaptionAndLinksBlock>
                    <Logo />
                    <Links>
                        <Link to="/login">Вход</Link>
                        <Link to="/register-team">Регистрация команд</Link>
                        <Link to="/register-judge">Регистрация судей</Link>
                    </Links>
                </CaptionAndLinksBlock>

                <PageContainer apiPath="/api/page-public" slug={slug} />
            </RightPane>
        </PageWrapper>
    );
}

export const PublicInfoPage = () => {
    return <PublicInfoPageBySlug slug="info" />;
}
