import React from "react";
import { ChatsUnreadIndicator } from "../components/Chats/ChatsUnreadIndicator";
import { JudgeChatWithOrgPage } from "../components/Chats/ChatWithOrgPage";
import { InfoPage } from "../components/InfoPage";
import { SecretaryRoundsPage } from "../components/Secretary/SecretaryRoundsPage";
import { MenuRoute } from "../toolympus/components/frame";


export const SecretaryRoutes: MenuRoute[] = [
    { 
        path: '/secretary/rounds',
        title: 'Устные раунды',
        component: SecretaryRoundsPage,
        resolveHidden: (roles, ctx) => ctx?.state?.finals_available,
    },
    { 
        path: '/secretary/rounds-broadcast',
        title: 'Трансляции и записи',
        component: () => <InfoPage slug="rounds-broadcast" />,
        resolveHidden: (roles, ctx) => ctx?.state?.finals_broadcast_page_available,
    },
    { 
        path: '/secretary/chat-org',
        title: 'Чат с организатором',
        suffix: <ChatsUnreadIndicator />,
        component: () => <JudgeChatWithOrgPage />,
    },

];
