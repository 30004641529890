import { FieldType, useSingleSchema } from "../../hooks/useSchema";
import { useFetch } from '../../api/useFetch';
import { EmailItem } from './types';
import { apiFetch, FetchTypes } from "../../api/core";
import { useFieldSorting } from "../../hooks/useFieldSorting";
import { useLoadedData } from "../../hooks/useLoadedData";
import { useTextFilter } from "../schemed/Filtering/useTextFilter";

export function useEmails(apiPrefix: string) {
    const apiPath = `/api${apiPrefix}`;

    const sorting = useFieldSorting({ });
    const filter = useTextFilter<EmailItem>(em => `${em.code} ${em.title} ${em.description} ${em.subject}`);

    const { schema, isLoading: schemaLoading } = useSingleSchema(`${apiPath}/uiconfig`);
    const { data: emails, isLoading, reload } = useLoadedData<EmailItem[]>(`${apiPath}?${sorting.queryParameter}`, []);

    const crud = useFetch(null, {url: apiPrefix }, false);
    const test = useFetch(null, {method: FetchTypes.POST}, false)
 
    const create = (body: EmailItem) => {
        crud.request({body, method: FetchTypes.POST}).then(reload);    
    }

    const update = (body: EmailItem) => {
        crud.request({body, url: apiPrefix + '/' + body.code, method: FetchTypes.PUT}).then(reload);
    }

    const emailDelete = (code: string) => {
        crud.request({url: apiPrefix + '/' + code, method: FetchTypes.DELETE}).then(reload);
    }

    const testFn = (code: string, params: string, to?: string) => {
        const url = `${apiPrefix}/${code}/test?${to && "to="}${to}`;
        let data = {};
        try {
            data = JSON.parse(params);
        } catch (e) {
            data = {};
        }
        test.request({url, body: data});
    }

    const preview = (code: string) => {
        return apiFetch<{ body_html: string}>(`${apiPath}/${code}/preview`);
    }

    const schemaX = {
        ...schema,
        base_template: {
            ...schema.base_template,
            type: FieldType.select,
            values: emails.map(e => ({ value: e.code, label: e.code })),
            valueDict: emails.reduce((r,e) => ({ ...r, [e.code]: e.code }), {}),
        }
    }

    return {
        schema: schemaX,
        emails: filter.filterData(emails),
        loading: schemaLoading || isLoading || crud.loading,
        create,
        update,
        emailDelete,
        test: testFn,
        preview,
        testLoading: test.loading,

        sorting,
        filter,
    };
}