import React from 'react';
import { PageContainer } from '../toolympus/components/Pages';
import { DocumentLinks } from '../toolympus/components/Pages/DocumentLinks';
import { FormWrapper } from '../toolympus/components/primitives/Forms';

interface Props {
    slug: string;
}

export const InfoPage = ({ slug }: Props) => {
    return (
        <FormWrapper>
            <PageContainer apiPath="/api/page" slug={slug} customComponents={{ filelinks: DocumentLinks }} />
        </FormWrapper>
    );
}
