import React from 'react';
import { GridSettingsProvider, RoundPlayerInfoProps } from '../../../toolympus/components/Contests/Grid';
import { FormWrapper } from '../../../toolympus/components/primitives';
import { FemidaGridSettings } from './FemidaGridSettings';
import { SpeakerSelect } from './SpeakerSelect';
import { ArbiterRoundsListContainer } from '../../../toolympus/components/Contests/Grid/Parties/ArbiterRoundsList';
import { RoundSpeakersDisplay } from './TeamRoundsPage';
import { RoundPlayerInfo } from './RoundPlayerInfo';

export const JudgeRoundSpeakers = (props: RoundPlayerInfoProps) => {
    const speakers = (props.round?.info?.speakers[props.position] || {});
    return <div>
        <RoundPlayerInfo {...props} />
        {speakers && <RoundSpeakersDisplay speakers={speakers} />}
    </div>;
}

export const JudgeRoundsPage = () => {
    return (
        <GridSettingsProvider settings={{
            ...FemidaGridSettings,
            round_player_info_component: JudgeRoundSpeakers,
            }}>
            <FormWrapper>
                <ArbiterRoundsListContainer
                    apiPath="/api/admin/finals"
                    pageApiPath="/api/page"
                    pageSlug="judge-rounds"
                    scorePageSlug="rounds-score"
                    scoringCustomComponents={{
                        speaker: SpeakerSelect,
                    }}
                    />
            </FormWrapper>
        </GridSettingsProvider>
    );
}
