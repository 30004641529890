import { downloadFile } from "../../toolympus/api/core";
import { LoadedData, useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { CaseQuestion } from "./useUserCaseQuestions";

interface AdminCaseQuestions extends LoadedData<CaseQuestion[]> {
    exportQuestions: () => void;
}

const apiPath = '/api/case-question/admin';

export const useAdminCaseQuestions = (): AdminCaseQuestions => {
    const data = useLoadedData<CaseQuestion[]>(apiPath, []);

    const exportQuestions = () => {
        downloadFile(`${apiPath}/export`, 'вопросы.xlsx');
    }

    return {
        ...data,
        exportQuestions,
    }
}