export const femidaMessages = {
    error: {
        general: "Произошла ошибка. Пожалуйста проверьте введённые данные",

        auth: {
            user_blocked: "Пользователь заблокирован. Это могло произойти, если Вы несколько раз ввели неправильный пароль. В таком случае вход блокируется на {minutes} минут. Если вы считаете, что произошла ошибка, пожалуйста свяжитесь с администратором",
        },

        team: {
            team_changes_blocked: "Изменения заблокированы",
        },

        document_upload_closed: "Срок подачи документов вышел",
    },

    documents: {
        assignment: {
            failed_to_assign_any: "Не удалось назначить ни одного судью",
            already_assigned: "Этот документ уже назначен на оценку этому судье",
        },
    },

    contests: {
        rounds: {
            config: {
                labels: {
                    arbiter_access_url: "Формат ссылки для судьи",
                },

                stages: {
                    launch_confirmation: "Стадия будет запущена, команды и судьи получат доступ к раундам",
                    unlaunch_confirmation: "Запуск стадии будет отменен, доступ команд и судей к раундам будет отозван.",
                    assign_arbiters: "Назначить судей",
                    clear_arbiters: "Очистить судей",
                    clear_arbiters_confirmation: "Будут удалены все назначения судей в этой стадии. Продолжить?",

                    validation: {
                        arbiters_unassigned: "Судьи не назначены",
                        speakers_not_selected: "Участники не выбраны у команд: {teams} (раунд {rounds})",
                    },
                },

                validation: {

                    arbiter_not_found: "Судья не найден",
                    cant_assign_the_same_arbiter_to_a_round_twice: "Судья может быть назначен на один и тот же раунд только один раз",
                },
            },


            round: {
                labels: {
                    arbiters: "Судьи",
                    arbiter: "Судья {position}",
                },
            },

            arbiter: {
                select: "Выбрать судью",
            },

            public: {
                stage_names: {
                    rating: "Четвертьфинал",
                },
                
                empty_state: "Здесь будут показаны игры, в которых Вы участвуете",
            }
        },
    }
}