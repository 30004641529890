import { Criteria, ScoringSheet } from ".";
import { CrudItemData, useCrudItem } from "../../../api/useSimpleCrud";
import { FieldType, Schema, useSingleSchema } from "../../../hooks/useSchema";

export interface ScoringSheetEditData extends CrudItemData<ScoringSheet> {
    schema: Schema;
    addCriteria: () => void;
    updateCriteria: (idx: number, changes: Partial<Criteria>) => void;
    removeCriteria: (idx: number) => void;
    criteriaSchema: Schema;
}

const CriteriaSchema: Schema = {
    type: {
        type: FieldType.select,
        label_id: "contests.scoring.fields.criteria.type",
        values: [
            { value: 'number', label: 'Number' },
            { value: 'bool', label: 'Checkbox' },
            { value: 'text', label: 'Text' },
        ],
        valueDict: {
            'number': 'Number',
            'bool': 'Checkbox',
            'text': 'Text',
            'dropdown': 'Select',
        }
    },
    name: { type: FieldType.text, label_id: "contests.scoring.fields.criteria.name" },
    comment: { type: FieldType.textlong, label_id: "contests.scoring.fields.criteria.comment" },
    min_value: { type: FieldType.number, label_id: "contests.scoring.fields.criteria.min_value" },
    max_value: { type: FieldType.number, label_id: "contests.scoring.fields.criteria.max_value" },
}

const DefaultCriteria: Record<string, Criteria> = [
    { type: "number", min_value: 0, max_value: 10 },
    { type: "text", },
    { type: "checkbox", },
    { type: "dropdown", dropdown_options: [] },
    { type: "custom", },
].reduce((r,c) => ({ ...r, [c.type]: c }), {});

export const useScoringSheetEdit = (apiPath: string, code: string): ScoringSheetEditData => {
    const data = useCrudItem<ScoringSheet>(`${apiPath}/${code}`, {
        defaultValue: {} as ScoringSheet,
    });

    const { schema } = useSingleSchema(`${apiPath}/uiconfig`);

    const updateCriteria = (idx: number, changes: Partial<Criteria>) => { 
        const updated = (data.data.criteria || []).slice();
        const original = updated[idx];
        const extras = changes.type && changes.type !== original.type ? DefaultCriteria[changes.type] : {};
        updated[idx] = { ...updated[idx], ...extras, ...changes };
        if(updated[idx].type !== 'number') {
            updated[idx].min_value = undefined;
            updated[idx].max_value = undefined;
        }
        if(updated[idx].type !== 'dropdown') {
            updated[idx].dropdown_options = undefined;
        }
        data.update({ criteria: updated });
    }

    return {
        ...data,
        schema,
        addCriteria: () => data.update({ criteria: [...(data.data.criteria || []), { ...DefaultCriteria.number, name: "" } ]}),
        removeCriteria: (idx) => data.update({ criteria: (data.data.criteria || []).filter((_,i) => i !== idx)}),
        updateCriteria,
        criteriaSchema: CriteriaSchema,
    }
}