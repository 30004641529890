import React from 'react';
import styled from '@emotion/styled';
import { RoundPlayerInfoProps } from '../../../toolympus/components/Contests/Grid';
import { DescriptionOutlined } from '@material-ui/icons';
import { DocumentNames } from '../../Team/useTeamDocuments';
import { Typography, withTheme } from '@material-ui/core';
import { downloadFile } from '../../../toolympus/api/core';
import { useUser } from '../../../toolympus/userContext/UserContext';


interface Document {
    document_id: string;
    document_kind: "doc1" | "doc2";
}

type Documents = Record<Document["document_kind"], Document>;

const DocLinkWrapper = withTheme(styled.div`
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
    padding: 0.25rem 0;
    color: ${props => props.theme.palette.primary.main};

    & > :first-child {
        margin-right: 8px;
    }
`);

const DocLink = (props: { document: Document, teamName: string }) => {
    const { user } = useUser();
    const isTeam = (user?.roles || []).includes("team");
    const { document_kind, document_id } = props.document;
    const docName = DocumentNames[document_kind];

    const downloadDocument = () => {
        downloadFile(`/api/${isTeam ? "team" : "judge"}/document/${document_id}`, `${props.teamName} ${docName}.docx`)
    }


    return <DocLinkWrapper role="button" onClick={(e: any) => {
        e.stopPropagation();
        e.preventDefault();
        downloadDocument()
    }}>
        <DescriptionOutlined />
        <Typography>{docName}</Typography>
    </DocLinkWrapper>
}

export const RoundPlayerInfo = (props: RoundPlayerInfoProps) => {
    const documents = ((props.round.info?.documents || {})[props.position] || {}) as Documents;
    const teamName = props.round.players[props.position].display_name;
    return (
        <div>
            {documents.doc1 && <DocLink key="doc1" document={documents.doc1} teamName={teamName} />}
            {documents.doc2 && <DocLink key="doc2" document={documents.doc2} teamName={teamName} />}
        </div>
    );
}
