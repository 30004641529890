import React from 'react';
import styled from '@emotion/styled';
import { AssignmentTurnedInOutlined, WarningOutlined } from '@material-ui/icons';
import { GridSettingsProvider, PlayerRoundsListContainer, RoundPlayerInfoProps } from '../../../toolympus/components/Contests/Grid';
import { FormWrapper } from '../../../toolympus/components/primitives';
import { useUser } from '../../../toolympus/userContext/UserContext';
import { FemidaGridSettings } from './FemidaGridSettings';
import { TeamRoundSpeakersDialog } from './TeamRoundSpeakersDialog';
import { RoundSpeakers, useTeamRoundSpeakers } from './useTeamRoundSpeakers';
import { Button, Typography } from '@material-ui/core';
import { useContestState } from '../../../useContestState';
import { useTeamFeedbackSheets } from '../../Team/useTeamDocuments';
import { RoundPlayerInfo } from './RoundPlayerInfo';

const NoSpeakersWrapper = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 6px;
`;

const SpeakersWrapper = styled(Typography)`
    font-size: 12px;
`;

interface RoundSpeakersProps {
    speakers: RoundSpeakers;
    onClick?: React.MouseEventHandler<HTMLParagraphElement>;
}

const formatSpeakerName = (speaker?: RoundSpeakers["speaker1"]) => `${speaker?.firstname || ""} ${speaker?.lastname || ""}`;
const formatSpeakerTime = (speaker?: RoundSpeakers["speaker1"]) => speaker?.time ? `(${speaker?.time || ""}м)` : "";

export const RoundSpeakersDisplay = ({ speakers, onClick }: RoundSpeakersProps) => (
    <SpeakersWrapper onClick={onClick}>
        {formatSpeakerName(speakers.speaker1)} {formatSpeakerTime(speakers.speaker1)} {speakers.speaker1?.lastname && <br />}
        {formatSpeakerName(speakers.speaker2)} {formatSpeakerTime(speakers.speaker2)} {speakers.speaker2?.lastname && <br />}
        {formatSpeakerName(speakers.speaker3)}
    </SpeakersWrapper>
)

const TeamRoundSpeakers = (props: RoundPlayerInfoProps) => {
    const { user } = useUser();
    const speakersData = useTeamRoundSpeakers(props);

    if(user?._id !== props.round.players[props.position]._id) {
        return <RoundPlayerInfo {...props} />;
    }

    // const speakers = (props.round.info || {}).speakers[props.position];

    return <div>
        <RoundPlayerInfo {...props} />
        {!speakersData.hasSpeakers
            ? <NoSpeakersWrapper onClick={e => { e.stopPropagation(); e.preventDefault(); speakersData.dialog.open(); }}>
                <WarningOutlined color="secondary" /> укажите представителей
              </NoSpeakersWrapper>
            : <RoundSpeakersDisplay speakers={speakersData.speakers} onClick={e => { e.stopPropagation(); e.preventDefault(); speakersData.dialog.open(); }} />}
        <TeamRoundSpeakersDialog data={speakersData} />
    </div>;
}

export const TeamRoundsPage = () => {
    const state = useContestState();
    const { getRoundsFeedback } = useTeamFeedbackSheets();


    return (
        <GridSettingsProvider settings={{
            ...FemidaGridSettings,
            round_player_info_component: TeamRoundSpeakers,
            }}>
            <FormWrapper>
                <PlayerRoundsListContainer
                    apiPath="/api/admin/finals"
                    pageApiPath="/api/page"
                    pageSlug="team-rounds"
                    extraCustomComponents={{
                        rounds_feedback: () => state.data.rounds_feedback_available
                            ? <div style={{ textAlign: "center", margin: "1rem 0"}}>
                                <Button color="primary" variant="contained" startIcon={<AssignmentTurnedInOutlined />} onClick={() => getRoundsFeedback()}>скачать оценочный лист</Button>
                            </div>
                            : <div></div>,
                    }}
                    />
            </FormWrapper>
        </GridSettingsProvider>
    );
}
