import { useEffect, useState } from 'react';
import { Schema, schemaFieldsSorter } from '../../hooks/useSchema';
import { FieldDefinition } from './Common';
import { ExtraProps } from './FormControlProps';

export interface FieldsSettings {
    includedFields: string[];
    excludedFields: string[];
    schema: Schema;
    extraSettings?: Record<string, ExtraProps>;
    changeDefaultFields: (fields: string[]) => void;
    
    activeFields: FieldDefinition[];
    setFieldActive: (field: string, isActive: boolean) => void;
    resetToDefaults: () => void;
    moveFieldUp: (field: string) => void;
    moveFieldDown: (field: string) => void;
}

export interface FieldSettingsSettings {
    defaultFields: string[];
    storageKey?: string;
    whitelist?: string[];
    blacklist?: string[];
    outOfSchemaFields?: string[];
    schema: Schema;
    extraSettings?: Record<string, ExtraProps>;
}

export const useFields = (props: FieldSettingsSettings): FieldsSettings => {
    const { defaultFields, storageKey, schema, outOfSchemaFields } = props;
    const whitelist = props.whitelist;
    const blacklist = props.blacklist || [];
    const extraSettings = props.extraSettings || {};

    const [includedFields, setIncludedFields] = useState<string[]>(defaultFields);

    useEffect(() => {
        const stored = storageKey ? localStorage.getItem(storageKey) : null;
        if(stored) {
            const decoded = JSON.parse(stored);
            if(decoded && Array.isArray(decoded) && decoded.length > 0) {
                setIncludedFields(decoded);
            }
        }
    }, [storageKey]);

    const changeFields = (newFields: string[]) => {
        setIncludedFields(newFields);
        if(storageKey) {
            localStorage.setItem(storageKey, JSON.stringify(newFields));
        }
    }

    const changeDefaultFields = (newFields: string[]) => {
        const stored = storageKey ? localStorage.getItem(storageKey) : null;
        if(!stored) {
            setIncludedFields(newFields);
        }
    }

    const setFieldActive = (field: string, isActive: boolean) => {
        const found = includedFields.indexOf(field);
        if(isActive) {
            if(found < 0) {
                changeFields([...includedFields, field])
            }
        } else {
            if(found >= 0) {
                const copy = includedFields.slice();
                copy.splice(found, 1);
                changeFields(copy)
            }
        }
    }

    const availableFields = Object.keys(schema).concat(outOfSchemaFields || [])
        .filter(f => (!whitelist || whitelist.length === 0 || whitelist.includes(f)) && !(blacklist.includes(f)))
        .sort(schemaFieldsSorter(schema));

    const moveField = (f: string, direction: number) => {
        const copy = [...includedFields];
        const current = copy.indexOf(f);
        if(current >= 0) {
            copy.splice(current, 1);
            if(current+direction > copy.length) {
                copy.splice(0, 0, f);
            } else if(current+direction < 0) {
                copy.splice(copy.length, 0, f);
            } else {
                copy.splice(current+direction, 0, f);
            }
        }
        changeFields(copy);
    }

    return {
        includedFields,
        excludedFields: availableFields.filter(f => !includedFields.includes(f)),
        schema,
        extraSettings,
        changeDefaultFields,

        activeFields: includedFields.map(f => [f, extraSettings[f] || {}]),
        setFieldActive,
        resetToDefaults: () => changeFields(defaultFields),
        moveFieldDown: f => moveField(f, 1),
        moveFieldUp: f => moveField(f, -1),
    };
}