import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { Tooltip } from '../../toolympus/components/primitives/Tooltip';
import { FieldDefinition, FormControlsForFields, PlaceholderField } from '../../toolympus/components/schemed';
import { GoToChatButton } from './Common';
import { SecretaryReview } from './useSecretaryReview';

interface Props {
    data: SecretaryReview;
}

export const SecretaryReviewPopup = (props: Props) => {
    const {
        isOpen,
        close,
        data,
        update,
        schema,
        save,
        hasChanges,
        isLoading,
        approval,
        comments,
    } = props.data;

    const controls = (fields: FieldDefinition[], withArea?: boolean) => (
        data && <FormControlsForFields
            data={data}
            schema={schema}
            fields={fields}
            onChange={(o,c) => update(c)}
            withArea={withArea}
            />
    )

    const isApproved = data && approval.isRegistrationApproved(data);

    return (
        <Dialog open={isOpen} onClose={close} fullWidth maxWidth="md">
            <DialogTitle>
                Секретарь
                <Tooltip text="Комментарии">
                    {(comments && comments.button) as ReactElement<any, any>}
                </Tooltip>
                <Tooltip text="Чат" withWrapper>
                    <GoToChatButton to={`/admin/chat-org/${data?._id}`} />
                </Tooltip>
                {isLoading && <LoadingIndicator />}
            </DialogTitle>

            <DialogContent>
                <FormGrid columns="repeat(3,1fr)">
                    {controls([
                        ["email"],
                        ["phone"],
                    ])}

                    <div>
                        <Typography variant="caption" style={{ display: "block" }}>Одобрен?</Typography>
                        <Button
                            size="small"
                            color="primary"
                            variant={isApproved ? "contained" : "outlined"}
                            onClick={() => data && approval.update(data, !isApproved)}>
                            {isApproved ? "да" : "нет"}
                        </Button>
                    </div>

                    {controls([
                        ["lastname"],
                        ["firstname"],
                        ["middlename"],
                    ])}
                </FormGrid>

                <FormGrid columns="1fr">
                    {controls([
                        ["guide", { autoRows: true }],
                        ["rounds"],
                        [PlaceholderField],
                        ["university"],
                        ["affiliation"],
                        ["experience_secretary"],
                    ])}
                </FormGrid>
            </DialogContent>

            <DialogActions>
                <Button onClick={close}>
                    <FormattedMessage id="common.close"/>
                </Button>
                <Button color="primary" variant="contained" onClick={save} disabled={!hasChanges}>
                    <FormattedMessage id="common.save"/>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
