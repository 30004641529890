import { useEffect, useState } from "react";
import { apiFetch } from "../../api/core";
import { useCrudItem } from "../../api/useSimpleCrud";
import { flatten } from 'flat';


const plugKey = (target: any, k: string, v: string = "") => {
    const parts = k.split(".");
    let item: any = target;

    for(let i = 0; i < parts.length - 1; i++) {
        if(!item[parts[i]]) {
            item[parts[i]] = {};
        }
        item = item[parts[i]];
    }
    if(!item[parts[parts.length - 1]]) {
        item[parts[parts.length - 1]] = v;
    }

    return target;
}


export interface LocalizationMessagesConfig {
    apiPath?: string;
}

type Messages = Record<string, any>;
type MessagesFlat = Record<string, string>;
type MessagesDict = Record<string, Messages>;
export type Mode = "json" | "strings";

export interface LocalizationMessagesEdit {
    mode: Mode;
    setMode: (v: Mode) => void;

    selectedLanguage: string | null;
    setSelectedLanguage: (lang: string) => void;
    availableLanguages: string[];
    addLanguage: (lang: string) => void;
    
    messages: MessagesFlat;
    messagesKeys: string[];
    updateMessage: (k: string, v: string) => void;
    addMessage: (k: string) => void;
    showEmptyOnly: boolean;
    setShowEmptyOnly: (v: boolean) => void;
    filter: string;
    setFilter: (v: string) => void;
    
    messagesJson: string;
    updateJson: (v: string) => void;
    isJsonError: boolean;

    hasChanges: boolean;
    save: () => void;

    pullMessagesFromOtherLanguage: (sourceLang: string) => void;
}


export const useLocalizationMessagesEdit = (cfg: LocalizationMessagesConfig): LocalizationMessagesEdit => {
    const [mode, setMode] = useState<Mode>("strings");

    const apiPath = cfg.apiPath || "/api/localization/messages";

    const data = useCrudItem<MessagesDict>(apiPath, {
        defaultValue: { },
    });

    const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);

    const availableLanguages = Object.keys(data.data).sort();

    useEffect(() => {
        if(availableLanguages.length > 0 && !selectedLanguage) {
            setSelectedLanguage(availableLanguages[0]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableLanguages.length, selectedLanguage]);

    const addLanguage = (lang: string) => {
        apiFetch(apiPath, "post", { lang }).then(() => data.reload());
    }

    

    const updateAndSaveCurrent = (messages: Messages) => {
        return apiFetch(`${apiPath}/${selectedLanguage}`, "put", { messages })
            .then(() => { data.reload(); });
    }
    
    
    const [messagesJson, setMessagesJson] = useState<string>("");
    const [isJsonChanged, setIsJsonChanged] = useState<boolean>(false);
    const [isJsonError, setIsJsonError] = useState<boolean>(false);

    const updateJson = (v: string) => {
        setMessagesJson(v);
        setIsJsonChanged(true);
    }


    const messagesFlatOriginal: Record<string, string> = flatten(selectedLanguage ? data.data[selectedLanguage] : {});
    const [messagesFlat, setMessagesFlat] = useState<MessagesFlat>({});
    const [isMessagesFlatChanged, setIsMessagesFlatChanged] = useState<boolean>(false);

    const updateMessage = (k: string, v: string) => {
        setMessagesFlat(d => ({ ...d, [k]: v }));
        setIsMessagesFlatChanged(true);
    }

    const addMessage = (k: string) => {
        updateMessage(k, "");
    }
    
    useEffect(() => {
        const selectedMessages = selectedLanguage ? data.data[selectedLanguage] : {};
        setMessagesJson(JSON.stringify(selectedMessages, undefined, 4));
        setIsJsonChanged(false);
        setIsJsonError(false);

        setMessagesFlat(flatten(selectedMessages))
        setIsMessagesFlatChanged(false);
    }, [selectedLanguage, data.data]);

    const [showEmptyOnly, setShowEmptyOnly] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>("");


    const save = async () => {
        if(isJsonChanged) {
            try {
                await updateAndSaveCurrent(JSON.parse(messagesJson));
            } catch(e) {
                setIsJsonError(true);
                throw e;
            }
        } else if(isMessagesFlatChanged) {
            const updated = {};
            Object.keys(messagesFlat).forEach(k => {
                plugKey(updated, k, messagesFlat[k]);
            });
            updateAndSaveCurrent(updated);
        }
    }

    const hasChanges = isJsonChanged || isMessagesFlatChanged;

    const pullMessagesFromOtherLanguage = (lang: string) => {
        if(hasChanges || !selectedLanguage || lang === selectedLanguage) {
            return;
        }

        const sourceMessages: Record<string, string> = flatten(data.data[lang] || {});

        const targetChanged = { ...(data.data[selectedLanguage as string] || {}) };
        const targetMessages: Record<string, string> = flatten(data.data[selectedLanguage] || {});

        Object.keys(sourceMessages).forEach(k => {
            if(!targetMessages[k]) {
                plugKey(targetChanged, k);
            }
        });

        updateAndSaveCurrent(targetChanged);
    }

    const messagesKeys = Object.keys(messagesFlat)
        .filter(k => (!showEmptyOnly || !messagesFlatOriginal[k])
                    && (!filter || k.toLowerCase().includes(filter) || (messagesFlatOriginal[k] || "").toLowerCase().includes(filter)))
        .sort();

    return {
        mode,
        setMode,

        selectedLanguage,
        setSelectedLanguage,
        availableLanguages,
        addLanguage,

        messages: messagesFlat,
        messagesKeys,
        updateMessage,
        addMessage,
        showEmptyOnly,
        setShowEmptyOnly,
        filter,
        setFilter: (v: string) => setFilter(v.toLowerCase()),
        
        messagesJson,
        isJsonError,
        updateJson,

        hasChanges,
        save,

        pullMessagesFromOtherLanguage,
    }
}
