import React from "react";
import styled from '@emotion/styled';
import { Chip, ChipTypeMap, Tooltip } from "@material-ui/core";

export interface Hints {
    icon?: JSX.Element;
    color?: string;
    light?: boolean;
    label?: string;
    description?: string;
}

type VariantType = Pick<ChipTypeMap["props"], "variant">["variant"];
type SizeType = Pick<ChipTypeMap["props"], "size">["size"];

interface StatusProps {
    status: string;
    hints?: { [_: string]: Hints };
    labels?: { [_: string]: string };
    size?: SizeType;
    variant?: VariantType;
    chipStyles?: React.CSSProperties;
}


export const ChipWrapp = styled.div<{color?: string, light?: boolean, variant: VariantType}>`
    & .MuiChip-root {
        ${({variant, color}) => variant !== "outlined" ? `background-color: ${color};` : ''};        
    }

    & .MuiChip-deleteIcon, & .MuiChip-root {
        color: ${({color, light, variant }) => variant === "outlined" ? color : (color ? (light? 'black' : 'white') : '--')};
    }
`;
ChipWrapp.defaultProps = {className: 'ChipWrapp'};

export const Status = (props: StatusProps) => {
    const { status, hints: allHints, labels, size, variant, chipStyles } = props;
    const hints = (allHints && allHints[status]) || {};
    const label = hints.label || (labels && labels[status]) || status;

    return (
        <ChipWrapp light={hints.light} color={hints.color} variant={variant} style={chipStyles}>
            <Tooltip title={hints.description || ""} aria-label={status}>
                <Chip label={label}
                    onDelete={hints.icon && (() => {})} 
                    deleteIcon={hints.icon} 
                    size={size}
                    variant={variant} />
            </Tooltip>
        </ChipWrapp>
    );
}