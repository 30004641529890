import React from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { TagDisplay } from './TagDisplay';
import { TagUsage } from './useTagManagement';

interface Props {
    data: TagUsage;
}

const RecordsContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    gap: 0.25rem;
    min-width: 500px;
`;

export const TagUsagePopup = (props: Props) => {
    const {
        close,
        tag,
        records,
        isLoading,
        isActive,
    } = props.data;

    const recordsSorted = records.map(r => r.name ? r : { ...r, name: `${r.entity} ${r.record_id}`})
        .sort((a,b) => (a.name || "") > (b.name || "") ? 1 : -1);
    
    return (
        <Dialog open={isActive} onClose={close}>
            <DialogTitle>
                <FormattedMessage id="tags.tag_usage"/>
                <div style={{ marginLeft: '0.5rem', display: 'inline-block' }} />
                {tag && <TagDisplay tag={tag} wrap />}
                
            </DialogTitle>
            <DialogContent>
                {isLoading && <LoadingIndicator />}
                <RecordsContainer>
                    {recordsSorted.map((r,i) => <TagDisplay key={r.name} tag={{ _id: i, label: r.name || "" }} />)}
                </RecordsContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}><FormattedMessage id="common.close"/></Button>
            </DialogActions>
        </Dialog>
    );
}
