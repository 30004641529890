import { useLoadedData } from "../../../toolympus/hooks/useLoadedData"
import { useSchema } from "../../../toolympus/hooks/useSchema";
import { Judge } from "../../Public/useJudgeRegistration"

export interface JudgeWithStats extends Judge {
    assigned: number;
    scored: number;
    remaining: number;
}

export const useJudgeAssignmentStats = () => {
    const data = useLoadedData<JudgeWithStats[]>("/api/assignment/documents/admin/judge-stats", []);
    const { judge: schema } = useSchema();

    return {
        ...data,
        data: data.data.sort((a,b) => a.remaining > b.remaining ? -1 : 1),
        schema
    };
}