import { useState } from "react";
import { apiFetch, FetchTypes } from "../../../../api/core";
import { LoadedData, useLoadedData } from "../../../../hooks/useLoadedData";
import { ScoringData, useScoring } from "../../ScoringSheets";
import { Player, Round } from "../types";

export interface ScoreParams {
    arbiter_id: string;
    round_id: string;
    access_code: string;
}

export interface RoundScore {
    _id: string;
    round_id: string;
    arbiter_id: string;
    score_key: string;
    score_label: string;
    score_type: string;
    position?: 1 | 2;
    sheet?: string;
    value?: number;
    is_scored: boolean;
}

export interface RoundScoreGrouped {
    group_key: string;
    label: string;
    is_double: boolean;
    left?: RoundScore;
    right?: RoundScore;
}

export interface RoundToScore extends Round {
    scores: RoundScore[];
};

export interface ScoreRoundData {
    round: LoadedData<RoundToScore>;
    getPlayer: (position: number) => Player;
    scoresGrouped: RoundScoreGrouped[];
    
    setPlayerSelectScore: (score: RoundScore, selectedPosition: 1 | 2) => void;

    activeScore: RoundScore | null;
    startScoring: (s: RoundScore) => void;
    cancelScoring: () => void;
    scoring: ScoringData;
}

const groupKey = (s: RoundScore) => {
    if(s.position) {
        return s.score_key.substr(0, s.score_key.length-2);
    } else {
        return s.score_key;
    }
}

const groupScores = (scores: RoundScore[]): RoundScoreGrouped[] => {
    const result: RoundScoreGrouped[] = [];
    scores.forEach(s => {
        if(s.score_type === "player-info") {
            return;
        }
        if(s.position) {
            const k = groupKey(s);
            const existingGroup = result.find(g => g.group_key === k);
            if(existingGroup) {
                if(s.position === 1) {
                    existingGroup.left = s;
                } else {
                    existingGroup.right = s;
                }
            } else {
                result.push({
                    left: s.position === 1 ? s : undefined,
                    right: s.position === 2 ? s : undefined,
                    label: s.score_label,
                    group_key: k,
                    is_double: true,
                });
            }
        } else {
            result.push({ left: s, label: s.score_label, group_key: s.score_key, is_double: false });
        }
    });

    return result;
}

const RequestParams = { noAuth: true };

export const useScoreRoundByCode = (apiPath: string, params: ScoreParams): ScoreRoundData => {
    const roundPath = `${apiPath}/pub/arbiter/${params.arbiter_id}/round/${params.round_id}`;
    const round = useLoadedData<RoundToScore>(`${roundPath}?access_code=${params.access_code}`,
        {} as RoundToScore, true, undefined, { requestParams: RequestParams });

    const setPlayerSelectScore = (score: RoundScore, position: 1 | 2) => {
        if(score && score._id) {
            apiFetch(`${roundPath}/score/${score._id}/value?access_code=${params.access_code}`, FetchTypes.PUT, { value: position }, { noAuth: true })
                .then(() => round.reload());
        }
    }

    const [activeScore, setActiveScore] = useState<RoundScore | null>(null);

    const startScoring = (s: RoundScore) => setActiveScore(s);
    const cancelScoring = () => setActiveScore(null);

    const scoring = useScoring(
        `${roundPath}/score/${activeScore?.sheet}/${activeScore?._id}?access_code=${params.access_code}`,
        activeScore?.sheet,
        activeScore?._id,
        { pathAsIs: true });

    const scoresGrouped = round.data && round.data.scores ? groupScores(round.data.scores) : [];

    return {
        round,
        getPlayer: p => (round.data && round.data.players && round.data.players[p]) || { party_id: "", display_name: "-" },
        scoresGrouped,

        setPlayerSelectScore,

        activeScore,
        startScoring,
        cancelScoring,
        scoring: {
            ...scoring,
            submit: () => scoring.submit().then(x => { round.reload(); cancelScoring(); return x; }),
        },
    };
}

interface RoundAccessConfig {
    queryParams?: Record<string, string>;
}

export const useScoreRoundAuthed = (apiPath: string, params: Pick<ScoreParams, 'round_id'>, cfg?: RoundAccessConfig): ScoreRoundData => {
    const queryParams = Object.entries(cfg?.queryParams || {}).map(([k,v]) => `${k}=${v}`).join("&");
    const queryParamsStr = queryParams ? `?${queryParams}` : "";

    const roundPath = `${apiPath}/arbiter/round/${params.round_id}`;
    const round = useLoadedData<RoundToScore>(`${roundPath}${queryParamsStr}`, {} as RoundToScore, true);

    const setPlayerSelectScore = (score: RoundScore, position: 1 | 2) => {
        if(score && score._id) {
            apiFetch(`${roundPath}/score/${score._id}/value${queryParamsStr}`, FetchTypes.PUT, { value: position })
                .then(() => round.reload());
        }
    }

    const [activeScore, setActiveScore] = useState<RoundScore | null>(null);

    const startScoring = (s: RoundScore) => setActiveScore(s);
    const cancelScoring = () => setActiveScore(null);

    const scoring = useScoring(
        `${roundPath}/score/${activeScore?.sheet}/${activeScore?._id}${queryParamsStr}`,
        activeScore?.sheet,
        activeScore?._id,
        { pathAsIs: true });

    const scoresGrouped = round.data && round.data.scores ? groupScores(round.data.scores) : [];

    return {
        round,
        getPlayer: p => (round.data && round.data.players && round.data.players[p]) || { party_id: "", display_name: "-" },
        scoresGrouped,

        setPlayerSelectScore,

        activeScore,
        startScoring,
        cancelScoring,
        scoring: {
            ...scoring,
            submit: () => scoring.submit().then(x => { round.reload(); cancelScoring(); return x; }),
        },
    };
}

