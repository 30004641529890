import { apiFetch, FetchTypes } from "../../toolympus/api/core";
import { OpenRegistrationReviewData, useOpenRegistrationReview } from "../../toolympus/components/Contests/Registrations";
import { mergeSchema, useSchema } from "../../toolympus/hooks/useSchema";
import { Judge, JudgeSchemaChanges } from "../Public/useJudgeRegistration";
import { FormComments, useFormComments } from "./useComments";

export interface JudgeReview extends OpenRegistrationReviewData<Judge> {
    approve: () => Promise<any>;
    reject: () => Promise<any>;

    comments: FormComments;
}

export const useJudgeReview = (): JudgeReview => {
    const { judge: judgeSchema } = useSchema();
    const schema = mergeSchema(judgeSchema, JudgeSchemaChanges);

    const data = useOpenRegistrationReview<Judge>({
        apiPath: "/api/admin/judge",
        schema,
    })
    
    const comments = useFormComments('judge', data.id);

    
    const approve = () => {
        return apiFetch<Judge>(`/api/admin/judge/${data.id}/approve`, FetchTypes.PUT, {
            approval_status: "approved"
        })
            .then(t => data.setData(t));
    }

    const reject = () => {
        return apiFetch<Judge>(`/api/admin/judge/${data.id}/approve`, FetchTypes.PUT, {
            approval_status: "rejected",
         })
            .then(t => data.setData(t));
    }


    return {
        ...data,
        approve,
        reject,

        comments,
    }
}
