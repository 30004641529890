import { Paper, FormControl, InputLabel, Input, Button, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { FieldDefinition, TableForFields } from "../schemed";
import { SelectDictionary } from "./SelectDictionary";
import { LoadingIndicator } from "../primitives/LoadingIndicator";
import { SaveButton } from "../primitives/Buttons";
import styles from '@emotion/styled'
import { useSchema } from "../../hooks/useSchema";
import { Form } from "../primitives/Forms";
import { OccupyFreeSpace } from '../primitives/ActionRow';
import { useDictsApi } from "../../hooks/useDictionaries";
import { FormattedMessage } from "react-intl";
import { useQuery } from "../../hooks/useQueryParamsState";
import { useHistory, useLocation } from "react-router-dom";
import { useKeyupWithAltEvent } from "../../hooks/useDocumentEvent";

const AddForm = styles.div`
    height: 60px;
    display: flex;
    justify-content: space-start;
    align-items: flex-end;
    flex-direction: row;
    padding: 10px;
`;

export interface DictionariesProps {
    languages?: string[];
    apiPath?: string;
}

export const DictionariesList = (props: DictionariesProps) => {
    const languages = props.languages || [];
    const { 
        loading,
        dicts, 
        dict, 
        newCodeRecord, 
        setDictByKey, 
        onUpdate, 
        setNewCodeRecord, 
        add,
        hasChanges,
        save,
    } = useDictsApi(props.apiPath);
    
    const { dictionary: schemaSrc } = useSchema();
    const labelTranslationsSchemas = languages.reduce(
        (r,l) => ({ ...r, [`label_${l}`]: { ...schemaSrc["label"], label: `${schemaSrc["label"].label} (${l})` }}),
        {});
    const schema = { ...schemaSrc, ...labelTranslationsSchemas };

    const query = useQuery();
    const history = useHistory();
    const location = useLocation();
    const queryParam = "dictionary_id";
    const selectedDictId = query.get(queryParam);

    useEffect(() => {
        if(selectedDictId && (!dict || selectedDictId !== dict.id)) {
            setDictByKey(selectedDictId);
        }
    }, [dict, selectedDictId, setDictByKey]);

    useKeyupWithAltEvent('s', () => save());
    useKeyupWithAltEvent('ы', () => save());

    const selectDictionary = (key: string) => {
        setDictByKey(key);
        query.set(queryParam, key);
        history.replace({ pathname: location.pathname, search: `?${query.toString()}`});
    }

    
    const fields: FieldDefinition[] = ["code", "label"].concat(languages.map(l => `label_${l}`)).concat(["sortorder", "comment", "disabled"])
        .map(key => [key, { editable: key !== 'code' }]);
    
    return (
        <Form title={<FormattedMessage id="dictionaries.title" />}
            headerItems={(
                <>
                    <OccupyFreeSpace />
                    {dict && (
                        loading ? <LoadingIndicator /> :
                            (!hasChanges ? 
                                <Typography color="primary" variant="caption">
                                    <FormattedMessage id="dictionaries.statusSaved" />
                                </Typography>
                                :
                                <SaveButton action={save} />))}
                </>
            )}>
            <SelectDictionary 
                value={dict?.id}
                values={Object.keys(dicts).map(key => ({value: key, label: dicts[key].comment })).sort((a,b) => a.label > b.label ? 1 : -1)}
                onChange={selectDictionary}
                />
            
            <Paper>
                {dict && 
                    <>
                        <AddForm>
                            <FormControl>
                                <InputLabel><FormattedMessage id="dictionaries.newRecordLabel" /></InputLabel>
                                <Input value={newCodeRecord} onChange={({target: {value}}) => setNewCodeRecord(value)} />
                            </FormControl>
                            <Button disabled={loading || !newCodeRecord.trim()} onClick={add}><FormattedMessage id="dictionaries.addRecord" /></Button>
                        </AddForm>
                        <TableForFields 
                            fields={fields}
                            schema={schema}
                            data={dict.records}
                            onChange={onUpdate}
                            getRowId={r => r.code}
                            editByOneRow
                        />
                    </>
                }
            </Paper>
        </Form>);
}