import React, { useState, useEffect, useContext, createContext, ComponentPropsWithoutRef, useCallback, PropsWithChildren } from 'react';
import { apiFetch, FetchTypes } from '../api/core';

export interface DictionaryRecord {
    code: string;
    label: string;
    disabled: boolean;
    comment: string;
    sortorder: number;
    label_translations?: { [_: string]: string };
}

export interface Dictionary {
    id: string;
    name: string;
    comment: string;
    records: DictionaryRecord[];

    values?: { value: any, label: string }[];
    valueDict?: any;
}

export interface Dictionaries {
    [k: string]: Dictionary;
}

export type DictionariesService = Dictionaries & {
    reload: () => void;
}

const prepareDictionary = (dictionary: Dictionary) => {
    const values = (dictionary.records || []).map(({ code, label }) => ({ value: code, label }));
    const valueDict = values.reduce((result, { value, label }) => ({ ...result, [value]: label}), {});

    return {
        ...dictionary,
        values,
        valueDict,
    }
}

export const DictionariesContext = createContext<DictionariesService>({ reload: () => { }} as any);

interface DictionariesConfig {
    apiPath?: string;
    lang?: string;
}

export const DictionariesProvider = (props: ComponentPropsWithoutRef<any> & DictionariesConfig) => {
    const [dicts, setDicts] = useState<Dictionaries>({});

    const apiPath = props.apiPath || "/api/dictionary";

    const reload = useCallback(() => {
        apiFetch<Dictionary[]>(`${apiPath}/all-with-records${props.lang ? `?lang=${props.lang}` : ""}`)
            .then(ds => {
                const dicts = Object.values(ds).reduce((result,d) => ({ ...result, [d.name]: prepareDictionary(d) }), {});
                setDicts(dicts)
            })
    }, [apiPath, props.lang])

    useEffect(() => reload(), [reload]);

    const accessGuard = {
        get: (target: Dictionaries, name: string) => {
            const result = target[name];
            if(!result) {
                console.log(`Dictionary ${name} not loaded yet`);
                return prepareDictionary({} as Dictionary);
            } else {
                return result;
            }
        }
    };

    const guardedDicts = new Proxy(dicts, accessGuard);
    (guardedDicts as any).reload = () => {
        reload();
    }

    return (
        <DictionariesContext.Provider value={guardedDicts as DictionariesService}>
            {props.children}
        </DictionariesContext.Provider>
    )
}

interface DictionarisManual {
    dictionaries: Dictionaries;
}

export const DictionariesManualProvider = (props: PropsWithChildren<DictionarisManual>) => {
    const accessGuard = {
        get: (target: Dictionaries, name: string) => {
            const result = target[name];
            if(!result) {
                console.log(`Dictionary ${name} not loaded yet`);
                return prepareDictionary({} as Dictionary);
            } else {
                return result;
            }
        }
    };

    const guardedDicts = new Proxy(props.dictionaries, accessGuard);
    (guardedDicts as any).reload = () => { }

    return (
        <DictionariesContext.Provider value={guardedDicts as DictionariesService}>
            {props.children}
        </DictionariesContext.Provider>
    )
}

export const useDictionaries = (): DictionariesService => useContext(DictionariesContext);

export interface DictsApi {
    loading: boolean;
    dicts: Dictionaries;
    dict: Dictionary | null;
    onUpdate: (record: DictionaryRecord, changes: any) => void;
    setDictByKey: (key: string) => void;
    
    add: () => void;
    newCodeRecord: string;
    setNewCodeRecord: (v: string) => void;
    hasChanges: boolean;
    save: () => Promise<void>;
};

export const useDictsApi = (apiPath: string = "/api/dictionary"): DictsApi => {
    const [dicts, setDictionaries] = useState<Dictionaries>({});
    const [loading, setLoading] = useState(false);
    const [dict, setCurrentDict] = useState<Dictionary | null>(null);
    const [newCodeRecord, setNewCodeRecord] = useState("");

    const [accumulatedChanges, setAccumulatedChanges] = useState<Record<string, Partial<DictionaryRecord>>>({});

    const accumulateChange = (dictCode: string, recordCode: string, lang: string | undefined, changes: Partial<DictionaryRecord>) => {
        if(changes?.sortorder === null) {
            delete changes.sortorder;
        }
        if(Object.keys(changes).length === 0) {
            return;
        }

        const k = lang ? `${dictCode}::${recordCode}::${lang}` : `${dictCode}::${recordCode}`;
        setAccumulatedChanges(c => ({ ...c, [k]: { ...c[k], ...changes }}));
    }

    const save = () => {
        setLoading(true);
        return Promise.all(Object.entries(accumulatedChanges).map(([k, c]) => {
            const [dictCode, recordCode, lang] = k.split("::");
            const langUrlBit = lang ? `lang=${lang}` : '';
            return apiFetch<DictionaryRecord>(`${apiPath}/${dictCode}/${recordCode}?${langUrlBit}`, FetchTypes.PUT, c);
        })).then(() => {
            setAccumulatedChanges({});
            load();
        });
    }

    const setDicts = (ds: Dictionaries) => {
        Object.values(ds).forEach(d => {
            d.records.forEach(r => {
                Object.keys(r.label_translations || {}).forEach(l => { (r as { [_: string]: any })[`label_${l}`] = (r.label_translations || {})[l] })
            })
        });

        setDictionaries(ds);
    }

    useEffect(() => {
        load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const load = async () => {
        setLoading(true);
        const data = await apiFetch<Dictionaries>(`${apiPath}/all-with-records`);

        setDicts(data);
        setLoading(false);
    }

    const add = async () => {
        if (!dict) return;
        setLoading(true);

        const lastSortorder = dict.records.length > 0 ? dict.records[dict.records.length - 1].sortorder : 0;

        const record: DictionaryRecord = {code: newCodeRecord, comment: '', label: '', sortorder: lastSortorder + 1, disabled: false };
        setNewCodeRecord("");

        try {
            const records = await apiFetch<DictionaryRecord[]>(`${apiPath}/${dict.id}`, FetchTypes.POST, record);
            dict.records = records;
            setDicts({...dicts});
        } finally {
            setLoading(false);
        }
    }

    const onUpdate = (record: DictionaryRecord, changes: any) => {
        if(!dict) return;

        const resRecord = {...record, ...changes};

        const translationUpdated = Object.keys(changes).find(k => k.startsWith("label_"));
        if(translationUpdated) {
            const lang = translationUpdated.substr(6);
            resRecord.label_translations = { ...resRecord.label_translations, [lang]: changes[translationUpdated] };
            accumulateChange(dict.id, record.code, lang, { label: changes[translationUpdated]});
        } else {
            accumulateChange(dict.id, record.code, undefined, changes);
        }

        const replaceIdx = dict.records.findIndex(d => d.code === resRecord.code);
        if(replaceIdx >= 0) {
            const updatedDict = { ...dict };
            updatedDict.records[replaceIdx] = resRecord;
            setCurrentDict(updatedDict);
        }
    }

    const setDictByKey = (key: string) => {
        setCurrentDict(dicts[key])
    }

    const setNewCode = (value: string) => {
        if(/^[a-zA-Z0-9\-_]*$/.test(value)) {
            setNewCodeRecord(value);
        }
    }

    return {
        newCodeRecord,
        loading, 
        dicts, 
        dict,
        add, 
        onUpdate,
        setDictByKey,
        setNewCodeRecord: setNewCode,

        save,
        hasChanges: Object.keys(accumulatedChanges).length > 0,
    }
}
