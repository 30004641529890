import React from "react";
import { JudgeRoundsPage } from "../components/Admin/Finals/JudgeRoundsPage";
import { ChatsUnreadIndicator } from "../components/Chats/ChatsUnreadIndicator";
import { JudgeChatWithOrgPage } from "../components/Chats/ChatWithOrgPage";
import { InfoPage } from "../components/InfoPage";
import { JudgeDocumentsPage } from "../components/Judge/JudgeDocumentsPage";
import { JudgeProfilePage } from "../components/Judge/JudgeProfilePage";
import { JudgeScoreDocumentPage } from "../components/Judge/JudgeScoreDocumentPage";
import { PublicResultsContainer } from "../toolympus/components/Contests/Grid";
import { MenuRoute } from "../toolympus/components/frame";
import { FormWrapper } from "../toolympus/components/primitives/Forms";

export const JudgeApplicationRoutes: MenuRoute[] = [
    { 
        path: '/judge/info',
        title: 'Информация',
        component: () => <InfoPage slug="judge-info" />,
    },
    { 
        path: '/judge/profile',
        title: 'Профиль',
        component: JudgeProfilePage,
    },
    { 
        path: '/judge/documents/:id',
        title: 'Оценка документа',
        component: () => <JudgeScoreDocumentPage />,
        resolveHidden: (roles, ctx) => roles.includes("judge"),
        hidden: true,
    },
    { 
        path: '/judge/documents',
        title: 'Процессуальные документы',
        component: () => <JudgeDocumentsPage />,
        resolveHidden: (roles, ctx) => roles.includes("judge") && ctx?.state?.documents_assessment_available,
        alsoActivateForPath: path => path.startsWith('/judge/documents/'),
    },
    { 
        path: '/judge/rounds',
        title: 'Устные раунды',
        component: JudgeRoundsPage,
        resolveHidden: (roles, ctx) => roles.includes("judge") && ctx?.state?.finals_available,
    },
    { 
        path: '/judge/rounds-broadcast',
        title: 'Трансляции и записи',
        component: () => <InfoPage slug="rounds-broadcast" />,
        resolveHidden: (roles, ctx) => roles.includes("judge") && ctx?.state?.finals_broadcast_page_available,
    },
    { 
        path: '/judge/rounds-results',
        title: 'Результаты Конкурса',
        component: () => <FormWrapper>
                <PublicResultsContainer apiPath="/api/admin/finals" kind="arbiter" pageApiPath="/api/page" pageSlug="rounds-results" />
            </FormWrapper>,
        resolveHidden: (roles, ctx) => roles.includes("judge") && ctx?.state?.finals_results_available,
    },
    { 
        path: '/judge/chat-org',
        title: 'Чат с организатором',
        suffix: <ChatsUnreadIndicator />,
        component: JudgeChatWithOrgPage,
    },
];

export const JudgeRoutes: MenuRoute[] = [

];
