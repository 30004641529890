import { Paper, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { OccupyFreeSpace } from "./ActionRow";

export const AlertWrapper = styled(Paper)`
    padding: 1rem;
    margin: 0.5rem 0;
`;

export const AlertTitle = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;

    & > svg:first-child {
        margin-right: 0.5rem;
    }
`;

export const AlertColor = {
    success: "#22cc33",
    warning: "#fabb00",
    error: "#d32f2f",
    info: "#00bbdd",
}

interface Props {
    title?: ReactNode;
    icon?: ReactNode;
    action?: ReactNode;
    children?: ReactNode;
}

export const Alert = ({ title, icon, action, children }: Props) => (
    <AlertWrapper>
        {title && <AlertTitle>
            {icon} <Typography>{title}</Typography>
            {action && <OccupyFreeSpace />}
            {action}
            </AlertTitle>}
        {children}
    </AlertWrapper>
);
