import React from "react";
import { PageWrapper } from "../components/Common.styles";
import { MenuRoute } from "../toolympus/components/frame";
import { EmailConfirmationContainer } from "../toolympus/hooks/auth/EmailConfirmationBlock";

export const CommonRoutes: MenuRoute[] = [
    { 
        path: '/confirm-email',
        title: 'Подтверждение Email',
        component: () => <PageWrapper><EmailConfirmationContainer apiPath="/api/confirm" /></PageWrapper>,
        hidden: true,
    },
];

