import { useState } from "react";
import { apiFetch, downloadFile, FetchTypes } from "../../toolympus/api/core";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { FieldSorting, useFieldSorting } from "../../toolympus/hooks/useFieldSorting";
import { LoadedData, useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { mergeSchema, Schema, useSchema } from "../../toolympus/hooks/useSchema";
import { Team } from "../Public/useTeamRegistrationStageOne";
import { TableComments, useListComments } from "./useComments";
import { TeamReview, useTeamReview } from "./useTeamReview";

interface TeamListData extends LoadedData<Team[]> {
    schema: Schema;
    view: string;
    setView: (v: string) => void;
    filter: string;
    setFilter: (v: string) => void;
    exportTeams: () => void;
    exportTeamsStats: () => void;
    exportDocuments: () => void;
    assignDocLatePenalties: () => void;
    teamReview: TeamReview;
    comments: TableComments;
    sorting: FieldSorting;

}

export const TeamViews: [string, string][] = [
    ["Устные раунды", "finals"],
    ["Документы: загружены", "docs_uploaded"],
    ["Документы: нет", "docs_not"],
    ["Одобренные", "approved"],
    ["На рассмотрении", "review"],
    ["На доработке", "needs_changes"],
    ["Не заполнены", "not_filled"],
    ["Отказ", "rejected"],
    ["Все", "all"],
];

export const useTeamList = (): TeamListData => {
    const { team: teamSchema } = useSchema();
    const sorting = useFieldSorting({
        disallowedFields: ["participants_cnt", "coaches_cnt", "is_filled"],
    });
    const data = useLoadedData<Team[]>(`/api/admin/team?${sorting.queryParameter}`, []);
    const teamReview = useTeamReview();
    const comments = useListComments('team');

    const [view, setView] = useState<string>("review");

    const approve = () => teamReview.approve().then(x => { data.reload(); return x; });
    const reject = (x: boolean, y: string) => teamReview.reject(x,y).then(x => { data.reload(); return x; });

    const { filter, setFilter, filterData } = useTextFilter<Team>(t => `${t.number || ""} ${t.email} ${t.university} ${t.city} ${(t.members || []).map(m => m.lastname).join(" ")} ${(t.coaches || []).map(m => m.lastname).join(" ")}`);

    const assignDocLatePenalties = () => {
        apiFetch("/api/admin/document/assign-late-penalty", FetchTypes.POST)
            .then(() => data.reload());
    }

    const schema = mergeSchema(teamSchema, {
        participants_cnt: { label: "Участников" },
        coaches_cnt: { label: "Тренеров" },
    });

    const viewFilter: Record<string, (t: Team) => boolean> = {
        finals: t => !!t.finals_stage,
        docs_uploaded: t => t.approval_status === "approved" && !!t.documents?.doc1?.document_id && !!t.documents?.doc2?.document_id,
        docs_not: t => t.approval_status === "approved" && (!t.documents?.doc1?.document_id || !t.documents?.doc2?.document_id),
        approved: t => t.approval_status === "approved",
        needs_changes: t => t.approval_status === "needs_changes",
        rejected: t => t.approval_status === "rejected",
        not_filled: t => !t.approval_status && !t.is_filled,
        review: t=> !t.approval_status && t.is_filled,
    };

    const filterByView = (teams: Team[]): Team[] => teams.filter(viewFilter[view] || (() => true));

    const teams = filterData(filterByView(data.data.map(t => ({ ...t, participants_cnt: t.members?.length, coaches_cnt: t.coaches?.length }))));

    return {
        ...data,
        data: teams,
        schema,
        
        view,
        setView,

        filter,
        setFilter,

        exportTeams: () => downloadFile("/api/admin/export/teams", "команды.xlsx"),
        exportTeamsStats: () => downloadFile("/api/admin/export/teams-stats", "команды.xlsx"),
        exportDocuments: () => downloadFile("/api/admin/document/export", "документы.zip"),
        assignDocLatePenalties,

        sorting,

        teamReview: {
            ...teamReview,
            approve,
            reject,
        },

        comments,
    }
}