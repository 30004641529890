import moment from "moment";
import { useMemo, useState } from "react";

interface Config {
    timeslots: string[] | null;
    updateTimeslots: (v: string[]) => void;
}

export const timeslotFormat = "yyyy-MM-DD HH:mm";
export const dateFormat = "yyyy-MM-DD";

export const timeslotToDate = (slot: string) => moment(slot, timeslotFormat).format(dateFormat);

export const formatSlotDayDisplay = (d: string) => moment(d, dateFormat).format("D MMMM yyyy");
export const formatSlotTimeDisplay = (ts: string) =>moment(ts, timeslotFormat).format("HH:mm");

export const useViewTimeslots = (timeslots: string[] | null | undefined) => {
    const days = useMemo(() => {
        const ds = (timeslots || []).map(timeslotToDate)
        return Array.from(new Set(ds)).sort();
    }, [timeslots]);

    const timeslotsByDay = useMemo(() => {
        return (timeslots || []).reduce<Record<string, string[]>>((r, slot) => {
            const dayK = timeslotToDate(slot);
            r[dayK] = [...(r[dayK] || []), slot];
            return r;
        },
        {});
    }, [timeslots])
    
    return {
        days,
        timeslotsByDay,
    }
}

export const useTimeslotsConfig = (config: Config) => {
    const [newTimeslot, setNewTimeslot] = useState<moment.Moment | null>(null);

    const startAddTimeslot = (other?: string) => {
        if(other) {
            setNewTimeslot(moment(other, timeslotFormat)); // FIX
        } else if(config.timeslots && config.timeslots.length) {
            setNewTimeslot(moment(config.timeslots.slice(-1)[0], timeslotFormat));
        } else {
            setNewTimeslot(moment());
        }
    };

    const finishAddTimeslot = () => {
        if(newTimeslot) {
            config.updateTimeslots([...(config.timeslots || []), moment(newTimeslot).format(timeslotFormat)]);
        }
        setNewTimeslot(null);
    }

    const removeTimeslot = (slot: string) => {
        config.updateTimeslots((config.timeslots || []).filter(s => s !== slot));
    }

    const { days, timeslotsByDay } = useViewTimeslots(config.timeslots);
    
    return {
        days,
        timeslotsByDay,
        
        startAddTimeslot,
        finishAddTimeslot,
        cancelAddTimeslot: () => { setNewTimeslot(null); },
        removeTimeslot,

        isAddingTimeslot: !!newTimeslot,
        newTimeslot,
        setNewTimeslot,
    }
}

export type TimeslotsConfigData = ReturnType<typeof useTimeslotsConfig>;
