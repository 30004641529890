import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Schema } from '../../hooks/useSchema';
import { FormGrid } from '../primitives/Forms';
import { FormControl } from '../schemed';
import { Tag } from './useTagManagement';

interface TagEdit {
    data: Partial<Tag> | null;
    update: (v: Partial<Tag>) => void;
    canSubmit: boolean;
    submit: () => void;
    close: () => void;

    isActive: boolean;
    isLoading: boolean;
}

interface Props {
    data: TagEdit;
    schema: Schema;
    title: ReactNode;
    saveLabel?: ReactNode;
}

export const TagEditPopup = (props: Props) => {
    const { title, saveLabel, data, schema } = props;
    const { isActive, close, submit } = data;

    return (
        <Dialog open={isActive} onClose={() => close()} fullWidth maxWidth="sm">
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                {data.data && <FormGrid columns="1fr">
                    <FormControl
                        schema={schema["label"]}
                        field="label"
                        row={data.data}
                        onChange={(o,c) => data.update(c)}
                        />
                </FormGrid>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()}><FormattedMessage id="common.cancel" /></Button>
                <Button variant="contained" color="primary" onClick={() => submit()}>{saveLabel || <FormattedMessage id="common.save" />}</Button>
            </DialogActions>
        </Dialog>
    );
}

export const useNewTagData = (onSubmit: (t: Partial<Tag>) => Promise<Tag>): TagEdit & { open: (label?: string) => void } => {
    const [data, setData] = useState<Partial<Tag | null>>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const close = () => setData(null);

    return {
        data,
        update: c => setData(d => ({ ...d, ...c })),
        close,
        isActive: !!data,
        canSubmit: (data?.label?.length || 0) > 0,
        submit: () => {
            if(!data) {
                return;
            }
            setIsLoading(true);
            onSubmit(data)
                .then(() => { setIsLoading(false); close(); })
                .catch(e => { setIsLoading(false); throw e; });
        },

        isLoading,

        open: (label?: string) => setData({ label: (label || "") }),
    }
}


export const useEditTagData = (onSubmit: (original: Tag, changes: Partial<Tag>) => Promise<Tag>): TagEdit & { open: (tag: Tag) => void } => {
    const [data, setData] = useState<Partial<Tag> | null>(null);
    const [original, setOriginal] = useState<Tag | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);

    const close = () => { setData(null); setOriginal(null); };

    return {
        data,
        update: c => { setIsChanged(true); setData(d => ({ ...d, ...c }))},
        close,
        isActive: !!data,
        canSubmit: !!data && isChanged && (data.label?.length || 0) > 0,
        submit: () => {
            if(!data || !original) {
                return;
            }
            setIsLoading(true);
            onSubmit(original, data)
                .then(() => { setIsLoading(false); close(); })
                .catch(e => { setIsLoading(false); throw e; });
        },

        isLoading,

        open: (tag) => { setIsChanged(false); setOriginal(tag); setData({ label: tag.label }); },
    }
}
