import { apiFetch } from "../../toolympus/api/core";
import { OpenRegistrationReviewData, RegistrationApproval, useOpenRegistrationReview } from "../../toolympus/components/Contests/Registrations";
import { FieldType, mergeSchema, useSchema } from "../../toolympus/hooks/useSchema";
import { Secretary } from "../Public/useSecretaryRegistration";
import { useFormComments } from "./useComments";

export interface SecretaryReview extends OpenRegistrationReviewData<Secretary> {
    approval: RegistrationApproval<Secretary>,
}

const ApiPath = '/api/admin/secretary';

export const useSecretaryReview = (): SecretaryReview => {    
    const { secretary: schema } = useSchema();

    const data = useOpenRegistrationReview<Secretary>({
        apiPath: ApiPath,
        schema: mergeSchema(schema, {
            guide: { type: FieldType.textlong },
            university: { type: FieldType.textlong },
            affiliation: { type: FieldType.textlong },
            experience_secretary: { type: FieldType.textlong },
        }),
    })
    
    const comments = useFormComments("secretary", data.id);

    
    const approval = {
        isRegistrationApproved: (s: Secretary) => !!s.approved,
        update: (s: Secretary, approve: boolean, comment?: string) => {
            return apiFetch<Secretary>(`${ApiPath}/${s._id}/approve`, "put", { approved: !!approve })
                .then(s => {
                    data.reload();
                    return s;
                })
        }
    };

    return {
        ...data,
        approval,
        comments,
    }
}
