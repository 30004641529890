import React from 'react';
import { Form } from '../../../toolympus/components/primitives/Forms';
import { TabPanel, TabsHeader, useTabsState } from '../../../toolympus/components/primitives/Tabs';
import { DocumentsAssessmentList } from './DocumentsAssessmentList';
import { JudgeStats } from './JudgeStats';

export const DocumentsAssessmentPage = () => {
    const tabs = useTabsState([
        ["assess", "Оценка"],
        ["judge-stats", "Судьи"],
    ], "assess", "tab");

    return (
        <Form
            formPaperProps={{ style: { display: 'flex', flexFlow: 'column', overflow: 'hidden', maxHeight: '100%' }}}
            formBodyProps={{ style: { overflow: 'auto' }}}
            headerItems={
                <>
                    
                </>
            }>

            <TabsHeader noMargin state={tabs} />

            <TabPanel state={tabs.forTab("assess")}>
                <DocumentsAssessmentList />
            </TabPanel>

            <TabPanel state={tabs.forTab("judge-stats")}>
                <JudgeStats />
            </TabPanel>
        </Form>
    );
}
