import { IconButton, Typography } from '@material-ui/core';
import { AssignmentReturnedOutlined } from '@material-ui/icons';
import React, { ReactElement } from 'react';
import { ActionRow, OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { ButtonOptionPicker } from '../../toolympus/components/primitives/ButtonOptionPicker';
import { PseudoLink } from '../../toolympus/components/primitives/Common.styles';
import { Form } from '../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { SearchField } from '../../toolympus/components/primitives/SearchField';
import { cropText } from '../../toolympus/components/primitives/TextHelpers';
import { Tooltip } from '../../toolympus/components/primitives/Tooltip';
import { FieldSettingsPopupButton, TableForFields, TableRowButtons, useFields } from '../../toolympus/components/schemed';
import { useTags } from '../../toolympus/components/Tags';
import { TimeslotsDisplay } from '../Judge/JudgeTimeslotsSelection';
import { GoToChatButton } from './Common';
import { JudgeReviewPopup } from './JudgeReviewPopup';
import { JudgeViews, useJudgeList } from './useJudgeList';

interface Props {
    
}

export const JudgeListPage = (props: Props) => {
    const {
        data,
        schema,
        isLoading,
        filter,
        setFilter,
        view,
        setView,
        comments,
        judgeReview,
        exportJudges,
        sorting,
    } = useJudgeList();

    const conflicts = useTags('/api/conflict', "judge");

    const fields = useFields({
        defaultFields: [
            "email",
            "chat_comment_buttons",
            "approval_status",
            "lastname",
            "firstname",
            "middlename",
            "phone",
            "city",
            "job",
            "experience",
            "affiliation",
            "conflicts",
            "judge_intention_oral",
            "judge_intention_written",
            "judge_written_works_nbr",
        ],
        extraSettings: {
            email: { cellStyle: { whiteSpace: 'nowrap'}},
            city: { cellStyle: { whiteSpace: 'nowrap'}},
            job: { cellStyle: { minWidth: 200 }},
            experience: { cellStyle: { minWidth: 300 }},
            affiliation: { cellStyle: { minWidth: 300 }},
            conflicts: { label: 'Конфликты' },
            chat_comment_buttons: { label: ' '},
        },
        blacklist: [
            "password",
            "passwordRepeat",
            "assigned",
            "scored",
            "remaining",
        ],
        schema,
        outOfSchemaFields: [
            "chat_comment_buttons",
            "conflicts",
        ],
        storageKey: "__femida_judgelist_columns",
    });

    return (
        <Form
            formPaperProps={{ style: { display: 'flex', flexFlow: 'column', overflow: 'hidden', maxHeight: '100%' }}}
            formBodyProps={{ style: { overflow: 'hidden' }}}
            headerItems={
                <>
                    <ButtonOptionPicker
                        options={JudgeViews}
                        selected={view}
                        setSelected={setView}
                    />
                    {isLoading && <LoadingIndicator sizeVariant="s" />}
                    <OccupyFreeSpace />
                    <SearchField
                        filter={filter}
                        setFilter={setFilter}
                        doSearch={() => { }}
                        buttonLabel=" "
                        />
                    <IconButton size="small" onClick={exportJudges}>
                        <AssignmentReturnedOutlined />
                    </IconButton>
                    <FieldSettingsPopupButton fieldsSettings={fields} />
                </>
            }>
            
            <ActionRow>
                <OccupyFreeSpace />
                <Typography variant="caption">Судей: {data.length}</Typography>
            </ActionRow>

            <TableForFields
                schema={schema}
                data={data}
                sorting={sorting}
                fields={fields.activeFields}
                fieldElement={f => {
                    switch(f) {
                    case "email":
                    case "lastname":
                    case "firstname":
                    case "middlename":
                        return (row, _, orig) => <PseudoLink onClick={() =>  judgeReview.open(row._id)}>{orig}</PseudoLink>
                    case "experience":
                        return (row) => cropText(row.experience, 100) || "";
                    case "conflicts":
                        return row => conflicts.displayForRecord(row._id) as ReactElement<any, any>;
                    case "timeslots_selected":
                        return row => <TimeslotsDisplay slots={row.timeslots_selected} />;
                    case " ":
                        return row => <TableRowButtons>
                            <Tooltip text="Комментарии">
                                {comments.buttonForRecord(row._id) as ReactElement<any, any>}
                            </Tooltip>
                            <Tooltip text="Чат" withWrapper>
                                <GoToChatButton to={`/admin/chat-org/${row._id}`} />
                            </Tooltip>
                        </TableRowButtons>;
                    default:
                        return null;
                    }
                }}
                />
            {comments.popup}
            <JudgeReviewPopup data={judgeReview} conflicts={conflicts} />
            {conflicts.popup}
        </Form>
    );
}
