import React, { useState } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { SimpleButton } from '../../toolympus/components/primitives/Buttons';
import { useRestorePassword } from '../../toolympus/hooks/auth';
import { LoginLikePage } from './LoginLikePage';
import { Link, CaptionAndLinksBlock } from './Common';
import { FormGrid } from '../../toolympus/components/primitives/Forms';

export const PasswordRestorePage = () => {
    const { data, update, send, isSaving } = useRestorePassword("/api/restore-access/reset");
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const sendR = () => send().then(() => setIsSuccess(true));

    return (
        <LoginLikePage>
            <CaptionAndLinksBlock>
                <Typography variant="h3">изменение пароля</Typography>
            </CaptionAndLinksBlock>
            {!isSuccess && <FormGrid columns="1fr">
                <TextField
                    label={<FormattedMessage id="restorePassword.password"/>}
                    name='password'
                    type='password'
                    variant='outlined'
                    value={data.password}
                    onChange={e => update({ ...data, password: e.target.value || '' })} />
                <TextField
                    label={<FormattedMessage id="restorePassword.passwordRepeat"/>}
                    name='password repeat'
                    type='password'
                    variant='outlined'
                    value={data.passwordRepeat}
                    onChange={e => update({ ...data, passwordRepeat: e.target.value || '' })} />
                <SimpleButton
                    label={<FormattedMessage id="restorePassword.submit"/>}
                    action={sendR}
                    isLoading={isSaving}
                />
            </FormGrid>}
            {isSuccess && <Typography>Пожалуйста, <Link to="/login">передите на страницу входа</Link> и авторизуйтесь с помощью нового пароля.</Typography>}
        </LoginLikePage>
    );
}
