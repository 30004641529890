import React, { ReactElement } from 'react';
import { Button, IconButton, Typography } from '@material-ui/core';
import { downloadFile } from '../../toolympus/api/core';
import { ActionRow, OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { PseudoLink } from '../../toolympus/components/primitives/Common.styles';
import { Form } from '../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { SearchField } from '../../toolympus/components/primitives/SearchField';
import { FieldSettingsPopupButton, TableForFields, TableRowButtons, useFields } from '../../toolympus/components/schemed';
import { TeamViews, useTeamList } from './useTeamList';
import { TeamReviewPopup } from './TeamReviewPopup';
import { GoToChatButton } from './Common';
import { AssignmentReturnedOutlined } from '@material-ui/icons';
import { Tooltip } from '../../toolympus/components/primitives/Tooltip';
import { DocLink, getTeamDocumentName } from './DocumentsControls';
import { useContestState } from '../../useContestState';
import { useTags } from '../../toolympus/components/Tags';
import { TabsHeader, TabsStateForTab } from '../../toolympus/components/primitives/Tabs';



export const TeamListPage = () => {
    const { 
        data,
        schema,
        isLoading,
        filter,
        setFilter,
        exportTeams,
        exportTeamsStats,
        exportDocuments,
        assignDocLatePenalties,
        teamReview,
        comments,
        view,
        setView,
        sorting,
    } = useTeamList();

    const conflicts = useTags('/api/conflict', "team");

    const { data: state } = useContestState();

    const fields = useFields({
        defaultFields: [
            "number",
            "approval_status",
            "email",
            "chat_comment_buttons",
            "doc1",
            "doc2",
            "penalty_points",
            "university",
            "department",
            "city",
            "conflicts",
            "participants_cnt",
            "coaches_cnt",
            "is_filled",
            "is_blocked",
            "created_datetime",
        ],
        extraSettings: {
            email: { cellStyle: { whiteSpace: 'nowrap'}},
            university: { cellStyle: { minWidth: 400, maxWidth: 400 }},
            department: { cellStyle: { minWidth: 300, maxWidth: 400 }},
            city: { cellStyle: { whiteSpace: 'nowrap'}},
            doc1: { label: "Жалоба"},
            doc2: { label: "Отзыв"},
            penalty_points: { width: 50 },
            created_datetime: { utcToLocal: true },
            pdf_application_upload_time: { utcToLocal: true },
            chat_comment_buttons: { label: " " },
            conflicts: { label: 'Конфликты' },
            finals_participants: { label: "Участники раундов и хронометраж" },
            finals_participants_general: { label: "Участники устного этапа" },
        },
        blacklist: [
            "pdf_application_uploaded",
            "pdf_application_upload_time",
            "password",
            "passwordRepeat",
            "documents",
            "members",
            "coaches",
            "university_bound",
        ],
        schema,
        outOfSchemaFields: [
            "chat_comment_buttons",
            "doc1",
            "doc2",
            "conflicts",
            "finals_participants_general",
        ],
        storageKey: "__femida_teamlist_columns",
    });

    return (
        <Form
            formPaperProps={{ style: { display: 'flex', flexFlow: 'column', overflow: 'hidden', maxHeight: '100%' }}}
            formBodyProps={{ style: { overflow: 'hidden' }}}
            headerItems={
                <>
                <TabsHeader
                    state={{
                        current: view,
                        tabs: TeamViews.map(([x,y]) => ([y,x])),
                        setCurrent: setView,
                        forTab: () => ({} as TabsStateForTab),
                        keys: TeamViews.map(([x,_]) => x),
                        labels: TeamViews.map(([_,x]) => x),
                        onChange: (_,k) => setView(k),
                    }}
                    noMargin
                    scrollable
                />
                </>
            }>
            <ActionRow firstItemNoMargin itemMarginTop="0">
                <SearchField
                    filter={filter}
                    setFilter={setFilter}
                    doSearch={() => { }}
                    buttonLabel=" "
                    autoFocus
                    />
                <OccupyFreeSpace />
                {isLoading && <LoadingIndicator sizeVariant="s" />}
                {state.documents_upload_available &&
                    <Button color="primary" onClick={assignDocLatePenalties}>
                        рассчитать штрафы
                    </Button>}
                {state.documents_upload_available &&
                    <Button color="primary" onClick={exportDocuments}>
                        экспорт документов
                    </Button>}
                <Tooltip text="Выгрузка команд раздельная">
                    <IconButton size="small" onClick={exportTeams}>
                        <AssignmentReturnedOutlined />
                    </IconButton>
                </Tooltip>
                <Tooltip text="Выгрузка команд вкладками">
                    <IconButton size="small" onClick={exportTeamsStats}>
                        <AssignmentReturnedOutlined />
                    </IconButton>
                </Tooltip>
                <FieldSettingsPopupButton fieldsSettings={fields} />
            </ActionRow>

            <ActionRow firstItemNoMargin>
                <Typography variant="caption">Для просмотра деталей, кликните по номеру или email'у команды. Номер присваивается при утверждении команды.</Typography>
                <OccupyFreeSpace />
                <Typography variant="caption">Команд: {data.length}</Typography>
            </ActionRow>
            <TableForFields
                schema={schema}
                data={data}
                fields={fields.activeFields}
                sorting={sorting}
                fieldElement={f => {
                    switch(f) {
                        case "number":
                        case "approval_status":
                        case "email":
                            return (row, _, orig) => <PseudoLink onClick={() =>  teamReview.open(row._id)}>{orig}</PseudoLink>
                        case "pdf_application_uploaded":
                            return row => row.pdf_application_uploaded ?
                                <PseudoLink onClick={() => downloadFile(`/api/admin/team/pdf/${row._id}`, `team_${row.email}.pdf`)}>скачать</PseudoLink>
                                : "-";
                        case "chat_comment_buttons":
                            return row => <TableRowButtons>
                                <Tooltip text="Комментарии">
                                    {comments.buttonForRecord(row._id) as ReactElement<any, any>}
                                </Tooltip>
                                <Tooltip text="Чат" withWrapper>
                                    <GoToChatButton to={`/admin/chat-org/${row._id}`} />
                                </Tooltip>
                            </TableRowButtons>;
                        case "doc1":
                            return row => <DocLink
                                document={row.documents?.doc1}
                                downloadDocument={id => teamReview.downloadDocument(id, getTeamDocumentName("doc1", row))}
                                openDetails={() => teamReview.open(row._id)}
                                />
                        case "doc2":
                            return row => <DocLink
                                document={row.documents?.doc2}
                                downloadDocument={id => teamReview.downloadDocument(id, getTeamDocumentName("doc2", row))}
                                openDetails={() => teamReview.open(row._id)}
                                />
                        case "conflicts":
                            return row => conflicts.displayForRecord(row._id) as ReactElement<any, any>;
                        case "finals_participants":
                            return row => row.finals_participants && Object.keys(row.finals_participants).length > 0 ? `${Object.keys(row.finals_participants).length}р` : "-"
                        case "finals_participants_general":
                            return row => <>{(row.members || []).filter(m => m.participates_finals).length}</>
                        default:
                            return null;
                    }
                }}
                />

            <TeamReviewPopup data={teamReview} conflicts={conflicts} />
            {comments.popup}
            {conflicts.popup}
        </Form>
    );
}
