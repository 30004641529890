import React from 'react';
import styled from '@emotion/styled';
import { Button, Typography, useTheme, useMediaQuery, Checkbox } from '@material-ui/core';
import { PageWithComponents, PageDisplay, usePage } from '../../toolympus/components/Pages';
import { createColumns, FormGrid } from '../../toolympus/components/primitives/Forms';
import { FieldDefinition, FormControlsForFields } from '../../toolympus/components/schemed';
import { PageWrapper, Divider, SectionTitle, Blank } from '../Common.styles';
import { useTeamProfile, TeamProfileData } from './useTeamProfile';
import { Status } from '../../toolympus/components/StatusAction';
import { Team } from '../Public/useTeamRegistrationStageOne';
import { Check } from '@material-ui/icons';

const PersonBlockWrapper = styled.div`
    position: relative;

    width: 100%;

    & > :first-child {
        width: 24px;
        position: absolute;
        top: 28px;
        left: -24px;
    }
`;

const FileButtonsBlock = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1em 0;
`;

interface TeamStatusProps {
    team: Team;
}

export const teamStatus = ({ approval_status, is_filled }: Team): string => {
    if(approval_status) {
        return approval_status;
    } else if(is_filled) {
        return "review";
    }

    return "unfilled";
}

const TeamStatus = ({ team }: TeamStatusProps) => {
    const { palette } = useTheme();
    
    return <Status
                chipStyles={{ textTransform: 'uppercase' }}
                status={teamStatus(team)}
                labels={{
                    unfilled: "заполните заявку",
                    filled: "загрузите pdf",
                    review: "заявка рассматривается",
                    approved: "заявка принята",
                    needs_changes: "необходимы правки",
                    rejected: "заявка отклонена",
                }}
                hints={{
                    unfilled: { color: palette.error.main, },
                    filled: { color: palette.warning.main, },
                    review: { },
                    approved: { color: palette.success.main, },
                    needs_changes: { color: palette.warning.main, },
                    rejected: { color: palette.error.main, },
                }}
                />

}

interface InstructionsBlockProps {
    page: PageWithComponents;
    section: string;
}

const useIsMobile = (): boolean => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down('xs'));
}

const InstructionsBlock = ({ page, section }: InstructionsBlockProps) => {
    const pageToRender = {
        ...page,
        components: page.components
            .filter(c => c.subtype_text === section)
            .map(c => ({ ...c, subtype_text: "markdown" })),
    };

    return <PageDisplay data={{ page: pageToRender, isLoading: false }} />
}

interface ExplanationBlockProps {
    data: TeamProfileData;
    instructions: PageWithComponents;
}

const ExplanationAndUploadBlock = (props: ExplanationBlockProps) => {
    const { data, downloadPdf, uploadPdf } = props.data;
    const isMobile = useIsMobile();

    return (<>
            <FormGrid columns={isMobile ? "1fr" : "2fr 1fr"}>
                <div>
                    <InstructionsBlock page={props.instructions} section="general" />
                </div>
                <FileButtonsBlock>
                    <TeamStatus team={data} />
                    {/* no pdf application for now */}
                    {false && <>
                        <Button color="primary" disabled={!data.is_filled} onClick={downloadPdf}>
                            {data.is_filled ? "выгрузить заявку в pdf" : "заполните данные команды, чтобы выгрузить заявку"}
                        </Button>
                        <Button color="primary" onClick={uploadPdf}>
                            загрузить скан заявки
                        </Button>
                    </>}
                </FileButtonsBlock>
            </FormGrid>
            <Divider key="explanation-block-divider" />
        </>
    );
}



const FinalsMembersBlock = (props: { data: TeamProfileData }) => {
    const { membersForFinals, updateMemberForFinal, isMembersForFinalsSet, isMembersForFinalsChanged, saveMembersForFinals, } = props.data;
    const isMobile = useIsMobile();

    return (<>
            <Divider key="finals-participants-block-divider" />
            <FormGrid columns={isMobile ? "1fr" : "2fr 1fr"}>
                <div>
                    <h3 key="caption">
                        Устные раунды
                    </h3>
                    <Typography key="hint">
                        Пожалуйста, укажите, кто из членов команды примет участие в устных раундах
                    </Typography>

                    <FormGrid columns="max-content max-content" style={{ alignItems: "center"}}>
                        {membersForFinals.map((m,i) => (<>
                            <Typography>{m.lastname} {m.firstname} {m.middlename}</Typography>
                            <Checkbox
                                checked={m.participates_finals || false}
                                onChange={e => updateMemberForFinal(i, e.target.checked)}
                            />
                        </>))}
                    </FormGrid>
                </div>
                <Button
                    style={{ placeSelf: 'center center' }}
                    color="primary"
                    variant={isMembersForFinalsSet && !isMembersForFinalsChanged ? 'contained' : 'outlined'}
                    endIcon={isMembersForFinalsSet && !isMembersForFinalsChanged ? <Check /> : undefined}
                    onClick={saveMembersForFinals}>
                    {isMembersForFinalsSet && !isMembersForFinalsChanged ? "сохранено" : "сохранить"}
                </Button>
            </FormGrid>
        </>
    );
}

const ExplanationWrapper = styled.div`
    font-style: italic;
    background: rgba(43,79,112, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
    width: auto;
    display: inline-block;
`;

const FixingFixesBlock = (props: ExplanationBlockProps) => {
    const { data, isLoading, markFixesDone } = props.data;
    const isMobile = useIsMobile();

    return (data.approval_status === "needs_changes" ? <>
            <FormGrid columns={isMobile ? "1fr" : "2fr 1fr"}>
                <div>
                    <InstructionsBlock page={props.instructions} section="fixes" />
                    
                    <ExplanationWrapper>
                        <Typography>{data.reject_explanation}</Typography>
                    </ExplanationWrapper>
                </div>
                <FileButtonsBlock>
                    <Button color="primary" variant="contained" disabled={isLoading} onClick={markFixesDone}>
                        отправить на рассмотрение
                    </Button>
                </FileButtonsBlock>
            </FormGrid>
            <Divider key="fixing-fixes-block-divider" />
        </>
        : <></>
    );
}


interface Props {
    
}

export const TeamProfilePage = (props: Props) => {
    const teamProfileData = useTeamProfile();
    const {
        data,
        update,
        updateMember,
        updateCoach,
        addMember,
        save,
        hasChanges,
        isLoading,
        teamSchema,
        memberSchema,
        coachSchema,
        errors,
    } = teamProfileData;

    const instructions = usePage('/api/page', 'team-profile-instructions');
    const isMobile = useIsMobile();

    const teamControls = (fields: FieldDefinition[], disabled?: boolean, withArea?: boolean) => (
        <FormControlsForFields
            data={data}
            schema={teamSchema}
            fields={disabled ? fields.map(([f, e]) => [f, { ...e, readOnly: true }]) : fields}
            onChange={disabled ? () => {} : (o,c) => update(c)}
            errors={errors}
            withArea={withArea}
            />
    )

    const memberBlock = (idx: number) => (
        <PersonBlockWrapper key={`member${idx}`}>
            <span>{idx+1}.</span>
            <FormGrid
                noMargin={idx === 0}
                columns={createColumns("1fr", isMobile ? 1 : 3)}
                areas={isMobile ? [
                    "lastname",
                    "firstname",
                    "middlename",
                    "email",
                    "phone",
                    "study_year",
                    "is_contact",
                    "participates_finals",
                    ]
                    : [
                    "lastname firstname middlename",
                    "email phone study_year",
                    "is_contact is_contact participates_finals",
                ]}>
            <FormControlsForFields
                data={data.members[idx]}
                schema={memberSchema}
                fields={[
                    ["lastname", { readOnly: data.is_blocked }],
                    ["firstname", { readOnly: data.is_blocked }],
                    ["middlename", { readOnly: data.is_blocked }],
                    ["email", { readOnly: data.is_blocked }],
                    ["phone", { readOnly: data.is_blocked }],
                    ["study_year", { readOnly: data.is_blocked }],
                    ["is_contact", { readOnly: data.is_blocked }],
                    ...(!!data.finals_stage ? [["participates_finals", { readOnly: true }] as FieldDefinition] : []),
                ]}
                onChange={(o,c) => !data.is_blocked && updateMember(idx, c)}
                errors={errors?.getInnerErrors(["members", idx])}
                withArea
                />
            </FormGrid>
        </PersonBlockWrapper>
    );

    const coachBlock = (idx: number) => (
        <PersonBlockWrapper key={`coach${idx}`}>
            <span>{idx+1}.</span>
            <FormGrid
                noMargin={idx === 0}
                columns={createColumns("1fr", isMobile ? 1 : 3)}
                areas={isMobile ? [
                    "lastname",
                    "firstname",
                    "middlename",
                    "email",
                    "phone",
                    "position",
                    "is_contact",
                    ] : [
                        "lastname firstname middlename",
                        "email phone position",
                        "is_contact is_contact is_contact",
                ]}>
            <FormControlsForFields
                data={data.coaches[idx]}
                schema={coachSchema}
                fields={[
                    ["lastname"],
                    ["firstname"],
                    ["middlename"],
                    ["email"],
                    ["phone"],
                    ["position"],
                    ["is_contact"],
                ]}
                onChange={(o,c) => updateCoach(idx, c)}
                errors={errors?.getInnerErrors(["coaches", idx])}
                withArea
                />
            </FormGrid>
        </PersonBlockWrapper>
    );

    return (
        <PageWrapper>
            <FormGrid
                columns={createColumns("1fr", isMobile? 1 : 3)}>
                {teamControls([
                    ["email"],
                    ["number"],
                ], true)}
            </FormGrid>
            
            {teamProfileData.isFinalsMembersSelectionAvailable && <FinalsMembersBlock data={teamProfileData} />}
            <Divider />
            <ExplanationAndUploadBlock data={teamProfileData} instructions={instructions.page} />
            <FixingFixesBlock data={teamProfileData} instructions={instructions.page} />

            <FormGrid
                columns={createColumns("1fr", isMobile? 1 : 3)}
                areas={isMobile ? [
                    "university",
                    "department",
                    "city",
                ] : [
                "university university empty",
                "department city empty"
            ]}>
                {teamControls([
                    ["university"],
                    ["department"],
                    ["city"]
                ], data.is_blocked, true)}
            </FormGrid>

            <SectionTitle title="Участники команды" subtitle="(от 4 до 6 человек)" />
            {data.members.map((_,idx) => memberBlock(idx))}
            <FormGrid columns={createColumns("1fr", isMobile? 1 : 3)}>
                <div />
                <div />
                {data.members.length < 6 && <Button color="primary" onClick={addMember}>добавить участника</Button>}
            </FormGrid>
            <Divider />

            <SectionTitle title="Тренеры" subtitle="(до 2 человек)" />
            {data.coaches.map((_,idx) => coachBlock(idx))}
            <Divider />

            <FormGrid columns="1fr">
                <Blank />
                {teamControls([
                    ["finale_consent"],
                    ["personal_data_consent"],
                    ["photos_consent"],
                ], data.is_blocked)}
                <Blank />
            </FormGrid>

            <FormGrid columns={isMobile ? "1fr" : "2fr 1fr"}>
                {data.is_blocked && <Typography>Изменения заявки заблокированы</Typography>}
                {!data.is_blocked && <div/>}
                <Button variant="contained" color="primary" disabled={!hasChanges || isLoading || (data.is_blocked && !hasChanges)} onClick={save}>сохранить</Button>
            </FormGrid>

            <Divider key="after-form" />
            <FixingFixesBlock data={teamProfileData} instructions={instructions.page} />
            {data.is_filled && !data.approval_status &&
                <InstructionsBlock page={instructions.page} section="await-approval" />}
        </PageWrapper>
    );
}
