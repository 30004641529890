import React from 'react';
import { ComponentRenderers, PageContainer } from '../../../Pages';
import { usePlayerRounds } from './usePlayerRounds';
import { PartyRoundsList, PartyRoundsListProps } from './PartyRoundsList';


interface ContainerProps extends Omit<PartyRoundsListProps, 'data'> {
    apiPath: string;
    pageApiPath: string;
    pageSlug: string;
    extraCustomComponents?: ComponentRenderers;
}

/**
 * Grid rounds list page for players.
 * The rounds will be plugged in place of the page component of custom type `rounds`
 * @param props 
 * @returns 
 */
export const PlayerRoundsListContainer = (props: ContainerProps) => {
    const data = usePlayerRounds(props.apiPath);
    const rounds = <PartyRoundsList {...props} data={data} />

    return (
        <PageContainer
            apiPath={props.pageApiPath}
            slug={props.pageSlug}
            customComponents={{
                ...(props.extraCustomComponents || {}),
                rounds: () => rounds,
            }}
        />
    );
}
