import { useState } from "react";
import { useSnackbar } from 'notistack';
import { apiFetch, downloadFile, FetchTypes } from "../../../toolympus/api/core";
import { useTextFilter, WithTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { LoadedData, useLoadedData } from "../../../toolympus/hooks/useLoadedData"
import { Team } from "../../Public/useTeamRegistrationStageOne"
import { DocumentAccess, DocumentAccessPenalty, DocumentNames } from "../../Team/useTeamDocuments";
import { EditItemProps, NewItemProps, OpenItemProps, useEditItem, useNewItem, useOpenItem } from "../../../toolympus/api/useNewItem";
import { JudgeAssignmentData, useJudgeAssignment } from "./useJudgeAssignment";
import { useActionWithConfirmation } from "../../../toolympus/api/useAction";

export interface DocumentScoring {
    _id: string;
    judge_id: string;
    judge_name: string;
    score_total: number;
}

export interface Document extends DocumentAccess {
    scoring?: DocumentScoring[];
    score_avg?: number;
    info: {
        comment?: string;
        technical_penalty?: number;
        late_penalty?: number;
    }
}

export interface TeamAssess extends Team {
    documents: { doc1: Document, doc2: Document };
    score_result?: number;
}

export interface DocumentAssessmentListData extends LoadedData<TeamAssess[]>, WithTextFilter {
    assignJudges: () => void;
    assignThirdJudges: () => void;
    assignDocLatePenalties: () => void;
    notifyJudges: () => void;
    remindJudges: () => void;
    cancelAssignment: () => void;
    exportAssignments: () => void;
    download: (team: TeamAssess, d: Document) => void;
    penaltyEdit: EditItemProps<DocumentAccessPenalty>;
    acceptFinals: NewItemProps<{ count: number}, any>;
    judgeAssignment: JudgeAssignmentData & OpenItemProps<DocumentAccess>;

}

const apiMainPath = "/api/assignment/documents/admin";

export const useDocumentsAssessmentList = (): DocumentAssessmentListData => {
    const data = useLoadedData<TeamAssess[]>(apiMainPath, []);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const snackbar = useSnackbar();

    const getDocJudgeNames = (d: Document | undefined) => `${(d?.scoring || []).map(x => x?.judge_name || "").join(' ')}`
    const filter = useTextFilter<TeamAssess>(t => `${t.number} ${getDocJudgeNames(t?.documents?.doc1)} ${getDocJudgeNames(t?.documents?.doc2)}`);

    const penaltyEdit = useEditItem<DocumentAccessPenalty>(`/api/assignment/documents/admin/penalty`, '_id');
    const judgeAssignmentState = useOpenItem<DocumentAccess>();
    const judgeAssignment = useJudgeAssignment(() => { data.reload(); judgeAssignmentState.cancel(); });

    const acceptFinals = useNewItem<{ count: number }, any>('/api/assignment/documents/admin/accept-finals', { count: 20 });

    const assignJudges = () => {
        setIsLoading(true);
        apiFetch<TeamAssess[]>(apiMainPath, FetchTypes.POST)
            .then(result => {
                data.setData(result);
                setIsLoading(false);
            })
            .catch(e => {
                setIsLoading(false);
                throw e;
            });
    }

    const assignThirdJudges = () => {
        setIsLoading(true);
        apiFetch<TeamAssess[]>(`${apiMainPath}/third`, FetchTypes.POST)
            .then(() => {
                data.reload();
                setIsLoading(false);
            })
            .catch(e => {
                setIsLoading(false);
                throw e;
            });
    }

    const cancelAssignment = () => {
        apiFetch<TeamAssess[]>(apiMainPath, FetchTypes.DELETE).then(() => data.reload());
    }

    
    const assignDocLatePenalties = () => {
        apiFetch("/api/admin/document/assign-late-penalty", FetchTypes.POST)
            .then(() => data.reload());
    }

    const sendJudgesNotification = (template: string) => {
        apiFetch(`${apiMainPath}/notification/${template}`, FetchTypes.POST)
            .then(() => { snackbar.enqueueSnackbar("Уведомления отправляются", { variant: "success" }); });
    }

    const notifyJudges = () => sendJudgesNotification("assess-documents-first");
    const remindJudges = () => sendJudgesNotification("assess-documents-reminder");

    const download = (team: TeamAssess, d: Document) => downloadFile(
        `/api/admin/document/${d.document_id}`,
        `Команда_${team.number}_${DocumentNames[d.document_kind as 'doc1' | 'doc2']}.docx`);

    const exportAssignments = () => downloadFile(
        `/api/assignment/documents/admin/export`,
        "Назначения.xlsx"
    )

    return {
        ...data,
        data: filter.filterData(data.data),
        ...filter,
        isLoading: isLoading || data.isLoading,
        assignJudges,
        assignThirdJudges,
        cancelAssignment,
        assignDocLatePenalties,

        notifyJudges,
        remindJudges,
        download,
        exportAssignments,
        acceptFinals,

        penaltyEdit: {
            ...penaltyEdit,
            save: () => penaltyEdit.save().then(x => { data.reload(); penaltyEdit.cancel(); return x; }),
        },
        judgeAssignment: {
            ...judgeAssignment,
            ...judgeAssignmentState,
        }
    }
}


export const useAssignmentActions = (assignmentData: DocumentAssessmentListData) => {
    const assignJudges = useActionWithConfirmation(() => { assignmentData.assignJudges(); return Promise.resolve({}); }, {
        title: "Назначить судей",
        confirmationHint: "По 2 судьи будут добавлены в каждый документ. Если потребуется отменить, удалять придется по одному. Продолжить?",
    });

    const assignThirdJudges = useActionWithConfirmation(() => { assignmentData.assignThirdJudges(); return Promise.resolve({}); }, {
        title: "Назначить третьих судей",
        confirmationHint: "В документы, где назначены двое судей и расхождение между оценками превышает 20 баллов, будет назначен третий судья. Продолжить?",
    });

    const cancelAssignment = useActionWithConfirmation(() => { assignmentData.cancelAssignment(); return Promise.resolve({}); }, {
        title: "Отменить назначение",
        confirmationHint: "Все назначения судей будут удалены вместе с оценками. Отменить операцию будет невозможно. Продолжить?",
    });

    return [
        {
            label: "Назначить штрафы за опоздание",
            action: assignmentData.assignDocLatePenalties,
        },
        {
            label: assignJudges.title,
            action: assignJudges.run,
        },
        {
            label: assignThirdJudges.title,
            action: assignThirdJudges.run,
        },
        {
            label: cancelAssignment.title,
            action: cancelAssignment.run,
        },
        {
            label: "Отобрать команды в итоговые раунды",
            action: assignmentData.acceptFinals.startEditing,
        },
        {
            label: "Выгрузить назначения",
            action: assignmentData.exportAssignments,
        },
        {
            label: "Отправить уведомление судьям",
            action: assignmentData.notifyJudges,
        },
        {
            label: "Отправить напоминание судьям",
            action: assignmentData.remindJudges,
        },
    ];
}