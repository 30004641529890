import React, { ReactNode, useContext } from 'react';
import { Tag } from './useTagManagement';
import { useAllTags } from './useTags';

export interface IAvailableTags {
    tags: Tag[];
    tagsDict: Record<number, Tag>;
    isLoading: boolean;
    reload: () => void;
    fromKnownIDs: (ids?: number[]) => Tag[];
}

const AvailableTagsContext = React.createContext<IAvailableTags>({
    tags: [],
    tagsDict: {},
    isLoading: false,
    reload: () => { },
    fromKnownIDs: () => [],
});

interface Props {
    apiPath?: string;
    children?: ReactNode;
}

export const AvailableTagsProvider = (props: Props) => {
    const { data: tags, isLoading, reload } = useAllTags(props.apiPath || "", !props.apiPath);
    const tagsDict = tags.reduce((r,t) => ({ ...r, [t._id]: t }), {}) as Record<number, Tag>;
    return (
        <AvailableTagsContext.Provider value={{
            tags,
            tagsDict,
            fromKnownIDs: ids => (ids || []).map(t => tagsDict[t]).filter(t => !!t),
            isLoading,
            reload,
        }}>
            {props.children}
        </AvailableTagsContext.Provider>
    );
}

export const useAvailableTags = () => useContext(AvailableTagsContext);
