import { useState, useEffect } from "react";
import { apiFetch, FetchTypes } from "../../api/core";
import { useQuery } from "../useQueryParamsState";

enum ConfirmationResult {
    Success,
    Failure,
}

export interface EmailConfirmation {
    isLoading: boolean;
    isSuccess: boolean;
    isFailure: boolean;
}

export interface EmailConfirmationConfig {
    onSuccess?: () => void;
    onSuccessTimeout?: number;
}

export const useEmailConfirmation = (apiPath: string, config?: EmailConfirmationConfig): EmailConfirmation => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [result, setResult] = useState<ConfirmationResult | null>(null);

    const code = useQuery().get("code");

    useEffect(() => {
        if(code && code.length) {
            setIsLoading(true);
            apiFetch<{}>(apiPath, FetchTypes.POST, { code })
                .then(() => {
                    setIsLoading(false);
                    setResult(ConfirmationResult.Success);
                    
                    setTimeout(
                        () => (config?.onSuccess || (() => {}))(),
                        config?.onSuccessTimeout || 0);
                })
                .catch(e => {
                    setIsLoading(false);
                    setResult(ConfirmationResult.Failure);
                    throw e;
                })
        }
    }, [apiPath, code, config]);

    return {
        isLoading,
        isSuccess: result === ConfirmationResult.Success,
        isFailure: result === ConfirmationResult.Failure,
    }

}