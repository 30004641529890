import React, { ReactNode } from 'react';
import styled from '@emotion/styled/macro';
import { ExitToApp, Menu } from '@material-ui/icons';
import { AppContent as AC } from './toolympus/components/frame/new/';
import { Typography, IconButton, useMediaQuery, useTheme, withTheme } from '@material-ui/core';
import { FormHeader } from './toolympus/components/primitives/Forms';
import { OccupyFreeSpace } from './toolympus/components/primitives/ActionRow';

const AppContentW = styled(AC)`
    grid-template-rows: 59px 1fr;
    flex-grow: 0;
    max-width: 100%;
`;

const AppContentInnerW = styled.div`
    max-width: 100%;
    width: 100%;
    overflow: auto;
    z-index: 100;

    & ${FormHeader}  > h4:first-child {
        display: none;
    }
`;

const RibbonContainer = withTheme(styled.div`
    max-width: 100%;
    width: 100%;
    height: 100%;
    padding: 0 30px;
    overflow: hidden;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px -10px 10px 10px rgba(43, 79, 112, 0.25);
    z-index: 300;

    & > h2 {
        font-weight: 300;
        font-size: 24px;
        line-height: 26px;

        letter-spacing: 0.75px;
        text-transform: uppercase;
        margin: 0;
    }

    ${props => props.theme.breakpoints.down("md")} {
        padding: 0 10px;

        & > :first-child {
            margin-right: 1rem;
        }

        & > h2 {
            font-size: 16px;
            line-height: 20px;
        }
    }
`);

interface Props {
    logout: () => void;
    title?: string;
    children: ReactNode;
    toggleMenu: () => void;
}

export const AppContent = (props: Props) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    
    return (
        <AppContentW>
            <RibbonContainer>
                {!isDesktop && <IconButton size="small" onClick={props.toggleMenu}>
                    <Menu />
                </IconButton>}
                <Typography variant="h2">{props.title}</Typography>
                <OccupyFreeSpace />
                <IconButton size="small" onClick={props.logout}>
                    <ExitToApp />
                </IconButton>
            </RibbonContainer>
            <AppContentInnerW>
                {props.children}
            </AppContentInnerW>
        </AppContentW>
    );
}