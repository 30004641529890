import React from 'react';
import styled from '@emotion/styled';
import { Table, TableCell, TableContainer, TableRow, withTheme } from '@material-ui/core';
import { useCopyText } from '../../toolympus/components/primitives';
import { Judge } from '../Public/useJudgeRegistration';

interface Props {
    judges: Judge[];
}

const formatPhoneNumber = (phone?: string) => {
    const clean = (phone || "").replace(/[^0-9]/g, "") + "               ";
    return `${clean[0]} ${clean.substring(1,4)} ${clean.substring(4,7)} ${clean.substring(7)}`
}

const StyledRow = withTheme(styled(TableRow)`
    &:nth-of-type(even) {
        background-color: ${props => props.theme.palette.primary.main}10;
    }
`);

export const JudgesContactList = (props: Props) => {
    const copyText = useCopyText();
    const judges = props.judges.map(j => ({
        ...j,
        fullname: `${j.lastname} ${j.firstname} ${j.middlename}`,
        phone: formatPhoneNumber(j.phone),
    }))
    .sort((a,b) => a.fullname < b.fullname ? 1 : -1);

    return (
        <TableContainer>
            <Table>
                {judges.map(j => (
                    <StyledRow key={j._id}>
                        <TableCell>{j.fullname}</TableCell>
                        <TableCell onClick={() => copyText(j.email)}>{j.email}</TableCell>
                        <TableCell onClick={() => copyText(j.phone)}>{j.phone}</TableCell>
                    </StyledRow>
                ))}
            </Table>
        </TableContainer>
    );
}
