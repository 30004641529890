import { GridSettings } from "../../../toolympus/components/Contests/Grid";
import { AdminRoundPopupTeamDetails } from "./AdminRoundPopupTeamDetails";
import { RoundPlayerInfo } from "./RoundPlayerInfo";

export const FemidaGridSettings: Partial<GridSettings> = {
    qr_codes_enabled: false,
    arbiter_links_enabled: false,
    judges_per_stage_round: { po_final: 7 },
    conference_kind: "zoom",
    get_arbiter_class: (_,i) => i === "1" ? "highlight" : "",
    round_player_info_component: RoundPlayerInfo,
    admin_round_popup_player_info_component: AdminRoundPopupTeamDetails,
    player_extra_columns: [
        ["Оценка за документы", p => p.info?.documents_score]
    ],
    get_results_extra_columns: stage => {
        if(stage === "rating") {
            return {
                columns: [
                    ["documents_score", { label: "Оценка за документы"}],
                    ["rounds_score", { label: "Оценка за раунды"}],
                    ["university", { label: "Университет"}],
                ],
                getValue: (f, p) => {
                    if(f === "rounds_score") {
                        return ((p.score - ((p.info || {})["documents_score"] || 0)) || 0).toFixed(2);
                    } else if (f === "documents_score") {
                        return ((p.info || {})[f] || 0).toFixed(2);
                    } else if (f === "university") {
                        return (p.info || {})[f];
                    }
                },
            }
        } else {
            return {
                columns: [
                    ["university", { label: "Университет"}],
                ],
                getValue: (f, p) => {
                    if(f === "university") {
                        return (p.info || {})[f];
                    }
                },
            }
        }
    }
}