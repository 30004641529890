import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ListItem, Typography, withTheme } from '@material-ui/core';
import { ChatList } from '../../toolympus/components/Chats/ChatList';
import { PageWrapper } from '../Common.styles';
import { Chat, Participant } from '../../toolympus/components/Chats/types';

const isParticipantTeam = (p: Participant) => p.name.startsWith("Команда");
const isParticipantSecretary = (p: Participant) => p.name.includes("(секретарь)");

const filters: Record<string, (c: Chat) => boolean> = {
    all: c => true,
    teams: c => !!c.participants.find(isParticipantTeam),
    judges: c => !c.participants.find(isParticipantTeam) && !c.participants.find(isParticipantSecretary),
    secretaries: c => !!c.participants.find(isParticipantSecretary),
}

const useChatsTabs = () => {
    const [activeTab, setActiveTab] = useState<string>("all");

    return {
        activeTab,
        setActiveTab,
        filter: filters[activeTab] || filters["all"],
        tabs: [
            ["all", "Все"],
            ["teams", "Команды"],
            ["judges", "Судьи"],
            ["secretaries", "Секретари"],
        ]
    }
}

export const ChatListOrgPage = () => {
    const tabs = useChatsTabs();
    return (
        <PageWrapperX>
            <ChatList
                cropLastMessageInList={140}
                chatListFilter={tabs.filter}
                chatListHeader={<ListItem>
                    <TabsWrapper>
                        {tabs.tabs.map(([tab, label]) => (
                            <TabHeader
                                key={tab}
                                isActive={tab === tabs.activeTab}
                                onClick={() => tabs.setActiveTab(tab)}
                                >
                                    {label}
                            </TabHeader>
                        ))}
                    </TabsWrapper>
                </ListItem>}
                allowMarkdownEditor
                allowEditMessage
                />
        </PageWrapperX>
    );
}

const PageWrapperX = withTheme(styled(PageWrapper)`
    padding: 0;
    display: flex;
    overflow-y: hidden;
    height: 100%;

    & > :first-child {
        column-gap: 12px;
        min-height: calc(100vh - 59px);
        max-height: calc(100vh - 59px);

        & > ul:first-child {
            box-shadow: 2px 0px 5px 0px rgba(43, 79, 112, 0.25);
            
            .MuiListItem-root {
                border-bottom: 1px solid ${props => props.theme.palette.grey[200]};
            }
        }
    }
`);

const TabsWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 0.75rem;
`;

const TabHeader = withTheme(styled(Typography)<{ isActive?: boolean }>`
    cursor: pointer;
    font-weight: ${props => props.isActive ? 500 : "normal"};
    color: ${props => props.isActive ? props.theme.palette.primary.main : props.theme.palette.text.secondary};
`);

TabHeader.defaultProps = { role: "button" };