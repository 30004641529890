import { IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useState } from "react";
import { OccupyFreeSpace } from "../../primitives/ActionRow";
import { PseudoLink } from "../../primitives/Common.styles";
import { Form } from "../../primitives/Forms";
import { LoadingIndicator } from "../../primitives/LoadingIndicator";
import { SearchField } from "../../primitives/SearchField";
import { TableForFields } from "../../schemed";
import { EmailItem } from "../types";
import { useEmails } from "../useEmails";
import { Popup } from "./Popup";

const entryEmail = (): EmailItem => ({
    description: "",
    title: "",
    active: false,
    code: "",
    body: "",
    subject: ""
});

const noSelect = { email: null, isNew: false };

export const EmailsPage = ({apiPrefix = '/email'}: {apiPrefix?: string}) => {
    const emailsApi = useEmails(apiPrefix);
    const { schema, loading, emails } = emailsApi;

    const [selected, setSelected] = useState<{ email: (EmailItem | null), isNew: boolean }>(noSelect);

    return <Form
            headerItems={<>
                <IconButton color='primary' onClick={() => setSelected({ email: entryEmail(), isNew: true })}>
                    <Add />
                </IconButton>
                {loading && <LoadingIndicator />}
                <OccupyFreeSpace />
                <SearchField
                    filter={emailsApi.filter.filter}
                    setFilter={emailsApi.filter.setFilter}
                    doSearch={() => {}}
                    noButton
                    autoFocus
                    />
            </>}
            >

        <TableForFields
            sorting={emailsApi.sorting}
            fields={[
                ["code"],
                ["title"],
                ["description"],
                ["active", {width: 100}],
            ]}
            schema={schema}
            data={emails}
            fieldElement={(field) => 
                field === "code"? 
                    ((row: EmailItem) => <PseudoLink onClick={() => setSelected({ email: row, isNew: false })}>{row.code}</PseudoLink> )
                    :
                     null
            }
        />

        {selected.email && 
            <Popup 
                data={{
                    isNew: selected.isNew,
                    email: selected.email,
                    ...emailsApi,
                    onClose: () => setSelected(noSelect),
                    onChange: (row: EmailItem) => setSelected({ email: row, isNew: selected.isNew }),
                }} 
            />}

    </Form>
}