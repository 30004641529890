import { useState } from "react";
import { apiFetch, FetchTypes } from "../../../toolympus/api/core";
import { TagManagement, useTagManagement } from "../../../toolympus/components/Tags";

export interface ConflictsControl extends TagManagement {
    isLoading: boolean;
    prefill: () => void;
}

export const useConflictsControl = (): ConflictsControl => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const mainData = useTagManagement("/api/conflict");

    const prefill = () => {
        setIsLoading(true);
        apiFetch(`/api/conflict/prefill`, FetchTypes.POST)
            .then(() => { setIsLoading(false); })
            .catch(e => { setIsLoading(false); throw e; })
    }

    return {
        ...mainData,
        isLoading: isLoading || mainData.isLoading,
        prefill,
    }
}