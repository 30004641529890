import { Button } from '@material-ui/core';
import React, { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ActionRow, OccupyFreeSpace } from '../../components/primitives/ActionRow';
import { SimpleDialog } from '../../components/primitives/Dialogs';
import { LoadingIndicator } from '../../components/primitives/LoadingIndicator';
import { FormControl, TableForFields } from '../../components/schemed';
import { FieldType } from '../../hooks/useSchema';
import { SnapshotsData } from './useSnapshots';

interface Props {
    data: SnapshotsData;
    extraActions?: ReactNode;
}

export const SnapshotsForm = (props: Props) => {
    const {
        isLoading,
        data,
        create,
        switchToSnapshot,
    } = props.data;

    const [newSnapshotName, setNewSnapshotName] = useState<string | null>(null);

    const schema = {
        name: { type: FieldType.text, label_id: "system.snapshots.labels.name", },
        created_datetime: { type: FieldType.datetime, label_id: "system.snapshots.labels.created_datetime", },
    };

    return (<>
        <ActionRow>
            <Button color="primary" onClick={() => setNewSnapshotName("")}><FormattedMessage id="system.snapshots.create_snapshot" /></Button>
            <OccupyFreeSpace />
            {isLoading && <LoadingIndicator />}
            {props.extraActions}
        </ActionRow>
        <TableForFields
            data={data}
            schema={schema}
            fields={[
                ["name"],
                ["created_datetime", { utcToLocal: true }]
            ]}
            rowButtons={r => <Button size="small" onClick={() => switchToSnapshot(r.name)}><FormattedMessage id="system.snapshots.switch_to_snapshot" /></Button>}
            />


        <SimpleDialog
            isOpen={newSnapshotName !== null}
            close={() => setNewSnapshotName(null)}
            dialogTitle={<FormattedMessage id="system.snapshots.create_snapshot" />}
            saveLabel={<FormattedMessage id="common.create" />}
            save={() => {
                if((newSnapshotName || "").trim().length > 0) {
                    create(newSnapshotName || "");
                }
                setNewSnapshotName(null);
            }}>

            <FormControl
                field="name"
                row={{ name: newSnapshotName }}
                onChange={(o,c) => setNewSnapshotName(c.name)}
                schema={schema.name}
                />
        </SimpleDialog>
        </>);
}
