import { useState, useCallback, useEffect } from "react";
import { apiFetch, FetchTypes } from "./toolympus/api/core";
import { LoadedData } from "./toolympus/hooks/useLoadedData"

export interface ContestState {
    registration_available: boolean;
    registration_available_judge: boolean;
    registration_available_secretary: boolean;
    case_available: boolean;
    documents_upload_available: boolean;
    documents_upload_allowed: boolean;
    team_registration_due_date: string;
    documents_assessment_available: boolean;
    documents_assessment_allowed: boolean;
    documents_scores_available: boolean;
    finals_members_update_enabled: boolean;
    documents_feedback_pass_available: boolean;
    documents_feedback_non_pass_available: boolean;
    finals_broadcast_page_available: boolean;
    judges_timeslots_available: boolean;
    judges_timeslots_enabled: boolean;
    rounds_timeslots: string[] | null;
    rounds_feedback_available: boolean;
}

export const DefaultContestState: ContestState = {
    registration_available: true,
    registration_available_judge: true,
    registration_available_secretary: true,
    case_available: true,
    documents_upload_available: true,
    documents_upload_allowed: false,
    team_registration_due_date: '',
    documents_assessment_available: false,
    documents_assessment_allowed: false,
    documents_scores_available: false,
    finals_members_update_enabled: false,
    documents_feedback_pass_available: false,
    documents_feedback_non_pass_available: false,
    finals_broadcast_page_available: false,
    judges_timeslots_available: false,
    judges_timeslots_enabled: false,
    rounds_timeslots: null,
    rounds_feedback_available: false,
}

export const useContestState = (): LoadedData<ContestState> => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<ContestState>(DefaultContestState);

    const reload = useCallback(() => {
        setIsLoading(true);
        apiFetch<ContestState>('/api/state', FetchTypes.GET, null, { noAuth: true })
            .then(s => {
                setData(s);
                setIsLoading(false);
            })
            .catch(e => {
                setIsLoading(false);
                throw e;
            })
    }, []);

    useEffect(() => reload(), [reload]);
    
    return {
        data,
        setData,
        isLoading,
        reload,
    }
};
