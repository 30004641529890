import React, { ReactNode } from 'react';
import { MessagerProvider } from '../../toolympus/components/Chats';
import { socketByToken } from '../../toolympus/hooks/useWebsocket';

interface Props {
    children?: ReactNode;
}

export const ChatsProvider = ({ children }: Props) => {
    return (
        <MessagerProvider getSocket={socketByToken('/chat/token', token => 'chat/channel?token=' + token)}>
            {children}
        </MessagerProvider>
    );
}
