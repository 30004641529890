import { Typography } from '@material-ui/core';
import React, { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { apiFetch, FetchTypes } from '../../../api/core';
import { useAction } from '../../../api/useAction';
import { SimpleDialog } from '../../primitives/Dialogs';
import { useAvailableTags } from '../../Tags';
import { RecordTagEditProps, RecordTagsEdit, useTagSelection } from '../../Tags/RecordTagsEditPopup';

interface PopupProps {
    isOpen: boolean;
    close: () => void;
    run: () => Promise<any>;
    isRunning: boolean;
    includeTags: RecordTagEditProps;
    excludeTags: RecordTagEditProps;
}

export const AssignArbitersPopup = (props: PopupProps) => {
    const { isOpen, close, run, includeTags, excludeTags } = props;
    return (
        <SimpleDialog
            isOpen={isOpen}
            close={close}
            fullWidth maxWidth="md"
            dialogTitle={<FormattedMessage id="contests.rounds.config.stages.assign_arbiters" />}
            save={run}
            saveLabel={<FormattedMessage id="contests.rounds.config.stages.assign_arbiters" />} >
            
            <Typography variant="caption"><FormattedMessage id="contests.rounds.config.stages.include_tags" /></Typography>
            <RecordTagsEdit {...includeTags} wrapTags />
            
            <Typography variant="caption"><FormattedMessage id="contests.rounds.config.stages.exclude_tags" /></Typography>
            <RecordTagsEdit {...excludeTags} wrapTags />

            <Typography><FormattedMessage id="contests.rounds.config.stages.assign_arbiters_confirmation" /></Typography>
        </SimpleDialog>
    );
}


export interface ArbitersAssignment {
    popup: ReactNode;
    open: () => void;
}

export const useArbitersAssignment = (apiPath: string, stage_code: string, cfg?: { onSave?: () => void }): ArbitersAssignment => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const { tags: allTags } = useAvailableTags();
    const includeTags = useTagSelection(allTags);
    const excludeTags = useTagSelection(allTags);

    const open = () => {
        setIsActive(true);
        includeTags.clear();
        excludeTags.clear();
    }

    const close = () => {
        setIsActive(false);
        includeTags.clear();
        excludeTags.clear();
    }

    const run = useAction(() => {
        return apiFetch(`${apiPath}/stage/${stage_code}/round/assign-arbiters`, FetchTypes.POST, {
            include: includeTags.selectedTags.map(t => t._id),
            exclude: excludeTags.selectedTags.map(t => t._id),
        })
        .then(() => {
            close();
            if(cfg?.onSave) {
                cfg.onSave();
            }
        })
    });

    return {
        open,
        popup: <AssignArbitersPopup
            close={close}
            isOpen={isActive}
            run={run.run}
            isRunning={run.isRunning}
            includeTags={includeTags}
            excludeTags={excludeTags}
            />,
    }
}