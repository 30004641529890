import { useSnackbar } from "notistack";
import { useState } from "react";
import { apiFetch, apiUploadFile, downloadFile, FetchTypes } from "../../toolympus/api/core";
import { openFileUploader } from "../../toolympus/api/files";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useContestState } from "../../useContestState";
import { Team } from "../Public/useTeamRegistrationStageOne";

export interface DocumentAccess {
    _id: string;
    document_kind: string;
    document_id: string;
    info: any & {
        late_penalty?: number;
        technical_penalty?: number;
    };
    updated_at: string;
}

export interface DocumentAccessPenalty {
    _id: string;
    kind?: string;
    team_number?: string | null;
    points?: number;
    comment?: string;
}

export const DocumentNames = {
    doc1: "Жалоба",
    doc2: "Отзыв",
}

export type DocKey = 'doc1' | 'doc2';
type Documents = Record<DocKey, DocumentAccess | null>;

export interface TeamDocuments {
    team: Team;
    documents: Documents;
    upload: (k: DocKey) => void;
    uploadFile: (k: DocKey, file: File) => void;
    remove: (k: DocKey) => void;
    download: (k: DocKey) => void;
    isFeedbackAvailable: boolean;
    getFeedback: () => void;
    isRoundsFeedbackAvailable: boolean;
    getRoundsFeedback: () => void;
    isLoading: boolean;
    isUploadAllowed: boolean;
    isScoresAvailable: boolean;
    isShowLinkToFinalsMembers: boolean;
}

const ApiPath = '/api/team/document';

export const useTeamFeedbackSheets = () => {
    const { enqueueSnackbar } = useSnackbar();

    const getFeedback = () => {
        downloadFile(`/api/assignment/documents/team/feedback`, `Хрустальная Фемида - Оценочный лист.pdf`)
            .catch(() => {
                enqueueSnackbar("Оценочный лист вам пока не доступен", { variant: "error" });
            });
    }

    const getRoundsFeedback = () => {
        downloadFile(`/api/assignment/documents/team/feedback-rounds`, `Хрустальная Фемида - Оценочный лист (устные раунды).pdf`)
            .catch(() => {
                enqueueSnackbar("Оценочный лист вам пока не доступен", { variant: "error" });
            });
    }

    return {
        getFeedback,
        getRoundsFeedback,
    }
}

export const useTeamDocuments = (): TeamDocuments => {
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const { data: team, isLoading: isTeamLoading } = useLoadedData<Team>("/api/team/my", {} as Team);
    const { data, isLoading, reload } = useLoadedData<DocumentAccess[]>(ApiPath, []);
    const state = useContestState();

    const isUploadAllowed = !state.isLoading && state.data.documents_upload_allowed

    const documents = data.reduce((r,d) => ({ ...r, [d.document_kind]: d }), { doc1: null, doc2: null}) as Documents;

    const uploadFile = (k: DocKey, file: File) => {
        setIsUploading(true);
            apiUploadFile(`${ApiPath}/${k}`, FetchTypes.PUT, 'file', file)
                .then(() => {
                    setIsUploading(false);
                    reload();
                })
                .catch(e => {
                    setIsUploading(false);
                    throw e;
                });
    }

    const upload = (k: DocKey) => {
        if(!isUploadAllowed) {
            return;
        }

        openFileUploader(f => uploadFile(k, f));
    }

    const remove = (k: DocKey) => {
        if(!isUploadAllowed) {
            return;
        }

        const d = documents[k];
        if(d) {
            apiFetch(`${ApiPath}/${k}`, FetchTypes.DELETE)
                .then(() => reload());
        }
    }

    const download = (k: DocKey) => {
        const d = documents[k];
        if(d) {
            downloadFile(`${ApiPath}/${d.document_id}`, `${DocumentNames[k]}.docx`);
        }
    }

    const isFeedbackAvailable = state.data.documents_feedback_pass_available || state.data.documents_feedback_non_pass_available;
    const isRoundsFeedbackAvailable = state.data.rounds_feedback_available && !!team.finals_stage;

    const { getFeedback, getRoundsFeedback } = useTeamFeedbackSheets();

    return {
        documents,
        team,
        isLoading: isLoading || isTeamLoading || isUploading,
        upload,
        uploadFile,
        remove,
        download,
        isUploadAllowed,
        isScoresAvailable: state.data.documents_scores_available,
        isShowLinkToFinalsMembers: state.data.finals_members_update_enabled && !!team.finals_stage,

        isFeedbackAvailable,
        getFeedback,
        isRoundsFeedbackAvailable,
        getRoundsFeedback,
    }
}