import React, { ReactNode, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
    Tab,
    Tabs,
    withTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

const TabsHeaderContainer = withTheme(styled(Tabs)`
    margin-top: ${props => (props as any).noMargin ? 0 : '2em'};
    border-bottom: 1px solid ${props => props.theme.palette.grey[400]};
    background: ${props => props.theme.palette.grey[100]};
    border-top: 1px solid ${props => props.theme.palette.grey[400]};

    & button {
        background: ${props => props.theme.palette.grey[100]};
    }
    & button:last-child{
        border-right: 1px solid ${props => props.theme.palette.grey[400]};
    }
    & button:first-child{
        border-left: 1px solid ${props => props.theme.palette.grey[400]};
    }

    & button[aria-selected="true"] {
        background: ${props => props.theme.palette.background.paper};
    }
`);

interface TabConfig {
    hidden?: boolean;
}

type TabDefinition = [string, ReactNode] | [string, ReactNode, TabConfig];

export interface TabsStateForTab {
    key: string;
    isActive: boolean;
}

export interface TabsState {
    tabs: TabDefinition[];
    labels: ReactNode[];
    keys: string[];
    current: string;
    setCurrent: (key: string) => void;
    onChange: (evt: any, key: string) => void;
    forTab: (key: string) => TabsStateForTab;
}

export const useTabsState = (tabs: TabDefinition[], defaultTab?: string, queryParam?: string, localStorageKey?: string): TabsState => {
    const tabsAvailable = tabs.filter(([k,l,cfg]) => !cfg || !cfg.hidden)
    const keys = tabsAvailable.map(([k,l]) => k);
    const labels = tabsAvailable.map(([k,l]) => l);

    const [currentTabKey, setCurrentTabKey] = useState<string>(defaultTab || keys[0]);
    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    useEffect(() => {
        let readFromQuery = false;
        if(queryParam) {
            const keyFromUrl = query.get(queryParam);
            if(keyFromUrl) {
                setCurrentTabKey(keyFromUrl);
                readFromQuery = true;
            }
        }
        
        if (localStorageKey && !readFromQuery) {
            const keyFromLS = localStorage.getItem(localStorageKey);
            if(keyFromLS) {
                setCurrentTabKey(keyFromLS);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    


    const forTab = (key: string) => ({ key, isActive: currentTabKey === key})

    const setCurrent = (key: string) => {
        setCurrentTabKey(key);
        if(queryParam) {
            query.set(queryParam, key);
            history.replace({ pathname: location.pathname, search: `?${query.toString()}`});
        }
        if(localStorageKey) {
            localStorage.setItem(localStorageKey, key);
        }
    }

    return {
        tabs: tabsAvailable,
        keys,
        labels,
        current: currentTabKey,
        setCurrent,
        onChange: (e: any, k: string) => setCurrent(k),
        forTab,
    }

}


interface TabsHeaderProps {
    noMargin?: boolean;
    state: TabsState;
    scrollable?: boolean;
}


export const TabsHeader = (props: TabsHeaderProps) => {
    const { state, scrollable } = props;

    const scrollProps: any = scrollable? {
        variant: "scrollable",
        scrollButtons: "auto"
    } : {}

    return <TabsHeaderContainer
        value={state.current}
        onChange={state.onChange}
        indicatorColor="primary"
        {...props}
        {...scrollProps}
        textColor="primary">
            {state.tabs.map(([k, l]) => (<Tab key={k} label={l} value={k} />))}
    </TabsHeaderContainer>;
}

interface PanelProps {
    state: TabsStateForTab;
    children: ReactNode;
    alwaysRenderChildren?: boolean;
    style?: React.CSSProperties;
}

export const TabPanel = (props: PanelProps) => {
    const { state } = props;
    return (
        <div key={state.key} role="tabpanel" hidden={!state.isActive} style={props.style}>
            {(!!state.isActive || props.alwaysRenderChildren) && props.children}
        </div>
    );
}