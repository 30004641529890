import React, { ReactNode, useContext } from 'react';
import { FieldDefinition } from '../../schemed';
import { RoundPlayerInfoProps } from './RoundPlayerInfo';
import { Arbiter, Player, PlayerStanding } from './types';

export interface GridSettings {
    arbiter_links_enabled: boolean;
    qr_codes_enabled: boolean;
    third_place_game_allowed: boolean;
    judges_per_stage_round: Record<string, number>;
    conference_kind?: "zoom" | null,
    get_arbiter_class?: (a: Arbiter, idxInRound: string) => string | null | undefined;
    round_player_info_component?: React.ComponentType<RoundPlayerInfoProps>;
    admin_round_popup_player_info_component?: React.ComponentType<RoundPlayerInfoProps>;
    player_extra_columns?: [string, (player: Player) => ReactNode][];
    arbiter_extra_columns?: [string, (player: Arbiter) => ReactNode][];
    get_results_extra_columns?: (stage: string) => { columns: FieldDefinition[], getValue: (column: string, playerStanding: PlayerStanding) => ReactNode } | undefined;
    
}

const DefaultSettings: GridSettings = {
    arbiter_links_enabled: true,
    qr_codes_enabled: true,
    third_place_game_allowed: false,
    judges_per_stage_round: {},
    get_arbiter_class: () => undefined,
};

export const GridSettingsContext = React.createContext<Partial<GridSettings>>(DefaultSettings);

export const GridSettingsProvider = ({ settings, children }: { settings: Partial<GridSettings>, children: ReactNode }) => {
    return <GridSettingsContext.Provider value={{ ...DefaultSettings, ...settings}}>
        {children}
    </GridSettingsContext.Provider>
}

export const useGridSettings = () => useContext(GridSettingsContext);
