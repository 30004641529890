import { useState } from "react";
import { downloadFile } from "../../toolympus/api/core";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { FieldSorting, useFieldSorting } from "../../toolympus/hooks/useFieldSorting";
import { LoadedData, useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { Schema, useSchema } from "../../toolympus/hooks/useSchema";
import { Judge } from "../Public/useJudgeRegistration";
import { TableComments, useListComments } from "./useComments";
import { JudgeReview, useJudgeReview } from "./useJudgeReview";

interface JudgeListData extends LoadedData<Judge[]> {
    schema: Schema;
    filter: string;
    setFilter: (v: string) => void;
    view: string;
    setView: (v: string) => void;
    exportJudges: () => void;
    comments: TableComments;
    judgeReview: JudgeReview;
    sorting: FieldSorting;
}

export const JudgeViews: [string, string][] = [
    ["Одобренные", "approved"],
    ["На рассмотрении", "review"],
    ["Отказ", "rejected"],
    ["Все", "all"],
];

export const useJudgeList = (): JudgeListData => {
    const { judge: schema } = useSchema();
    const sorting = useFieldSorting({
        disallowedFields: ["assigned", "remaining"],
    });
    const data = useLoadedData<Judge[]>(`/api/admin/judge?${sorting.queryParameter}`, []);
    const comments = useListComments('judge');
    const judgeReview = useJudgeReview();

    const [view, setView] = useState<string>("review");

    const viewFilter: Record<string, (j: Judge) => boolean> = {
        approved: t => t.approval_status === "approved",
        rejected: t => t.approval_status === "rejected",
        review: t=> !t.approval_status,
    };

    const { filter, setFilter, filterData } = useTextFilter<Judge>(j => `${j.lastname} ${j.firstname} ${j.job} ${j.email} ${j.city}`);

    const exportJudges = () => {
        downloadFile("/api/admin/export/judges", "судьи.xlsx");
    }
    
    const filterByView = (teams: Judge[]): Judge[] => data.data.filter(viewFilter[view] || (() => true));
    const judges = filterData(filterByView(data.data));

    return {
        ...data,
        data: judges,
        schema,
        filter,
        setFilter,
        view,
        setView,
        exportJudges,
        sorting,
        comments,
        judgeReview: {
            ...judgeReview,
            approve: () => judgeReview.approve().then(() => data.reload()),
            reject: () => judgeReview.reject().then(() => data.reload()),
        },
    }
}
