import React from 'react';
import { PageContainer } from '../../toolympus/components/Pages';
import { FormWrapper } from '../../toolympus/components/primitives/Forms';
import { TeamDocuments } from './TeamDocuments';

export const TeamDocumentsPage = () => {

    return (
        <FormWrapper>
            <PageContainer apiPath="/api/page" slug="team-documents" customComponents={{ documents: () => <TeamDocuments /> }}  />
        </FormWrapper>
    );
}
