import React from 'react';
import { Form } from '../../../toolympus/components/primitives/Forms';
import { TableForFields } from '../../../toolympus/components/schemed';
import { useJudgeAssignmentStats } from './useJudgeAssignmentStats';

export const JudgeStats = () => {
    const data = useJudgeAssignmentStats();
    return (
        <Form
            formPaperProps={{ style: { display: 'flex', flexFlow: 'column', overflow: 'hidden', maxHeight: '100%' }}}
            formBodyProps={{ style: { overflow: 'hidden' }}}
            >

            <TableForFields
                schema={data.schema}
                data={data.data}
                fields={[
                    ["email"],
                    ["lastname"],
                    ["firstname"],
                    ["middlename"],
                    ["judge_written_works_nbr"],
                    ["assigned"],
                    ["scored"],
                    ["remaining"],
                ]}
                rowStyle={r => r.assigned === r.scored ? { background: "#00cc0020" } : {}}
                />
        </Form>
    );
}
