import { Typography, withTheme } from '@material-ui/core';
import React from 'react';
import styled from '@emotion/styled/macro';
import { MarkdownDisplay } from '../../toolympus/components/schemed/Markdown';
import { useTitleTexts } from './useTitleTexts';

interface Props {
}

const Block = styled.div`
    & > :not(:last-child) {
        margin-bottom: 0px;
    }

    & > div > p:first-child {
        margin-top: 0;
    }
`;

const Wrapper = withTheme(styled.div`
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    
    ${props => props.theme.breakpoints.down('md')} {
        & > ${Block}:not(:first-child) {
            margin-top: 48px;
        }
    }
`);

const Caption = withTheme(styled(Typography)`
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;

    letter-spacing: 0.75px;
    text-transform: uppercase;

    ${props => props.theme.breakpoints.down('md')} {
        font-size: 16px;
        line-height: 20px;
    }
`);
Caption.defaultProps = { variant: "h3" };

const Title = withTheme(styled(Typography)`
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;

    letter-spacing: 0.75px;
    text-transform: uppercase;

    
    ${props => props.theme.breakpoints.down('md')} {
        font-size: 20px;
        line-height: 24px;
    }
`);
Title.defaultProps = { variant: "h1" };

const YearTitle = styled(Typography)`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* or 108% */

    letter-spacing: 0.75px;
    text-transform: uppercase;
    `;
YearTitle.defaultProps = { variant: "h2" };

const TextBlock = styled.div`
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    letter-spacing: 0.75px;

    & > div > p:first-child {
        margin-top: 0;
    }
    & > div > p:last-child {
        margin-bottom: 0;
    }
`;

const DescriptionBlock = styled(TextBlock)`
`;

export const MootDescription = (props: Props) => {
    const texts = useTitleTexts();
    
    return (
        <Wrapper>
            <Block>
                <Caption>
                    {texts.caption}
                </Caption>
                <Title>
                    {texts.title}
                </Title>
                <MarkdownDisplay text={texts.subtitle} />
            </Block>
            
            <Block>
                <Caption>
                    {texts.year_caption}
                </Caption>
                <YearTitle>
                    {texts.year_title.split('\n').reduce((r, x) => ([...r, <br/>, x]), [] as (string | JSX.Element)[]).slice(1)}
                </YearTitle>
                <DescriptionBlock>
                    <MarkdownDisplay text={texts.year_description} />
                </DescriptionBlock>
            </Block>
        </Wrapper>
    );
}
