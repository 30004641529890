import { useState } from "react";
import { useHistory } from "react-router-dom";
import { apiFetch, FetchTypes } from "../../toolympus/api/core";
import { useValidationErrors, ValidationErrors } from "../../toolympus/components/schemed";
import { useDictionaries } from "../../toolympus/hooks/useDictionaries";
import { FieldType, mergeSchema, Schema, useSchema } from "../../toolympus/hooks/useSchema";
import { login, useUser } from "../../toolympus/userContext/UserContext";
import { DocKey, DocumentAccess } from "../Team/useTeamDocuments";

export interface TeamRegistration {
    email: string;
    password: string;
    passwordRepeat: string;
    university: string;
    university_bound?: string;
    department: string;
    city: string;
    personal_data_consent: boolean;
}

export interface Team extends TeamRegistration {
    _id: string;
    number?: string | null;
    is_filled: boolean;
    is_blocked: boolean;
    pdf_application_uploaded: boolean;
    approval_status: string | null;
    reject_explanation: string | null;
    members: any[];
    coaches: any[];
    finale_consent: boolean;
    photos_consent: boolean;
    documents?: Record<DocKey, DocumentAccess | null>;

    finals_stage?: string;
    documents_score?: number;
    doc1_score?: number;
    doc2_score?: number;
    finals_participants?: any;
}

export interface RegistrationData<T> {
    schema: Schema;
    data: T;
    update: (changes: Partial<T>) => void;
    submit: () => Promise<any>;
    canSubmit: boolean;
    isLoading: boolean;
    errors: ValidationErrors;
}

interface TeamRegistrationStageOneData extends RegistrationData<TeamRegistration> {
    isOtherUniversity: boolean;
}

export const TeamSchemaChanges = {
    password: { type: FieldType.password },
    passwordRepeat: { type: FieldType.password, label: "Пароль ещё раз" },
    university: { label: "Название ВУЗа полностью" },

    finale_consent: { label: "Все участники команды подтверждают своё согласие в случае прохождения Отборочного этапа участвовать в Финальном этапе" },
    personal_data_consent: { label: "Все участники команды и тренеры команды дают своё согласие на обработку персональных данных" },
    photos_consent: { label: "Участники команды и тренеры команды выражают своё согласие на публикацию официальных фотографий полуфинальных и финального раундов на странице Конкурса в сети Интернет и социальных сетях" },
};

export const useTeamRegistrationStageOne = (): TeamRegistrationStageOneData => {
    const { team: teamSchema } = useSchema();

    const schema = mergeSchema(teamSchema, TeamSchemaChanges);

    const [data, setData] = useState<TeamRegistration>({
        email: "",
        password: "",
        passwordRepeat: "",
        university: "",
        department: "",
        city: "",
        personal_data_consent: false,
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const user = useUser();
    const history = useHistory();
    const errors = useValidationErrors();

    const dictionaries = useDictionaries();

    const canSubmit = (
        Object.entries(data).every(([k,v]) => v && v !== "")
        && data.password === data.passwordRepeat
    );

    const submit = () => {
        setIsLoading(true);
        errors.clearErrors();
        return apiFetch<string>('/api/team/register', FetchTypes.POST, data)
            .then(token => {
                login(user, token);
                history.replace('/team/profile');
                setIsLoading(false);
            })
            .catch(e => {
                errors.captureErrors(e);
                setIsLoading(false);
                throw e;
            })
    }

    const update = (changes: Partial<TeamRegistration>) => {
        const changesPrime = { ...changes };

        if(changesPrime.university_bound) {
            if(changesPrime.university_bound === "other") {
                changesPrime.university = "";
            } else {
                const unisDict = dictionaries["Universities"];
                const university = (unisDict.records || []).find(r => r.code === changesPrime.university_bound || "");
                changesPrime.university = university?.label || changesPrime.university_bound;
            }
        }

        setData({ ...data, ...changesPrime });
    }

    return {
        schema,
        data,
        update,
        canSubmit,
        submit,
        isLoading,
        errors,
        isOtherUniversity: data.university_bound === "other",
    }
}