import React from 'react';
import { ScoreRoundForm, ScoreRoundFormProps } from './ScoreRoundForm';
import { ScoreParams, useScoreRoundByCode } from './useScoreRoundByCode';

interface Props extends Omit<ScoreRoundFormProps, 'data'> {
    apiPath: string;
    params: ScoreParams;
}

/**
 * Scoring form page for grid arbitrators.
 * If the page api path and slug are provided, the round info and the scoring controls will be plugged
 * in place of the `info` and `scores` components, respectively.
 * Otherwise info and scores will be returned, wrapped in a FormWrapper.
 * @param props 
 * @returns 
 */
export const ScoreRoundContainer = (props: Props) => {
    const data = useScoreRoundByCode(props.apiPath, props.params);

    return <ScoreRoundForm {...props} data={data} />;
}
