import React, { useState } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { SimpleButton } from '../../toolympus/components/primitives/Buttons';
import { useResetPassword } from '../../toolympus/hooks/auth';
import { LoginLikePage } from './LoginLikePage';
import { CaptionAndLinksBlock } from './Common';
import { FormGrid } from '../../toolympus/components/primitives/Forms';

export const PasswordResetPage = () => {
    const { email, updateEmail, restore, isSaving } = useResetPassword("/api/restore-access/request");
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const sendR = () => restore().then(() => setIsSuccess(true));

    return (
        <LoginLikePage>
            <CaptionAndLinksBlock>
                <Typography variant="h3">восстановление пароля</Typography>
            </CaptionAndLinksBlock>
            {!isSuccess && <FormGrid columns="1fr">
                <TextField
                    label={<FormattedMessage id="resetPassword.email"/>}
                    value={email}
                    onChange={(ev) => updateEmail(ev.target.value || '')}
                />
                <SimpleButton
                    label={<FormattedMessage id="resetPassword.submit"/>}
                    action={sendR}
                    isLoading={isSaving}
                />
            </FormGrid>}
            {isSuccess && <Typography>На Вашу почту отправлена ссылка для установки нового пароля. Пожалуйста, перейдите по ней.</Typography>}
        </LoginLikePage>
    );
}

