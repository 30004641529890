import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { IconButton, Typography, withTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { MenuRoute, Routes } from '.';
import { ActionRow, OccupyFreeSpace } from '../primitives/ActionRow';

interface MenuGroupItem {
    key: string;
    label: ReactNode;
    component: React.ComponentType<any>;
}

export interface MenuGroup {
    key: string;
    icon?: ReactNode;
    label: ReactNode;
    items: MenuGroupItem[];
}

interface Props {
    groups: MenuGroup[];
    noCloseButton?: boolean;
}

interface MenuDisplayProps extends Props {
    pathPrefix: string;
}

const Wrapper = styled.div`
    padding: 3rem 1.5rem;
`;

const MenuWrapper = withTheme(styled.ul`
    padding: 0.5rem 0;
    gap: 50px 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    ${props => props.theme.breakpoints.down('sm')} {
        grid-template-columns: 1fr;
    }

    & > li > div, & > li > a {
        display: flex;
        flex-flow: row;
        align-items: center;
        font-size: 1.6rem;

        & > svg {
            font-size: 1.2em;
            display: inline;
            padding-right: 0.5rem;
            color: ${props => props.theme.palette.primary.main};
        }
        
        & h3 {
            font-size: inherit;
            padding-bottom: 3px;
            border-bottom: 1px dotted ${props => props.theme.palette.primary.main};
        }
    }


    & ul {
        padding: 0.5rem 1.75rem;
    }

    & li {
        list-style: none;
    }

    & a {
       text-decoration: none;
       color: inherit;
    }

    & ul a {
       color: ${props => props.theme.palette.primary.main};
    }
`);

const groupItemLink = (g: MenuGroup, i: MenuGroupItem) => `${g.key}${g.items.length > 1 ? "/" : ""}${g.items.length > 1 ? i.key : ""}`;

export const Submenu = (props: MenuDisplayProps) => {
    const history = useHistory();

    return (
        <Wrapper>
            {!props.noCloseButton && <ActionRow>
                <OccupyFreeSpace />
                <IconButton onClick={() => history.goBack()}><Close /></IconButton>
            </ActionRow>}
            <MenuWrapper>
                {props.groups.map(g => (
                    <li key={g.key}>
                        {g.items.length > 1 ? <>
                            <div>{g.icon}<Typography variant="h3">{g.label}</Typography></div>
                            <ul>
                                {g.items.map(i => <li key={i.key}>
                                    <Link to={`${props.pathPrefix}/${groupItemLink(g, i)}`}>
                                        <Typography>{i.label}</Typography>
                                    </Link>
                                </li>)}
                            </ul>
                        </>
                        : <Link to={`${props.pathPrefix}/${groupItemLink(g, g.items[0])}`}>
                            {g.icon}<Typography variant="h3">{g.label}</Typography>
                        </Link>}
                    </li>
                ))}
            </MenuWrapper>
        </Wrapper>
    );
}

export const SubmenuContainer = (props: Props) => {
    let { path } = useRouteMatch();
    const routes = props.groups.reduce((r,g) => ([
        ...r,
        ...g.items.map(i => ({
            path: `${path}/${groupItemLink(g, i)}`,
            component: i.component,
        })),
    ]), [] as MenuRoute[]);

    return (
        <Routes
            routes={[
                ...routes,
                { path: "/", component: () => <Submenu pathPrefix={path} {...props} /> },
            ]}
        />
    )
}
