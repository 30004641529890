import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { SignupMode, useInviteSignup } from '../../toolympus/hooks/auth';
import { LoginLikePage } from './LoginLikePage';
import { CaptionAndLinksBlock } from './Common';
import { SignupForm } from '../../toolympus/components/auth';

const Wrapper = styled.div`
    width: 100%;
    & .MuiContainer-root {
        padding: 0;
    }
`;

export const InviteSignupPage = () => {
    const data = useInviteSignup({
        signupApiPath: '/api/signup-invite',
        loginOnSuccess: true,
        redirectPath: '/',
    })

    return (
        <LoginLikePage>
            <CaptionAndLinksBlock>
                <Typography variant="h3">регистрация</Typography>
            </CaptionAndLinksBlock>
            <Wrapper>
                <SignupForm mode={SignupMode.Invite} signupData={data} />
            </Wrapper>
        </LoginLikePage>
    );
}

