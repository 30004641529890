import React, { ReactNode, useState } from "react";
import { useConfirmationDialog } from "../components/primitives/ConfirmationDialog";
import { LoadingIndicator } from "../components/primitives/LoadingIndicator";

export interface Action<T> {
    run: () => Promise<T>;
    isRunning: boolean;
    canRun: boolean;
    buttonProps: {
        onClick: () => { },
        disabled: boolean,
        startIcon: ReactNode,
    }
}

export const useAction = <T,>(action: () => Promise<T>, canRun?: boolean): Action<T> => {
    const [isRunning, setIsRunning] = useState<boolean>(false);

    const run = () => {
        setIsRunning(true);
        return action()
            .then(x => {
                setIsRunning(false);
                return x;
            })
            .catch(e => {
                setIsRunning(false);
                throw e;
            })
    }

    const canRunX = canRun === undefined ? true : canRun;

    return {
        isRunning,
        canRun: canRunX,
        run,

        buttonProps: {
            onClick: run,
            disabled: !canRunX || isRunning,
            startIcon: isRunning && <LoadingIndicator sizeVariant="s" />,
        }
    }
}


export interface ActionWithConfirmation<T> extends Action<T> {
    title?: ReactNode;
}

interface ActionConfig {
    canRun?: boolean;
    skipConfirmation?: boolean;
    title?: ReactNode;
    confirmationHint?: ReactNode;
    confirmationLabel?: ReactNode;
    confirmationText?: string;
}

export const useActionWithConfirmation = <T,>(action: () => Promise<T>, config: ActionConfig): ActionWithConfirmation<T> => {
    const actionx = useAction(action, config.canRun);
    const confirmationDialog = useConfirmationDialog();

    return {
        ...actionx,
        title: config.title,
        run: () => {
            if(config.skipConfirmation) {
                return actionx.run();
            } else {
                confirmationDialog.open({
                    execute: actionx.run,
                    confirmationText: config.confirmationText,
                    confirmationLabel: config.confirmationLabel,
                    title: config.title ? <>{config.title}?</> : "",
                    hint: config.confirmationHint,
                });
                return Promise.resolve({ } as T);
            }
        }
    }
}
