import React from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { FormWrapper } from '../../../primitives/Forms';
import { LoadingIndicator } from '../../../primitives/LoadingIndicator';
import { useFormats } from '../../../schemed';
import { utc } from '../../../timezone';
import { RoundScore, RoundToScore, ScoreRoundData } from './useScoreRoundByCode';
import { FormattedMessage } from 'react-intl';
import { Check, KeyboardReturn } from '@material-ui/icons';
import { ScoringComponentMapping, ScoringSheetForm } from '../../ScoringSheets';
import { PageContainer } from '../../../Pages';
import { ScoredRoundContext } from './ScoredRoundContext';
import { useHistory } from 'react-router-dom';

export interface ScoreRoundFormProps {
    data: ScoreRoundData;
    customComponents?: ScoringComponentMapping;
    pageApiPath?: string;
    pageSlug?: string;
    goBackButton?: boolean;
}

const RoundInfoWrapper = styled.div`
    padding-bottom: 0.5rem;
`;

const RoundInfo = ({ round }: { round: RoundToScore }) => {
    const { formatDatetimeShort } = useFormats();

    if(!round || !round._id) {
        return <></>;
    }

    return <RoundInfoWrapper className="round-info">
        <Typography variant="h5" className="heading">
            <span className="title">{round.title}{round.time ? `, ` : ""}</span><span className="time">{round.time ? formatDatetimeShort(utc.toLocal(round.time)) : ""}</span>
        </Typography>
        <Typography className="description">{round.description}</Typography>
    </RoundInfoWrapper>
}

const ScoresWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 40px 1fr;
    grid-row-gap: 0.5rem;
    align-items: center;
    justify-items: center;

    padding-bottom: 20px;

    & .score-label {
        grid-column: span 3;
    }

    &.scores-head {
        padding-bottom: 1rem;
    }
`;

const LoaderContainer = styled.div`
    width: 100%;
    height: 0;
    position: relative;

    & > * {
        min-height: 30px;
        position: absolute;
        top: 0;
        right: 0;
    }
`;

const ScoreButton = ({ score, data }: { data: ScoreRoundData, score: RoundScore }) => {
    return <Button
            className={`${score.score_key} ${score.is_scored ? 'scored' : 'unscored'}`}
            color="primary"
            variant={score.is_scored ? "contained" : "outlined"}
            endIcon={score.is_scored ? <Check /> : undefined}
            onClick={() => data.startScoring(score)}>
                <FormattedMessage id={score.is_scored ? "contests.rounds.scores.scored" : "contests.rounds.scores.score"} />
    </Button>
}

const SelectPlayerButton = ({ score, data, position }: { data: ScoreRoundData, score?: RoundScore, position: 1 | 2 }) => {
    return <Button
            className={`${score?.score_key} ${score?.is_scored ? 'scored' : 'unscored'} ${score?.value === position ? 'selected' : 'unselected'}`}
            color={!score?.is_scored || score?.value === position ? "primary" : "default"}
            variant={score?.value === position ? "contained" : "outlined"}
            endIcon={score?.value === position ? <Check /> : undefined}
            onClick={() => score && data.setPlayerSelectScore(score, position)}>
                <FormattedMessage id={score?.value === position ? "contests.rounds.scores.selected_player" : "contests.rounds.scores.select_player"} />
    </Button>
}

export const ScoreRoundForm = (props: ScoreRoundFormProps) => {
    const { round, scoresGrouped, getPlayer, activeScore, cancelScoring, scoring, } = props.data;
    const { pageApiPath, pageSlug } = props;

    const history = useHistory();

    const infoPortion = <>
        <LoaderContainer>
            <div>
                {round.isLoading && <LoadingIndicator />}
                {props.goBackButton && !round.isLoading && <IconButton size="small" onClick={() => history.goBack()}><KeyboardReturn /></IconButton>}
            </div>
        </LoaderContainer>
        {round.data && round.data._id &&  <RoundInfo round={round.data} />}
    </>;

    const scoresPortion = round.data && round.data._id ? <>
            <ScoresWrapper key="head" className="scores-head">
                <Typography variant="h6" key="p1" className="player-name player1">{getPlayer(1).display_name}</Typography>
                <Typography variant="caption" key="vs" className="vs">vs</Typography>
                <Typography variant="h6" key="p2" className="player-name player2">{getPlayer(2).display_name}</Typography>
            </ScoresWrapper>
            {scoresGrouped.map(sg => sg.is_double ? (
                    <ScoresWrapper key={sg.group_key} className={`score score-double ${sg.group_key}`}>
                        <Typography className="score-label">{sg.label}</Typography>

                        <ScoreButton key={sg.left?.score_key} score={sg.left as RoundScore} data={props.data} />
                        <div />
                        <ScoreButton key={sg.right?.score_key} score={sg.right as RoundScore} data={props.data} />
                    </ScoresWrapper>
                ) : (
                    <ScoresWrapper key={sg.group_key} className={`score score-select ${sg.group_key}`}>
                        <Typography className="score-label">{sg.label}</Typography>

                        <SelectPlayerButton key={`${sg.left?.score_key}_1`} score={sg.left as RoundScore} data={props.data} position={1} />
                        <div />
                        <SelectPlayerButton key={`${sg.left?.score_key}_2`} score={sg.left as RoundScore} data={props.data} position={2} />
                    </ScoresWrapper>
                ))}
            <Dialog open={!!activeScore} onClose={cancelScoring} maxWidth="md" fullWidth>
                <DialogTitle>{activeScore?.score_label} - {getPlayer(activeScore?.position || 0).display_name}</DialogTitle>
                <DialogContent>
                    <ScoredRoundContext.Provider value={{ round: round.data, score: activeScore }}>
                        <ScoringSheetForm data={scoring} customComponents={props.customComponents} />
                    </ScoredRoundContext.Provider>
                </DialogContent>
            </Dialog>
            </>
            :
            <></>;

    return pageApiPath && pageSlug ? (
        <PageContainer apiPath={pageApiPath} slug={pageSlug} suppressLoadingIndicator customComponents={{
            info: () => infoPortion,
            scores: () => scoresPortion,
        }} />
    ) : (
        <FormWrapper elevation={0}>
            {infoPortion}

            {scoresPortion}
        </FormWrapper>
    );
}
