import { useState } from "react";
import { apiFetch, FetchTypes } from "../../toolympus/api/core";
import { LoadedData, useLoadedData } from "../../toolympus/hooks/useLoadedData";

export interface CaseQuestion {
    _id: string;
    question: string;
    author_name: string;
    created_datetime: string;
}

export interface UserCaseQuestionsData extends LoadedData<CaseQuestion[]> {
    submitQuestion: () => void;
    canSubmit: boolean;
    newQuestion: string;
    updateNewQuestion: (v: string) => void;
}

const apiPath = '/api/case-question/user';

export const useUserCaseQuestions = (): UserCaseQuestionsData => {
    const data = useLoadedData<CaseQuestion[]>(apiPath, []);
    const [newQuestion, updateNewQuestion] = useState<string>("");

    const canSubmit = newQuestion.length > 10 && !data.isLoading;

    const submitQuestion = () => {
        if(canSubmit) {
            const question = newQuestion;
            updateNewQuestion("");
            apiFetch(apiPath, FetchTypes.POST, { question })
                .then(() => data.reload());
        }
    }

    return {
        ...data,
        newQuestion,
        updateNewQuestion,
        canSubmit,
        submitQuestion,
    }
}