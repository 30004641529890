import { withTheme, Theme } from '@material-ui/core';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const AppContent = styled.div`
    display: grid;
    grid-template-rows: max-content;
    grid-auto-rows: 1fr;
    box-sizing: border-box;

    flex-grow: 1;
    width: 100%;
    min-height: 100vh;
    background-color: white;
    overflow-x: hidden;
    padding: 0;

    position: relative;

    & .MuiAppBar-root {
        box-shadow: none;
    }
`;
AppContent.defaultProps = {className: 'AppContent'};

interface ScrollBarStyle {
    size?: number;
}

export const scrollStyle = (theme: Theme, style: ScrollBarStyle) => css`
    & *::-webkit-scrollbar {
        width: ${style.size || 2}px;
        height: ${style.size || 2}px;
        background-color: ${theme.palette.grey[200]};
    }
    & *::-webkit-scrollbar-thumb {
        background-color: ${theme.palette.primary.light};
    }
`;


export const AppContentSimple = withTheme(styled.div<{ fit100Height?: boolean, scrollBarsStyle?: ScrollBarStyle, }>`
    overflow-x: hidden;
    width: 100%;
    min-height: 100vh;
    max-height: ${props => props.fit100Height ? '100vh' : 'none'};
    background-color: white;

    ${props => props.scrollBarsStyle ? scrollStyle(props.theme, props.scrollBarsStyle) : ''}
`);