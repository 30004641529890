import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useLoadedData } from '../../toolympus/hooks/useLoadedData';
import { GridConfiguration } from "../../toolympus/components/Contests/Grid/useGridConfiguration";
import { Round } from '../../toolympus/components/Contests/Grid/types';
import { apiFetch } from '../../toolympus/api/core';
import { DictionariesManualProvider } from '../../toolympus/hooks/useDictionaries';
import { useIntl } from 'react-intl';
import { useFormats } from '../../toolympus/components/schemed';

const useFullRoundsList = () => {
    const gridConfig = useLoadedData<GridConfiguration>("/api/admin/finals", { stages: [] } as any as GridConfiguration);
    const [rounds, setRounds] = useState<Round[]>([]);

    useEffect(() => {
        if(gridConfig.data.stages.length > 0) {
            Promise.all(gridConfig.data.stages.map(stage => apiFetch<Round[]>(`/api/admin/finals/stage/${stage.code}/round`)))
                .then(roundsByStage => setRounds(roundsByStage.reduce((r,stageRounds) => [...r, ...stageRounds], [])));
        }
    }, [gridConfig.data.stages])
    
    return {
        rounds,
    }
}

export const RoundsDictionaryProvider = (props: PropsWithChildren<{}>) => {
    const { rounds } = useFullRoundsList();
    const { formatMessage } = useIntl();
    const { formatDatetimeShort } = useFormats();

    const roundLabel = (r: Round) => `${r.title} (${r.time ? formatDatetimeShort(r.time) : "-"}, ${formatMessage({ id: `contests.rounds.public.stage_names.${r.stage_code}`})})`

    const roundsDict = useMemo(() => {
        const records = rounds.map(r => ({ value: r._id, label: roundLabel(r) }))
        return {
            id: "rounds",
            name: "rounds",
            comment: "",
            records: records.map((r,i) => ({ code: r.value, sortorder: i, label: r.label, comment: "", disabled: false })),
            values: records,
            valueDict: records.reduce<Record<string, string>>((result,r) => { result[r.value] = r.label; return result; }, {})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rounds]);
    return (
        <DictionariesManualProvider dictionaries={{
            rounds: roundsDict
            }}>
            {props.children}
        </DictionariesManualProvider>
    );
}
