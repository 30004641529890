import React from "react";
import { CasePage } from "../components/CasePage";
import { ChatsUnreadIndicator } from "../components/Chats/ChatsUnreadIndicator";
import { TeamChatWithOrgPage } from "../components/Chats/ChatWithOrgPage";
import { InfoPage } from "../components/InfoPage";
import { TeamDocumentsPage } from "../components/Team/TeamDocumentsPage";
import { TeamProfilePage } from "../components/Team/TeamProfilePage";
import { TeamRoundsPage } from "../components/Admin/Finals/TeamRoundsPage";
import { PublicResultsContainer } from "../toolympus/components/Contests/Grid";
import { MenuRoute } from "../toolympus/components/frame";
import { FormWrapper } from "../toolympus/components/primitives/Forms";

export const TeamApplicationRoutes: MenuRoute[] = [
    { 
        path: '/team/info',
        title: 'Информация',
        component: () => <InfoPage slug="team-info" />,
    },
    { 
        path: '/team/profile',
        title: 'Команда и регистрация',
        component: () => <TeamProfilePage />,
    },
    { 
        path: '/team/case',
        title: 'Фабула',
        component: () => <CasePage />,
        resolveHidden: (roles, ctx) => roles.includes("team") && ctx?.state?.case_available,
    },
    { 
        path: '/team/documents',
        title: 'Процессуальные документы',
        component: () => <TeamDocumentsPage />,
        resolveHidden: (roles, ctx) => roles.includes("team") && ctx?.state?.documents_upload_available,
    },
    { 
        // Info page shown about finals before the finals are actually made available
        path: '/team/rounds',
        title: 'Устные раунды',
        component: () => <InfoPage slug="team-rounds" />,
        resolveHidden: (roles, ctx) => roles.includes("team")
            && ctx?.state?.documents_scores_available
            && !ctx?.state?.finals_available,
    },
    { 
        path: '/team/rounds',
        title: 'Устные раунды',
        component: TeamRoundsPage,
        resolveHidden: (roles, ctx) => roles.includes("team")
            && ctx?.state?.finals_available
            && ctx?.team?.isFinalsParticipant,
    },
    { 
        path: '/team/rounds-broadcast',
        title: 'Трансляции и записи',
        component: () => <InfoPage slug="rounds-broadcast" />,
        resolveHidden: (roles, ctx) => roles.includes("team")
            && ctx?.state?.finals_broadcast_page_available
            && ctx?.team?.isFinalsParticipant,
    },
    { 
        path: '/team/rounds-results',
        title: 'Результаты Конкурса',
        component: () => <FormWrapper>
                <PublicResultsContainer apiPath="/api/admin/finals" kind="player" pageApiPath="/api/page" pageSlug="rounds-results" />
            </FormWrapper>,
        resolveHidden: (roles, ctx) => roles.includes("team") && ctx?.state?.finals_results_available,
    },
    { 
        path: '/team/chat-org',
        title: 'Чат с организатором',
        suffix: <ChatsUnreadIndicator />,
        component: () => <TeamChatWithOrgPage />,
    },
];

export const TeamRoutes: MenuRoute[] = [
];
