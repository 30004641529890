import React, { ReactNode } from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Link, LinkProps, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

export interface MenuItem {
    title: string;
    suffix?: ReactNode;
    path: string;
    icon?: ReactNode;
    alsoActivateForPath?: (path: string) => boolean;
}

export interface MenuProps {
    menuItems: MenuItem[];
    itemAnchorComponent?: React.ComponentType<LinkProps>;
    iconsOnly?: boolean;
}

export const Anchor = styled(Link)`
    text-decoration: none;
    color: inherit;

    &:active {
        color: inherit;
    }
`;

const isMenuItemActive = (currentPath: string, item: MenuItem) => {
    const isSelected = currentPath.replace(/\//g, '') === item.path.replace(/\//g, '');
    const alsoActivated = item.alsoActivateForPath !== undefined && item.alsoActivateForPath(currentPath);
    return isSelected || alsoActivated;
}

export const useActiveMenuItem = (items: MenuItem[]): MenuItem | undefined => {
    const history = useHistory();
    return items.find(i => isMenuItemActive(history.location.pathname, i));
}

export const Menu: React.FC<MenuProps> = (props: MenuProps) => {
    const { menuItems } = props;
    const AnchorComponent = props.itemAnchorComponent || Anchor;
    const history = useHistory();

    return (
        <List>
            {menuItems.map(item => {
                return (
                    <AnchorComponent to={item.path} key={item.path}>
                        <ListItem
                            button
                            selected={isMenuItemActive(history.location.pathname, item)}
                        >
                            {item.icon && <ListItemIcon title={item.title}>{item.icon}</ListItemIcon>}
                            {!props.iconsOnly && <ListItemText primary={<>{item.title}{item.suffix}</>} />}
                        </ListItem>
                    </AnchorComponent>
                )
            })}
        </List>
    )
}