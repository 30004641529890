import { useEffect, useState } from "react";
import { apiFetch } from "../../../toolympus/api/core";
import { Round } from "../../../toolympus/components/Contests/Grid/types";
import { useDialogState } from "../../../toolympus/components/primitives";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData"
import { FieldSchema, FieldType } from "../../../toolympus/hooks/useSchema";
import { Team } from "../../Public/useTeamRegistrationStageOne"

interface Speaker {
    firstname: string;
    lastname: string;
    time?: number;
}

export interface RoundSpeakers {
    speaker1: Partial<Speaker>;
    speaker2: Partial<Speaker>;
    speaker3?: Partial<Speaker>;
}

interface TeamMember {
    lastname: string;
    firstname: string;
}

const EmptySpeakers: RoundSpeakers = {
    speaker1: {},
    speaker2: {},
    speaker3: {},
}


interface Config {
    round: Round;
    position: number;
}

export const useTeamRoundSpeakers = (cfg: Config) => {
    const round = cfg?.round;
    const position = cfg?.position;

    const dialog = useDialogState();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const teamProfile = useLoadedData<Team>("/api/team/my", { } as Team, dialog.isOpen);

    const members = (teamProfile.data.members || []).filter(m => !!m.participates_finals);

    const [speakers, setSpeakers] = useState<RoundSpeakers>(EmptySpeakers);

    useEffect(() => {
        const speakers = (round.info || {}).speakers[position];
        if(speakers && speakers.speaker1) {
            setSpeakers(speakers);
        } else {
            setSpeakers(EmptySpeakers);
        }
    }, [round, position]);

    const updateSpeakerMember = (speaker: keyof RoundSpeakers, member: string) => {
        if(member) {
            const [lastname, firstname] = member.split(":");
            setSpeakers(x => ({ ...x, [speaker]: { ...x[speaker], lastname: lastname, firstname: firstname }}));
        } else {
            setSpeakers(x => ({ ...x, [speaker]: { ...x[speaker], lastname: undefined, firstname: undefined }}));
        }
    }

    const getSpeakerMemberValue = (speaker: keyof RoundSpeakers) => {
        const member = speakers[speaker];
        return member && member.lastname ? `${member.lastname}:${member.firstname}` : undefined;
    }

    const updateSpeakerTime = (speaker: keyof RoundSpeakers, time: number) => {
        if(speaker !== "speaker3") {
            setSpeakers(x => ({ ...x, [speaker]: { ...x[speaker], time }}));
        }
    }

    const isSpeakerTimeValid = (speaker: keyof RoundSpeakers): boolean => {
        if(speaker === "speaker3") {
            return !speakers.speaker3?.time;
        } else {
            const time = speakers[speaker].time || 0;
            return 15 <= time && time <= 30;
        }
    }

    const isTotalTimeValid =
        isSpeakerTimeValid("speaker1")
        && isSpeakerTimeValid("speaker2")
        && isSpeakerTimeValid("speaker3")
        && ((speakers.speaker1.time || 0) + (speakers.speaker2.time || 0) <= 45);

    const isSpeakersSelected = speakers.speaker1.lastname && speakers.speaker2.lastname
        && new Set([`${speakers.speaker1.lastname}:${speakers.speaker1.firstname}`, `${speakers.speaker2.lastname}:${speakers.speaker2.firstname}`, `${speakers.speaker3?.lastname}:${speakers.speaker3?.firstname}`]).size === 3;

    const save = () => {
        if(isTotalTimeValid) {
            // save
            // reload
            setIsLoading(true);
            const toSave = { ...speakers };
            if(!toSave.speaker3?.lastname) {
                delete toSave.speaker3;
            }
            apiFetch(`/api/team/my/finals-participants/${cfg.round._id}`, "put", toSave)
                .then(() => {
                    window.location.reload();
                })
                .catch(e => {
                    setIsLoading(false);
                    throw e;
                });

        }
    }

    const speakerSchema: FieldSchema = {
        type: FieldType.select,
        values: members.map(m => ({ value: `${m.lastname}:${m.firstname}`, label: `${m.firstname} ${m.lastname}`})),
    }

    return {
        round,
        position,
        members,
        speakers,
        hasSpeakers: speakers.speaker1.lastname,

        updateSpeakerMember,
        updateSpeakerTime,
        getSpeakerMemberValue,

        isSpeakerTimeValid,
        isTotalTimeValid,
        isSpeakersSelected,
        save,

        dialog,
        isLoading: isLoading || teamProfile.isLoading,

        speakerSchema,
    }
}

export type TeamRoundSpeakersData = ReturnType<typeof useTeamRoundSpeakers>;
