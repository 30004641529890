import { CrudItemData, useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { Schema, useSchema, mergeSchema } from "../../toolympus/hooks/useSchema";
import { JudgeRegistration, JudgeSchemaChanges } from "../Public/useJudgeRegistration"

export interface JudgeProfile extends CrudItemData<JudgeRegistration> {
    schema: Schema;
}

export const useJudgeProfile = (): JudgeProfile => {
    const data = useCrudItem<JudgeRegistration>('/api/judge/me', {
        defaultValue: {} as JudgeRegistration,
    });
    const { judge: schema } = useSchema();

    return {
        ...data,
        schema: mergeSchema(schema, JudgeSchemaChanges),
    }
}
