import React, { useEffect, useState } from 'react';
import { FieldType } from '../../hooks/useSchema';
import { expandProps, FormControlProps } from './FormControlProps';
import { TextEdit } from './TextEdit';

const displayJson = (v: any) => v ? JSON.stringify(v, null, 2) : "";

export const JsonEdit = (props: FormControlProps) => {
    const { row, value, field, onChange } = expandProps(props);
    const [innerVal, setInnerVal ] = useState<string>("");
    const [hasParseError, setHasParseError] = useState<boolean>(false);

    useEffect(() => {
        setInnerVal(displayJson(value));
    }, [value]);

    const onChangeX = (old: any, changes: any): void => {
        const newVal = changes[field] || "";
        setInnerVal(newVal);
        if(newVal === "") {
            onChange(old, { ...changes, [field]: null});
            setHasParseError(false);
            return;
        }

        try {
            const parsed = JSON.parse(newVal);
            setHasParseError(false);
            onChange(old, { ...changes, [field]: parsed });
        } catch {
            setHasParseError(true);
        }
    }

    return (
        <TextEdit
            {...props}
            schema={{ ...props.schema, type: FieldType.textlong }}
            extraProps={{ autoRows: true, ...props.extraProps, }}
            row={{ ...row, [field]: innerVal }}
            onChange={onChangeX}
            error={hasParseError} />
    );
}

export const JsonDisplay = ({ value }: { value: any }) => <>{displayJson(value)}</>;
