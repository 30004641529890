import React from 'react';
import { Button, Typography, useMediaQuery, useTheme, } from '@material-ui/core';
import { 
    CaptionAndLinksBlock, 
    LeftPane, 
    Link, 
    RegistrationPageWrapper, 
    RegistrationPageRightPane, 
    TailButtons,
    ContactUsText,
} from './Common';
import { FieldDefinition, FormControlsForFields } from '../../toolympus/components/schemed';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { useContestState } from '../../useContestState';
import { PublicInfoPageBySlug } from './PublicInfoPage';
import { useSecretaryRegistration } from './useSecretaryRegistration';



export const SecretaryRegistrationPage = () => {
    const { schema, data, update, canSubmit, submit, errors, isLoading, isSubmitted } = useSecretaryRegistration();

    const { data: contestState } = useContestState();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const controls = (fields: FieldDefinition[]) => (
        <FormControlsForFields
            data={data}
            schema={schema}
            fields={fields}
            onChange={(o,c) => update(c)}
            errors={errors}
            withArea
            />
    );

    if(!contestState.registration_available_secretary) {
        return <PublicInfoPageBySlug slug="team-registration-blocked" />
    }

    return (
        <RegistrationPageWrapper>
            <LeftPane pad="small" />
            <RegistrationPageRightPane>
                <CaptionAndLinksBlock>
                    <Typography variant="h3">регистрация судебных секретарей</Typography>
                    <Typography>Уже зарегистрировались?<br/><Link to="/login">Войдите в личный кабинет</Link></Typography>
                    <Typography>Если Вы хотите зарегистрироваться в качестве судьи,<br/><Link to="/register-judge">заполните другую форму</Link></Typography>
                </CaptionAndLinksBlock>

                {!isSubmitted && <React.Fragment key="registration">
                    <FormGrid
                        style={{ alignItems: 'flex-start'}}
                        columns={Array(isMobile ? 1 : 6).fill("1fr").join(" ")}
                        areas={isMobile ? [
                                "lastname",
                                "firstname",
                                "middlename",
                                "university",
                                "affiliation",
                                "experience_secretary",
                                ".",
                                "phone",
                                "email",
                                "password",
                                "passwordRepeat",
                                "personal_data_consent",
                            ]
                            : [
                            "lastname lastname firstname firstname middlename middlename",
                            Array(6).fill("university").join(" "),
                            Array(6).fill("affiliation").join(" "),
                            Array(6).fill("experience_secretary").join(" "),
                            Array(6).fill(".").join(" "),
                            `${Array(3).fill("email").join(" ")} ${Array(3).fill("phone").join(" ")}`,
                            `${Array(3).fill("password").join(" ")} ${Array(3).fill("passwordRepeat").join(" ")}`,
                            Array(6).fill("personal_data_consent").join(" "),
                        ]}>
                        {controls([
                            ["lastname"],
                            ["firstname"],
                            ["middlename"],
                            ["university"],
                            ["affiliation"],
                            ["experience_secretary"],
                            ["email", { hint: "Для входа в систему и уведомлений" }],
                            ["phone"],
                            ["password", { hint: "От 6 символов. Должен включать латинские буквы, цифры и символы" }],
                            ["passwordRepeat", { hint: "Чтобы не было ошибок" }],
                            ["personal_data_consent"],
                        ])}
                    </FormGrid>

                    <ContactUsText />

                    <TailButtons>
                        <div />

                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            onClick={submit}
                            disabled={!canSubmit || isLoading}
                            style={{ gridArea: 'submit'}}>
                            подать заявку
                            {isLoading && <LoadingIndicator sizeVariant="s" />}
                        </Button>
                    </TailButtons>
                </React.Fragment>}

                {isSubmitted && <React.Fragment key="after-registration">
                    <Typography style={{ fontStyle: 'italic' }}>Ваша заявка отправлена. Мы свяжемся с Вами по E-mail. Если у Вас возникнут вопросы, пожалуйста, напишите нам на <a href="mailto:mootcourt@constlawcenter.ru">mootcourt@constlawcenter.ru</a></Typography>
                    <div key="counterweight" />
                    </React.Fragment>}

            </RegistrationPageRightPane>
        </RegistrationPageWrapper>
    );
}
