import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
    useHistory,
} from "react-router-dom";
import Logo from './img/logo_white_no_text.png';
import { clearApiToken, decodeToken, getApiToken } from './toolympus/api/core';
import { AppWrapper, Sidebar, Menu, Anchor, DrawerPaperView, useActiveMenuItem, Routes, MenuRoute } from './toolympus/components/frame/new/';
import { checkRole, DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { PartnersBlock } from './components/Public/PartnersBlock';
import { AppContent } from './AppContent';
import { 
    CommonRoutes, 
    AdminRoutes, 
    TeamRoutes, 
    TeamApplicationRoutes, 
    JudgeRoutes, 
    JudgeApplicationRoutes,
} from './Routes';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { ChatsProvider } from './components/Chats/ChatsProvider';
import { useContestState } from './useContestState';
import { ConfirmationDialogProvider } from './toolympus/components/primitives/ConfirmationDialog';
import { SecretaryRoutes } from './Routes/SecretaryRoutes';
import { useTeamProfileStatus } from './components/Team/useTeamProfile';


const LogoImg = styled.img`
    width: 100%;
    box-sizing: border-box;
    padding: 20px 25px;
`;
LogoImg.defaultProps = { src: Logo, alt: 'Хрустальная Фемида' };

const MenuAnchor = styled(Anchor)`
    & .MuiListItemText-root span {
        font-weight: 300;
    }

    & .MuiListItem-root.Mui-selected,
    & .MuiListItem-root.Mui-selected:hover {
        background-color: rgba(255, 255, 255, 0.15);
        border-left: 2px solid #ffffff;
    }

    & .MuiListItem-gutters {
        padding-left: 30px;
        padding-right: 30px;
    }
`;

const DrawerPaperViewShadow = styled(DrawerPaperView)`
    & > .MuiPaper-root {
        box-shadow: 0px 0px 10px 3px rgba(43, 79, 112, 0.25);
        z-index: 400;
    }

    
    & ul::-webkit-scrollbar {
        width: 2px;
        background-color: #dadada;
    }

    & ul::-webkit-scrollbar-thumb {
        background-color: #efefef;
    }
`;

const PartnersWrapper = styled.div`
    width: 100%;
    height: 100px;
    padding: 16px 30px;
    margin-bottom: 44px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.85);
    overflow: hidden;
`;

const AppRouter: React.FC = () => {
    const token = getApiToken();
    const history = useHistory();
    const [user, setUser] = useState<DefaultUser | null>(token ? decodeToken(token) as DefaultUser : { _id: '', roles: [], email: '' });
    const userContext = UserContext;

    useLoginGuard('/login', {});

    const logout = () => {
        clearApiToken();
        setUser(null);
        history.push('/login');
    }

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    const contestState = useContestState();
    const teamProfile = useTeamProfileStatus(user);

    const routesCtx = { state: contestState.data, team: teamProfile };

    const resolveRouteHidden = (r: MenuRoute) =>  r.resolveHidden === undefined || r.resolveHidden(user?.roles || [], routesCtx);


    const routesToRender = [
        ...CommonRoutes,
        ...(checkRole(user, "admin") ? AdminRoutes.filter(resolveRouteHidden) : []),
        ...(checkRole(user, "team-application") ? TeamApplicationRoutes.filter(resolveRouteHidden) : []),
        ...(checkRole(user, "team") ? TeamRoutes.filter(resolveRouteHidden) : []),
        ...(checkRole(user, "judge-application") ? JudgeApplicationRoutes.filter(resolveRouteHidden) : []),
        ...(checkRole(user, "judge") ? JudgeRoutes.filter(resolveRouteHidden) : []),
        ...(checkRole(user, "secretary") ? SecretaryRoutes.filter(resolveRouteHidden) : []),
    ];

    const menuItems = routesToRender
        .filter(r => !r.hidden && r.title)
        .map(({ title, suffix, path, alsoActivateForPath }) => ({
            title: title || "",
            suffix, 
            path,
            alsoActivateForPath,
        }));

    const top = (<LogoImg />);
    const menu = (<Menu menuItems={menuItems} itemAnchorComponent={MenuAnchor} />);
    const bottom = (
        <PartnersWrapper>
            <PartnersBlock pageSize={1} />
        </PartnersWrapper>);

    const activeMenuItem = useActiveMenuItem(menuItems);

    return (
        <userContext.Provider value={{ user: user, setUser: setUser }}>
            <ChatsProvider>
                <AppWrapper limitHeight>
                    <Sidebar
                        width={300}
                        top={top}
                        menu={menu}
                        bottom={bottom}
                        open={isMobileMenuOpen || isDesktop}
                        onClose={() => setIsMobileMenuOpen(false)}
                        drawerPaperComponent={DrawerPaperViewShadow}
                    />
                    <AppContent logout={logout} title={activeMenuItem?.title} toggleMenu={() => setIsMobileMenuOpen(v => !v)}>
                        <ConfirmationDialogProvider>
                            <Routes routes={routesToRender} />
                        </ConfirmationDialogProvider>
                    </AppContent>
                </AppWrapper>
            </ChatsProvider>
        </userContext.Provider>
    );
}

export default AppRouter;

