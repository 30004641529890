import React from 'react';
import { createMuiTheme, withTheme } from '@material-ui/core/styles';
import { css, Global } from '@emotion/react';

export const theme = createMuiTheme({
    typography: {
        fontFamily: "Montserrat",
    },
    palette: {
        primary: {
            main: '#2B4F70',
        },
        secondary: {
            main: '#5B1520',
        },
        text: {
            primary: '#0B2C4A',
            // secondary: '',
            // disabled: '',
            // hint: '',
        },
    },

    props: {
        MuiFormControl: {
        }
    }
});

export const GlobalCSS = (props: any) => css`
    body {
        color: ${(props.theme as any).palette.text.primary};
    }

    a {
        color: ${(props.theme as any).palette.secondary.main};
        text-decoration: none;
        font-weight: 600;
    }

    strong {
        font-weight: 600;
    }

    .rounds-container .round, .party-stage-rounds .round {
        &:nth-child(6n+6), &:nth-child(6n+5) {
            margin-bottom: 72px;
        }

        @media(max-width: 600px) {
            &:nth-child(6n+6) {
                margin-bottom: 0;
            }   
        }
    }
`;

export const GlobalStyles = withTheme((props: any) => <Global styles={GlobalCSS(props)} />);