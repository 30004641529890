import React from 'react';
import { RoundPlayerInfoProps } from '../../../toolympus/components/Contests/Grid';
import { Round } from '../../../toolympus/components/Contests/Grid/types';
import { LoadingIndicator } from '../../../toolympus/components/primitives';
import { useLoadedData } from '../../../toolympus/hooks/useLoadedData';
import { RoundSpeakersDisplay } from './TeamRoundsPage';

export const AdminRoundPopupTeamDetails = (props: RoundPlayerInfoProps) => {
    const { round, position } = props;

    const roundDetails = useLoadedData<Round>(`/api/admin/finals/stage/${round.stage_code}/round/${round._id}`, {} as Round);

    const speakers = (roundDetails.data?.info?.speakers || {})[position];

    return roundDetails.isLoading
        ? <LoadingIndicator sizeVariant="s" />
        : speakers?.speaker1?.lastname
            ? <RoundSpeakersDisplay speakers={speakers} />
            : <p>участники не выбраны</p>;
}
