import React, { useState } from 'react';
import { Button, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import { VisibilityOutlined } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { useItemWithControls } from '../../../api/useWithControls';
import { ActionRow, OccupyFreeSpace } from '../../primitives/ActionRow';
import { SaveButton } from '../../primitives/Buttons';
import { Form, FormGrid } from '../../primitives/Forms';
import { LoadingIndicator } from '../../primitives/LoadingIndicator';
import { ScoringSheetEditData, useScoringSheetEdit } from './useScoringSheetEdit';
import { CriteriaEdit } from './CriteriaEdit';
import { useMockScoring } from './useScoring';
import { ScoringComponentMapping, ScoringSheetForm } from './ScoringSheetForm';
import { Tooltip } from '../../primitives/Tooltip';

interface Props {
    data: ScoringSheetEditData;
    customScoringComponents?: ScoringComponentMapping;
}

export const ScoringSheetEdit = (props: Props) => {
    const {
        data,
        hasChanges,
        isLoading,
        schema,
        save,

        addCriteria,
    } = props.data;

    const { controls } = useItemWithControls(props.data, { schema });

    const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
    const sheetPreview = useMockScoring(data);

    return (
        <Form
            title={<FormattedMessage id="contests.scoring.sheet_edit_title" values={{ title: data.title }} />}
            headerItems={<>
                <OccupyFreeSpace />
                <Tooltip text_id="contests.scoring.sheet_preview">
                    <IconButton size="small" onClick={() => setIsPreviewOpen(true)}>
                        <VisibilityOutlined />
                    </IconButton>
                </Tooltip>
                {isLoading && <LoadingIndicator />}
                {hasChanges && <SaveButton action={() => save()} />}
            </>}
            >
            
            <FormGrid columns="1fr 2fr">
                {controls([
                    ["code", { disabled: true }],
                    ["title"],
                    ["description", { wrapperStyle: { gridColumnStart: 'span 2' }}],
                ])}
            </FormGrid>

            <ActionRow itemMarginTop="1rem">
                <Typography variant="h6"><FormattedMessage id="contests.scoring.fields.sheet.criteria" /></Typography>
                <Button size="small" onClick={() => addCriteria()}><FormattedMessage id="common.add" /></Button>
            </ActionRow>

            {(data.criteria || []).map((c,idx) => <CriteriaEdit key={`${c._id}${idx}`} idx={idx} data={props.data} />)}

            <ActionRow itemMarginTop="1rem">
                <OccupyFreeSpace />
                <Button size="small" onClick={() => addCriteria()}><FormattedMessage id="common.add" /></Button>
            </ActionRow>

            <Dialog open={isPreviewOpen} onClose={() => setIsPreviewOpen(false)} fullWidth maxWidth="md">
                <DialogContent>
                    <ScoringSheetForm
                        data={sheetPreview}
                        customComponents={props.customScoringComponents}
                        onCancel={() => setIsPreviewOpen(false)} />
                </DialogContent>
            </Dialog>
        </Form>
    );
}

interface ContainerProps extends Pick<Props, 'customScoringComponents'> {
    apiPath: string;
}

export const ScoringSheetEditContainer = (props: ContainerProps) => {
    const { code } = useParams<{ code: string }>();
    const data = useScoringSheetEdit(props.apiPath, code);

    return <ScoringSheetEdit data={data} {...props} />
}
