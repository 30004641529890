import React from 'react';
import { AssignmentReturnedOutlined } from '@material-ui/icons';
import { useAdminCaseQuestions } from './useAdminCaseQuestions';
import { useSchema } from '../../toolympus/hooks/useSchema';
import { Form } from '../../toolympus/components/primitives/Forms';
import { IconButton } from '@material-ui/core';
import { OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { TableForFields } from '../../toolympus/components/schemed';


export const CaseQuestionsListPage = () => {
    const { data, isLoading, exportQuestions } = useAdminCaseQuestions();
    const { case_question: schema } = useSchema();

    return (
        <Form
            headerItems={
                <>
                    {isLoading && <LoadingIndicator sizeVariant="s" />}
                    <OccupyFreeSpace />
                    
                    <IconButton size="small" onClick={exportQuestions}>
                        <AssignmentReturnedOutlined />
                    </IconButton>
                </>
            }>
            <TableForFields
                schema={schema}
                data={data}
                fields={[
                    ["author_name"],
                    ["created_datetime", { utcToLocal: true }],
                    ["question"],
                ]}
                />
        </Form>
    );
}
