import { apiFetch, downloadFile, FetchTypes } from "../../toolympus/api/core";
import { useTableComments } from "../../toolympus/components/Comments";
import { RegistrationsData } from "../../toolympus/components/Contests/Registrations";
import { useRegistrationsTabs } from "../../toolympus/components/Contests/Registrations/useRegistrationsTabs";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { FieldType, mergeSchema, useSchema } from "../../toolympus/hooks/useSchema";
import { Secretary } from "../Public/useSecretaryRegistration";
import { SecretaryReview, useSecretaryReview } from "./useSecretaryReview";

export interface SecretaryListData extends RegistrationsData<Secretary> {
    review: SecretaryReview;
}

const ApiPath = '/api/admin/secretary';

export const useSecretaryList = (): SecretaryListData => {
    const data = useLoadedData<Secretary[]>(ApiPath, []);
    const { secretary: secretarySchema } = useSchema();
    const schema = mergeSchema(secretarySchema, {
        rounds: {
            type:  FieldType.dictionarySelectMulti,
            dictionary: "rounds",
            label: "Раунды",
        }
    })

    const filter = useTextFilter<Secretary>(s => `${s.lastname} ${s.firstname} ${s.email} ${s.university} ${s.affiliation}`, "", { exactFields: ["_id"] });
    const comments = useTableComments("/api/comment", "secretary", { isRemoveAllowed: () => false });

    const approval = {
        isRegistrationApproved: (s: Secretary) => !!s.approved,
        update: (s: Secretary, approve: boolean, comment?: string) => {
            return apiFetch<Secretary>(`${ApiPath}/${s._id}/approve`, FetchTypes.PUT, { approved: !!approve })
                .then(t => {
                    data.reload();
                    return t;
                })
        }
    };

    const tabs = useRegistrationsTabs({ approval, queryParam: "tab" });

    const remove = (s: Secretary) => apiFetch(`${ApiPath}/${s._id}`, "delete").then(() => data.reload());

    const exportData = () => downloadFile(`${ApiPath}/export`, "секретари.xlsx");

    const review = useSecretaryReview();


    return {
        ...data,
        data: filter.filterData(data.data.filter(t => tabs.activeFilter(t))),

        remove,
        approval,
        exportData,

        ...filter,
        schema,
        comments,
        tabs,

        review: {
            ...review,
            schema,
        },
    }
}
