import React from 'react';
import { Button, Typography, useMediaQuery, useTheme, } from '@material-ui/core';
import { 
    CaptionAndLinksBlock, 
    LeftPane, 
    Link, 
    RegistrationPageWrapper, 
    RegistrationPageRightPane, 
    TailButtons,
    ContactUsText,
} from './Common';
import { useTeamRegistrationStageOne } from './useTeamRegistrationStageOne';
import { MaybeFieldDefinition, FormControlsForFields } from '../../toolympus/components/schemed';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { useContestState } from '../../useContestState';
import { PublicInfoPageBySlug } from './PublicInfoPage';
import { DictionariesProvider } from '../../toolympus/hooks/useDictionaries';

export const TeamRegistrationPageUnwrapped = () => {
    const { schema, data, update, canSubmit, submit, errors, isLoading, isOtherUniversity } = useTeamRegistrationStageOne();

    const { data: contestState } = useContestState();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const controls = (fields: MaybeFieldDefinition[]) => (
        <FormControlsForFields
            data={data}
            schema={schema}
            fields={fields}
            onChange={(o,c) => update(c)}
            errors={errors}
            withArea
            />
    );

    if(!contestState.registration_available) {
        return <PublicInfoPageBySlug slug="team-registration-blocked" />
    }

    return (
        <RegistrationPageWrapper>
            <LeftPane pad="small" />
            <RegistrationPageRightPane>
                <CaptionAndLinksBlock>
                    <Typography variant="h3">регистрация команд</Typography>
                    <Typography>Уже зарегистрировались?<br/><Link to="/login">Войдите в личный кабинет</Link></Typography>
                    <Typography>Если Вы хотите зарегистрироваться в качестве судьи,<br/><Link to="/register-judge">заполните другую форму</Link></Typography>
                </CaptionAndLinksBlock>

                <FormGrid
                    style={{ alignItems: 'flex-start'}}
                    columns={isMobile ? "1fr" : "1fr 1fr"}
                    areas={isMobile ? [
                        "university_bound",
                        isOtherUniversity ? "university" : null,
                        "department",
                        "city",
                        "email",
                        "password",
                        "passwordRepeat",
                        "empty3",
                        "finale_consent",
                        "personal_data_consent",
                        "photos_consent",
                        ]
                        : [
                        "university_bound university_bound",
                        isOtherUniversity ? "university university" : null,
                        "department city",
                        "email empty1",
                        "password passwordRepeat",
                        "empty3 empty3",
                        "finale_consent finale_consent",
                        "personal_data_consent personal_data_consent",
                        "photos_consent photos_consent",
                    ]}>
                    {controls([
                        ["university_bound"],
                        isOtherUniversity ? ["university"] : null,
                        ["department"],
                        ["city"],
                        ["email", { hint: "Для входа в систему и уведомлений" }],
                        ["password", { hint: "От 6 символов. Должен включать латинские буквы, цифры и символы" }],
                        ["passwordRepeat", { hint: "Чтобы не было ошибок" }],
                        ["personal_data_consent"],
                    ])}
                    <div style={{ gridArea: 'empty3', height: "20px" }} />
                </FormGrid>

                <ContactUsText />

                <TailButtons>
                    <div />

                    <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        onClick={submit}
                        disabled={!canSubmit || isLoading}
                        style={{ gridArea: 'submit'}}>
                        продолжить регистрацию
                        {isLoading && <LoadingIndicator sizeVariant="s" />}
                    </Button>
                </TailButtons>

            </RegistrationPageRightPane>
        </RegistrationPageWrapper>
    );
}

export const TeamRegistrationPage = () => {
    return (
        <DictionariesProvider apiPath="/api/dictionaries">
            <TeamRegistrationPageUnwrapped />
        </DictionariesProvider>
    )
}
