import { PartyRounds, usePartyRounds } from "./usePartyRounds";

export interface ArbiterRounds extends PartyRounds {

}

export const useArbiterRounds = (apiPath: string): ArbiterRounds => {
    const data = usePartyRounds(apiPath, 'arbiter')

    return {
        ...data,
    }
};
