import React from 'react';
import { Button, List, ListItem, ListItemText, ListItemSecondaryAction, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { FormWrapper } from '../../toolympus/components/primitives/Forms';
import { useJudgeDocuments } from './useJudgeDocuments';
import { DocumentNames } from '../Team/useTeamDocuments';
import { PageContainer } from '../../toolympus/components/Pages';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { Link } from 'react-router-dom';
import { PseudoLink } from '../../toolympus/components/primitives/Common.styles';
import { DocumentLinks } from '../../toolympus/components/Pages/DocumentLinks';


const Documents = () => {
    const data = useJudgeDocuments();

    if(data.isLoading) {
        return <LoadingIndicator />;
    }

    if(data.data.length === 0) {
        return <Typography style={{ fontStyle: 'italic' }}>
            Вам пока не назначены документы для проверки.
        </Typography>;
    }

    return (
        <List style={{ maxWidth: "500px" }}>
            {data.data.map(d => (
                <ListItem key={d._id}>
                    <ListItemText
                        primary={`${DocumentNames[d.document_kind as 'doc1' | 'doc2']}, команда ${d.team_number}`}
                        secondary={<PseudoLink onClick={() => data.download(d)}>скачать</PseudoLink>}
                        />
                    <ListItemSecondaryAction>
                        <Link to={`/judge/documents/${d._id}`}>
                            {d.is_scored ?
                                <Button color="primary" style={{ background: '#00aa00'}} variant="contained" endIcon={<Check />}>оценен</Button>
                                : <Button color="primary" variant="outlined">оценить</Button>} 
                        </Link>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );
}

export const JudgeDocumentsPage = () => {
    return (
        <FormWrapper>
            <PageContainer apiPath="/api/page" slug="judge-documents" customComponents={{ documents: () => <Documents />, filelinks: DocumentLinks }}  />
        </FormWrapper>
    );
}
