import React, { ReactNode } from 'react';
import { Button, IconButton, Typography } from '@material-ui/core';
import { AssignmentReturnedOutlined, EditOutlined } from '@material-ui/icons';
import { ActionRow, OccupyFreeSpace } from '../../primitives/ActionRow';
import { Form } from '../../primitives/Forms';
import { LoadingIndicator } from '../../primitives/LoadingIndicator';
import { SearchField } from '../../primitives/SearchField';
import { FieldSettingsPopupButton, TableForFields, useFields, FieldSettingsSettings, TableRowButtons } from '../../schemed';
import { FieldSchema } from '../../../hooks/useSchema';
import { FormattedMessage } from 'react-intl';
import { TabsHeader } from '../../primitives/Tabs';
import { Registration, RegistrationsData } from './types';
import { DeleteButton } from '../../primitives/DeleteButton';
import { OpenRegistrationReviewData } from '.';
import { CopyText } from '../../primitives/CopyText';


interface Props<T extends Registration> {
    data: RegistrationsData<T>;
    fields: FieldSettingsSettings;
    fieldElement?: (field: string) => ((row: T, schema?: FieldSchema, original?: ReactNode) => (JSX.Element | string)) | null | undefined;
    title: ReactNode;
    totalRow?: { hide?: boolean, label?: ReactNode };
    tagsLabel?: string;
    autoexpander?: { initialRows: number, increment: number } | "disabled";
    noRemove?: boolean;
    noEdit?: boolean;
    itemDescription?: (r: T) => string;
    review?: OpenRegistrationReviewData<T>;
    extraHeaderActions?: ReactNode
}

const TagsField = "__tags";
const CommentsField = "__comments";
const ApprovalField = "__approval";
const ActionsField = "__actions";

export const RegistrationsListPage = <T extends Registration>(props: Props<T>) => {
    const data = props.data;

    const fieldSettings = {
        ...props.fields,
        outOfSchemaFields: [
            ...(props.fields.outOfSchemaFields || []),
            ...(data.tags ? [TagsField] : []),
            ...(data.comments ? [CommentsField] : []),
            ...(data.approval ? [ApprovalField] : []),
            ...(data.remove && !props.noRemove ? [ActionsField] : []),
        ],
        extraSettings: {
            ...(props.fields.extraSettings || {}),
            [TagsField]: { label: props.tagsLabel || <FormattedMessage id="tags.labels.label_plural" /> },
            [CommentsField]: { label: <FormattedMessage id="comments.title" /> },
            [ApprovalField]: { label: <FormattedMessage id="contests.registrations.labels.approval" /> },
            [ActionsField]: { label: <FormattedMessage id="contests.registrations.labels.actions" /> },
        }
    };

    const fields = useFields(fieldSettings);

    const fieldElement = (f: string) => {
        const fromProps = props.fieldElement && props.fieldElement(f);
        if(fromProps) {
            return fromProps;
        } else {
            switch(f) {
                case TagsField:
                    return data.tags && ((r: T) => <>{data.tags?.displayForRecord(r._id)}</>);
                case CommentsField:
                    return data.comments && ((r: T) => <TableRowButtons>{data.comments?.buttonForRecord(r._id)}</TableRowButtons>)
                case ApprovalField:
                    return data.approval && ((r: T) => {
                        const isApproved = data.approval?.isRegistrationApproved(r);
                        return (<Button
                            size="small"
                            color="primary"
                            variant={isApproved ? "contained" : "outlined"}
                            onClick={() => data.approval?.update(r, !isApproved)}
                            >
                            {isApproved ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />}
                        </Button>
                        )});
                case ActionsField:
                    return (r: T) => (
                        <TableRowButtons>
                            {props.review && !props.noEdit && (
                                <IconButton size="small" onClick={() => props.review?.open(r._id)}>
                                    <EditOutlined />
                                </IconButton>
                            )}
                            {data.remove && !props.noRemove && (
                                <DeleteButton
                                    size="small"
                                    remove={() => data.remove ? data.remove(r) : Promise.resolve({})}
                                    title={<><FormattedMessage id="common.delete"/>?</>}
                                    confirmationText="delete"
                                    hint={<FormattedMessage id="contests.registrations.remove_hint" values={{ description: props.itemDescription ? props.itemDescription(r) : r.email }} />}
                                    preventGoBack
                                />)}
                        </TableRowButtons>);
                case "_id":
                    return (r: T) => <CopyText value={r._id} />;
                case "email":
                    return (r: T) => <CopyText value={r.email} />;
            }
        }
    }

    return (
        <Form
            title={props.title}
            formPaperProps={{ elevation: 0 }}
            fitFullHeight
            headerItems={<ActionRow itemMarginTop="0" alignItems="baseline">
                {!props.totalRow?.hide && <Typography variant="caption">{props.totalRow?.label || <FormattedMessage id="contests.registrations.total" />} {data.data.length}</Typography>}
                <OccupyFreeSpace />
                {data.isLoading && <LoadingIndicator />}
                <SearchField filter={data.filter} setFilter={data.setFilter} autoFocus noButton />
                {props.extraHeaderActions}
                {data.exportData && <IconButton size="small" onClick={() => data.exportData && data.exportData()}>
                    <AssignmentReturnedOutlined />
                    </IconButton>}
                <FieldSettingsPopupButton fieldsSettings={fields} />
            </ActionRow>}
        >
            {data.tabs && !!data.tabs.tabs.length && <TabsHeader state={data.tabs} scrollable noMargin />}
            <TableForFields
                data={data.data}
                schema={data.schema}
                fields={fields.activeFields}
                fieldElement={fieldElement}
                autoexpander={props.autoexpander === "disabled" ? undefined : props.autoexpander || { initialRows: 20, increment: 20 }}
                onDblClick={props.review ? (r => props.review?.open(r._id)) : undefined}
                />
            
            {data.tags && data.tags.popup}
            {data.comments && data.comments.popup}

        </Form>
    );
}
