import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { apiFetch } from '../../../toolympus/api/core';
import { useActionWithConfirmation } from '../../../toolympus/api/useAction';
import { SnapshotsContainer } from '../../../toolympus/components/Snapshots/SnapshotsContainer';

interface Props {
    
}

export const ArchivesManagement = (props: Props) => {
    const cleanup = () => apiFetch("/api/snapshot/clean", "post");

    const cleanupAction = useActionWithConfirmation(cleanup, {
        confirmationText: "очистить",
        confirmationHint: <>
            <Typography key="l1">База данных будет очищена для запуска новой итерации конкурса.</Typography>
            <Typography key="l2">Это действие необратимо. Чтобы продолжить введите слово 'очистить' в поле ниже и нажмите кнопку.</Typography>
            </>,
        title: "Очистить базу данных",
        confirmationLabel: "я понимаю последствия, очистить",

    });

    return <SnapshotsContainer
        apiPath="/api/snapshot"
        extraActions={<Button color="secondary" onClick={cleanupAction.run} disabled={!cleanupAction.canRun}>очиситить базу данных</Button>}
        />;
}
