import React from 'react';
import { Rating, RatingTable } from "../../../toolympus/components/Contests/Grid/RatingTable"
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData"

interface Props {
    isActive?: boolean;
}

export const Doc1Rating = ({ isActive }: Props) => {
    const data = useLoadedData<Rating[]>("/api/admin/rating/document/doc1", [], isActive);
    return <RatingTable data={data} noDetails />
}

export const Doc2Rating = ({ isActive }: Props) => {
    const data = useLoadedData<Rating[]>("/api/admin/rating/document/doc2", [], isActive);
    return <RatingTable data={data} noDetails />
}

export const CriteriaSPMRating = ({ isActive }: Props) => {
    const data = useLoadedData<Rating[]>("/api/admin/rating/document-criteria/3", [], isActive);
    return <RatingTable data={data} noDetails />
}

export const CriteriaKSRFRating = ({ isActive }: Props) => {
    const data = useLoadedData<Rating[]>("/api/admin/rating/document-criteria/4", [], isActive);
    return <RatingTable data={data} noDetails />
}

export const CriteriaESPCRating = ({ isActive }: Props) => {
    const data = useLoadedData<Rating[]>("/api/admin/rating/document-criteria/5", [], isActive);
    return <RatingTable data={data} noDetails />
}

export const CriteriaLangRating = ({ isActive }: Props) => {
    const data = useLoadedData<Rating[]>("/api/admin/rating/document-criteria/7", [], isActive);
    return <RatingTable data={data} noDetails />
}

