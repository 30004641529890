import React from 'react';
import styled from '@emotion/styled';
import { ArrowBack, GetApp } from '@material-ui/icons';
import { FormWrapper } from '../../toolympus/components/primitives/Forms';
import { useJudgeDocumentScoring } from './useJudgeDocuments';
import { PageContainer } from '../../toolympus/components/Pages';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { Button, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { DocumentNames } from '../Team/useTeamDocuments';
import { ScoringSheetForm } from '../../toolympus/components/Contests/ScoringSheets';


const Caption = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-flow: row;
    gap: 2rem;
`;

const Score = () => {
    const { id } = useParams<{ id: string }>();
    const { isLoading, document, download, scoring } = useJudgeDocumentScoring(id);
    const history = useHistory();

    if(isLoading || !document) {
        return <LoadingIndicator />;
    }

    return (<>
        <Caption>
            <Typography variant="h6">{DocumentNames[document.document_kind as 'doc1' | 'doc2']}, команда {document.team_number}</Typography>
            <Button color="primary" size="small" endIcon={<GetApp />} onClick={download}>скачать</Button>
        </Caption>
        <ScoringSheetForm data={scoring} hideTitle onSubmit={() => history.push('/judge/documents')} />
    </>);
}

export const JudgeScoreDocumentPage = () => {
    return (
        <FormWrapper>
            <Link to="/judge/documents">
                <Button color="primary" size="small" startIcon={<ArrowBack />}>назад, к списку документов</Button>
            </Link>
            <PageContainer apiPath="/api/page" slug="judge-assess" customComponents={{ scoring: () => <Score /> }}  />
        </FormWrapper>
    );
}
