import React from 'react';
import styled from '@emotion/styled/macro';
import { Typography, withTheme, } from '@material-ui/core';
import LogoImg from '../../img/logo.svg';
import Backdrop from '../../img/backdrop.png';
import { MootDescription } from './MootDescription';
import { Link as LinkRouter } from 'react-router-dom';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { PartnersBlock } from './PartnersBlock';

export const Logo = withTheme(styled.img`
    width: 100%;
    box-sizing: border-box;
    height: 180px;
    width: auto;
    /* padding: 2rem 1.5rem; */

    ${props => props.theme.breakpoints.down('md')} {
        margin-bottom: 24px;
    }
`);

Logo.defaultProps = { src: LogoImg, alt: 'Хрустальная Фемида' };

interface LeftPaneItemProps {
    pad?: 'small' | 'normal';
}

const LoginUpperPadding = 100;
const RegistrationUpperPadding = 50;
const LeftLowerPadding = 50;

const Left = withTheme(styled.div<LeftPaneItemProps>`
    background: url(${Backdrop});
    background-position: 65% center;
    background-size: auto 100%;

    padding: ${props => props.pad === 'small' ? RegistrationUpperPadding : LoginUpperPadding}px 0 ${LeftLowerPadding}px 0;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    overflow-x: hidden;

    ${props => props.theme.breakpoints.down('md')} {
        padding: 20px 0 ${LeftLowerPadding}px 0;
    }
`);

const Texts = withTheme(styled.div<LeftPaneItemProps>`
    color: #ffffff;
    padding: 0 ${props => props.pad === 'small' ? 60 : 150}px ${LeftLowerPadding}px;
    flex: 1 0 auto;

    ${props => props.theme.breakpoints.down('md')} {
        padding: 0 20px ${LeftLowerPadding}px;
    }
`);

export const Link = withTheme(styled(LinkRouter)`
    color: ${props => props.theme.palette.secondary.main};
    text-decoration: none;
    font-weight: 500;
    font-style: italic;
`);

interface Props extends LeftPaneItemProps {
}


const PartnersWrapper = styled.div<LeftPaneItemProps>`
    width: 100%;
    height: 180px;
    min-height: 180px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.85);
    padding: 24px ${props => props.pad === 'small' ? 60 : 150}px;
    overflow: hidden;
`;

export const LeftPane = (props: Props) => {
    return (
        <Left {...props}>
            <Texts {...props}>
                <MootDescription />
            </Texts>
            <PartnersWrapper {...props}>
                <PartnersBlock />
            </PartnersWrapper>
        </Left>
    );
}

export const RightPane = withTheme(styled.div`    
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;

    padding: ${LoginUpperPadding}px 45px ${LeftLowerPadding}px;
    box-shadow: 0px 0px 10px 3px rgba(43, 79, 112, 0.25);

    
    & > form {
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;

        & > * {
            width: 100%;
        }
    }

    & ${FormGrid} {
        width: 100%;
    }

    ${props => props.theme.breakpoints.down('md')} {
        justify-content: flex-start;
        padding: ${RegistrationUpperPadding}px 20px ${LeftLowerPadding}px;

        & > * {
            max-width: 500px;
        }
    }
`);

export const RegistrationPageRightPane = withTheme(styled(RightPane)`
    padding: ${RegistrationUpperPadding}px 60px 50px;
    overflow-y: auto;

    & ${FormGrid} > span {
        margin-top: 16px;
        margin-bottom: -8px;
    }

    ${props => props.theme.breakpoints.down('md')} {
        padding: ${RegistrationUpperPadding}px 20px ${LeftLowerPadding}px;

        & > * {
            margin-top: 16px;
        }
    }
`);

export const RegistrationPageWrapper = withTheme(styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;

    ${props => props.theme.breakpoints.down("md")} {
        height: auto;
        max-height: max-content;
        overflow: auto;

        display: flex;
        flex-flow: column-reverse;
    }
`);

export const TailButtons = withTheme(styled.div`
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
`);

const CaptionAndLinksGap = 8;

export const CaptionAndLinksBlock = withTheme(styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    font-weight: 300;
    color: ${props => props.theme.palette.primary.main};
    
    & h3 {
        text-transform: uppercase;
        text-align: center;
        font-weight: 300;
        font-size: 24px;
        line-height: 26px;
        /* identical to box height, or 108% */

        letter-spacing: 0.75px;
        color: ${props => props.theme.palette.text.primary};

        margin-bottom: ${CaptionAndLinksGap}px;

        & :not(:first-child) {
            margin-top: ${CaptionAndLinksGap}px;
        }
    }

    & p {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.75px;
        
        & :not(:last-child) {
            margin-bottom: ${CaptionAndLinksGap}px;
        }
    }

    & a {
        color: ${props => props.theme.palette.secondary.main};
        text-decoration: none;
        font-weight: 600;
        font-style: italic;
    }
`);

export const ContactUsText = ({ respect }: { respect?: boolean}) => {
    return <Typography style={{ fontStyle: 'italic' }}>Если у {respect ? "Вас" : "вас"} возникнут трудности при регистрации, пожалуйста, напишите нам на <a href="mailto:mootcourt@constlawcenter.ru">mootcourt@constlawcenter.ru</a></Typography>;
}
