import { useTableComments, TableComments as TC, CommentsDataWithButton, useCommentsWithButton } from "../../toolympus/components/Comments";
import { useUserManagement } from "../../toolympus/components/userManagement";

export type TableComments = TC;
export type FormComments = CommentsDataWithButton;


const useUsernames = () => {
    const users = useUserManagement('/api/user', '');
    return (id: string) => (users.users.find(u => u._id === id) as unknown as { email: string } || { email: ""}).email;
}

const apiPrefix = '/api/comment';

export const useListComments = (entity: string): TC => {
    const getUserName = useUsernames();
    return useTableComments(apiPrefix, entity, {
        getUserName,
    });
}

export const useFormComments = (entity: string, record: string | null): FormComments => {
    const getUserName = useUsernames();
    return useCommentsWithButton(apiPrefix, entity, record || "", {
        getUserName,
        suppressLoad: !record,
    });
}
