import { useState } from "react";
import { apiFetch, FetchTypes } from "../../../toolympus/api/core";
import { useTextFilter, WithTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { LoadedData, useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { useSchema, Schema } from "../../../toolympus/hooks/useSchema";
import { DocumentAccess } from "../../Team/useTeamDocuments";
import { JudgeWithStats } from "./useJudgeAssignmentStats";

export interface JudgeAssignmentData {
    judges: LoadedData<JudgeWithStats[]> & WithTextFilter & { schema: Schema };
    assignJudge: (doc: DocumentAccess, judge_id: string) => Promise<any>;
    removeJudge: (doc: DocumentAccess, judge_id: string) => Promise<any>;
    isSubmitting: boolean;
}

export const useJudgeAssignment = (onSuccess?: () => void): JudgeAssignmentData => {
    const judges = useLoadedData<JudgeWithStats[]>('/api/assignment/documents/admin/judge-stats', []);
    const filter = useTextFilter<JudgeWithStats>(j => `${j.email} ${j.lastname} ${j.firstname} ${j.middlename}`);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { judge: schema } = useSchema();

    
    const updJudge = (method: FetchTypes, doc: DocumentAccess, judge_id: string) => {
        setIsSubmitting(true);
        return apiFetch(`/api/assignment/documents/admin/${judge_id}/${doc.document_id}`, method)
            .then(x => {
                setIsSubmitting(false);
                if(onSuccess) {
                    onSuccess();
                }
                return x;
            })
            .catch(e => {
                setIsSubmitting(false);
                throw e;
            })
    }


    return {
        judges: {
            ...judges,
            data: filter.filterData(judges.data),
            ...filter,
            schema,
        },
        isSubmitting,
        assignJudge: (d,j) => updJudge(FetchTypes.POST, d, j),
        removeJudge: (d,j) => updJudge(FetchTypes.DELETE, d, j),
    }
}
