import React from 'react';
import { useIntl } from 'react-intl';
import { LoadedData } from '../../../hooks/useLoadedData';
import { FieldType, mergeSchema, SchemaChanges } from '../../../hooks/useSchema';
import { ActionRow, OccupyFreeSpace } from '../../primitives/ActionRow';
import { ClipboardButton } from '../../primitives/Buttons';
import { LoadingIndicator } from '../../primitives/LoadingIndicator';
import { Tooltip } from '../../primitives/Tooltip';
import { createMDTable, createTsv } from '../../primitives/tsv';
import { FieldDefinition, TableForFields } from '../../schemed';

export interface Rating {
    _id: string;
    display_name: string;
    result: number;
    scores_cnt?: number;
    scores?: string;
}

interface Props {
    data: LoadedData<Rating[]>;
    noDetails?: boolean;
    schemaChanges?: SchemaChanges;
}

export const RatingTable = ({ data, noDetails, schemaChanges }: Props) => {
    const { formatMessage } = useIntl();

    const schema = mergeSchema({
        position: { type: FieldType.text, label: formatMessage({ id: "contests.rounds.ratings.columns.position"}) },
        display_name: { type: FieldType.text, label: formatMessage({ id: "contests.rounds.ratings.columns.display_name"}) },
        result: { type: FieldType.number, label: formatMessage({ id: "contests.rounds.ratings.columns.result"}) },
        scores_cnt: { type: FieldType.number, label: formatMessage({ id: "contests.rounds.ratings.columns.scores_cnt"}) },
        scores: { type: FieldType.text, label: formatMessage({ id: "contests.rounds.ratings.columns.scores"}) },
    }, schemaChanges || {});

    const getRow = (r: Rating, idx: number) => ([idx+1, r.display_name, (r.result || 0).toFixed(2), ...(noDetails ? [] : [r.scores_cnt, r.scores])]);
    const exportColumns = [
        schema.position.label?.toString() || "",
        schema.display_name.label?.toString() || "",
        schema.result.label?.toString() || "",
        ...(noDetails ? [] : [
            schema.scores_cnt.label?.toString() || "",
            schema.scores.label?.toString() || "",
        ])
    ];

    const getTsv = () => createTsv(exportColumns, getRow, data.data);
    const getMD = () => createMDTable(exportColumns, getRow, data.data);

    return <>
        <ActionRow>
            {data.isLoading && <LoadingIndicator />}
            <OccupyFreeSpace />

            <Tooltip text_id="common.copy_as_tsv" withWrapper>
                <ClipboardButton getValue={() => getTsv()} />
            </Tooltip>

            <Tooltip text_id="common.copy_as_md_table" withWrapper>
                <ClipboardButton getValue={() => getMD()} />
            </Tooltip>
        </ActionRow>

        <TableForFields
            data={data.data.map((x,i) => ({ ...x, position: i+1 }))}
            schema={schema}
            fields={[
                ["position"],
                ["display_name"],
                ["result", { precision: 2 }],
                ...(noDetails ? [] : [
                    ["scores_cnt"],
                    ["scores"],
                ] as FieldDefinition[])
            ]}
            />
    </>;
}