import { useState } from "react";
import { useHistory } from "react-router-dom";
import { apiFetch, FetchTypes } from "../../toolympus/api/core";
import { useValidationErrors } from "../../toolympus/components/schemed";
import { useDictionaries } from "../../toolympus/hooks/useDictionaries";
import { FieldType, mergeSchema, useSchema } from "../../toolympus/hooks/useSchema";
import { useUser, login } from "../../toolympus/userContext/UserContext";
import { RegistrationData } from "./useTeamRegistrationStageOne";

export interface JudgeRegistration {
    lastname: string;
    firstname: string;
    middlename?: string | null;
    email: string;
    phone: string;
    password: string;
    passwordRepeat: string;
    city: string;
    education: string;
    job: string;
    experience: string;
    affiliation: string;
    experience_femida_member: boolean;
    experience_femida_coach: boolean;
    experience_femida_judge_written: boolean;
    experience_femida_judge_oral: boolean;
    experience_moots: string;
    judge_intention_written: boolean;
    judge_intention_oral: boolean;
    judge_written_works_nbr: number | null;
    travel_compensation_required: boolean;
    personal_data_consent: boolean;
    affiliation_bound?: string[];
    timeslots_selected?: string[] | null;
}

export interface Judge extends JudgeRegistration {
    _id: string;
    approval_status?: 'approved' | 'rejected' | null;
}

interface JudgeRegistrationData extends RegistrationData<JudgeRegistration> {
    stage: 0 | 1 | 2;
    canGoNextStage: boolean;
    goNextStage: () => void;
    goPrevStage: () => void;
    isLastStage: boolean;
}

export const JudgeSchemaChanges = {
    password: { type: FieldType.password },
    passwordRepeat: { type: FieldType.password, label: "Пароль ещё раз" },

    education: { label: "Образование / специализация" },
    job: { label: "Место работы, занимаемая должность" },
    affiliation: {
        label: "Аффилированность с ВУЗами (по месту учебы, работы)",
        type: FieldType.textlong,
    },
    affiliation_bound: {
        label: "Аффилированность с ВУЗами (по месту учебы, работы)",
        type: FieldType.dictionarySelectMulti,
        dictionary: "Universities",
    },
    experience: {
        label: "Опыт в сфере конституционного и/или международного права",
        type: FieldType.textlong,
    },
    experience_moots: {
        label: "Опыт судейства в российских или международных конкурсах по праву",
        type: FieldType.select,
        values: [{ value: "yes", label: "есть" }, { value: "no", label: "нет" }],
        valueDict: { yes: "есть", no: "нет" },
    },

    experience_femida_member: { label: "член команды" },
    experience_femida_coach: { label: "тренер команды" },
    experience_femida_judge_written: { label: "судья письменного этапа" },
    experience_femida_judge_oral: { label: "судья очных раундов" },

    judge_intention_written: { label: "письменных раундов" },
    judge_intention_oral: { label: "устных раундов" },
    judge_written_works_nbr: { type: FieldType.number, label: " " },
    travel_compensation_required: { label: "Потребуется ли Вам компенсация расходов на проезд и проживание (для судей устных раундов)" },

    personal_data_consent: { label: "Я даю согласие на обработку персональных данных" },
};

export const useJudgeRegistration = (): JudgeRegistrationData => {
    const { judge: judgeSchema } = useSchema();

    const schema = mergeSchema(judgeSchema, JudgeSchemaChanges);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const user = useUser();
    const history = useHistory();
    const errors = useValidationErrors();

    const [data, setData] = useState<JudgeRegistration>({
        email: "",
        password: "",
        passwordRepeat: "",

        phone: "",
        lastname: "",
        firstname: "",
        middlename: "",

        city: "",
        affiliation: "",
        affiliation_bound: [],
        education: "",
        job: "",
        experience: "",
        experience_femida_member: false,
        experience_femida_coach: false,
        experience_femida_judge_written: false,
        experience_femida_judge_oral: false,
        experience_moots: "",
        judge_intention_written: false,
        judge_intention_oral: false,
        judge_written_works_nbr: 0,
        travel_compensation_required: false,
        personal_data_consent: false,
    });

    const [stage, setStage] = useState<JudgeRegistrationData['stage']>(0);
    const isLastStage = stage === 2;

    const isFieldProvided = (f: keyof JudgeRegistration) => data[f] !== null && data[f] !== undefined && data[f] !== "";

    const stageFields: Record<JudgeRegistrationData["stage"], (keyof JudgeRegistration)[]> = {
        0: [
            "lastname",
            "firstname",
            // "middlename",
            "phone",
            "city",
            "education",
            "job",
            "experience",
            // "affiliation",
        ],

        1: [
            "experience_moots",
            "experience_femida_member",
            "experience_femida_coach",
            "experience_femida_judge_written",
            "experience_femida_judge_oral",

            "judge_intention_written",
            "judge_intention_oral",
            "judge_written_works_nbr",
            "travel_compensation_required",
        ],

        2: [
            "email",
            "password",
            "passwordRepeat",
            "personal_data_consent",
        ],
    };

    const isNextStageAvailableForStage = (s: JudgeRegistrationData["stage"]): boolean => stageFields[s].every(k => isFieldProvided(k));

    const canGoNextStage = isNextStageAvailableForStage(stage);

    const canSubmit = (
        isNextStageAvailableForStage(0)
        && isNextStageAvailableForStage(1)
        && isNextStageAvailableForStage(2)
        && data.personal_data_consent
        && data.password === data.passwordRepeat
    );
    
    const dictionaries = useDictionaries();

    const processUniversities = (data: JudgeRegistration) => {
        const result = { ...data };
        const unis = dictionaries["Universities"]?.records || [];
        const affiliation = (data.affiliation_bound || [])
            .map(u => unis.find(r => r.code === u)?.label)
            .filter(u => !!u)
            .join(";")
        
        result.affiliation = affiliation || "нет";
        delete result.affiliation_bound;

        return result;
    }


    const submit = () => {
        setIsLoading(true);
        errors.clearErrors();

        return apiFetch<string>('/api/judge/register', FetchTypes.POST, processUniversities(data))
            .then(token => {
                login(user, token);
                history.replace('/judge/info');
                setIsLoading(false);
            })
            .catch(e => {
                errors.captureErrors(e);
                setIsLoading(false);
                setStage(0);
                throw e;
            })
    }

    return {
        schema,
        data,
        update: c => setData({ ...data, ...c }),
        canSubmit,
        submit,
        isLoading,
        errors,

        isLastStage,
        stage,
        canGoNextStage,
        goNextStage: () => {
            if(!isLastStage && canGoNextStage) {
                setStage(stage+1 as JudgeRegistrationData["stage"]);
            }
        },
        goPrevStage: () => {
            if(stage !== 0) {
                setStage(stage-1 as JudgeRegistrationData["stage"]);
            }
        },
    }
}
