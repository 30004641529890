import React from 'react';
import { Button, Typography, useMediaQuery, useTheme, } from '@material-ui/core';
import { 
    CaptionAndLinksBlock, 
    LeftPane, 
    Link, 
    RegistrationPageWrapper, 
    RegistrationPageRightPane, 
    TailButtons,
    ContactUsText,
} from './Common';
import { FieldDefinition, FormControlsForFields } from '../../toolympus/components/schemed';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { useJudgeRegistration } from './useJudgeRegistration';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { useContestState } from '../../useContestState';
import { PublicInfoPageBySlug } from './PublicInfoPage';
import { DictionariesProvider } from '../../toolympus/hooks/useDictionaries';


export const JudgeRegistrationPageUnwrapped = () => {
    const {
        schema,
        data,
        update,
        canSubmit,
        submit,
        errors,
        isLoading,
        stage,
        isLastStage,
        canGoNextStage,
        goPrevStage,
        goNextStage,
    } = useJudgeRegistration();

    const { data: contestState } = useContestState();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const controls = (fields: FieldDefinition[]) => (
        <FormControlsForFields
            data={data}
            schema={schema}
            fields={fields}
            onChange={(o,c) => update(c)}
            errors={errors}
            withArea
            />
    )

    const scrollAnchorClassArea = "scroll-anchor";
    const scrollAnchor = <div className="scroll-anchor" style={{ gridArea: scrollAnchorClassArea }} />;

    if(!contestState.registration_available_judge) {
        return <PublicInfoPageBySlug slug="judge-registration-blocked" />
    }

    return (
        <RegistrationPageWrapper>
            <LeftPane pad="small" />

            <style dangerouslySetInnerHTML={{ __html: "@media(max-width: 991px) {.MuiListItemText-root {white-space: break-spaces;}}"}}>
            </style>

            <RegistrationPageRightPane>
                <CaptionAndLinksBlock>
                    <Typography variant="h3">регистрация судей</Typography>
                    <Typography>Уже зарегистрировались?<br/><Link to="/login">Войдите в личный кабинет</Link></Typography>
                    <Typography>Если Вы хотите зарегистрировать команду,<br/><Link to="/register-team">заполните другую форму</Link></Typography>
                </CaptionAndLinksBlock>

                {stage === 0 && <FormGrid
                    columns={Array(isMobile ? 1 : 6).fill("1fr").join(" ")}
                    areas={isMobile ? [
                            scrollAnchorClassArea,
                            "lastname",
                            "firstname",
                            "middlename",
                            "phone",
                            "city",
                            "education",
                            "job",
                            "experience",
                            "affiliation_bound",
                        ]
                        : [
                        Array(6).fill(scrollAnchorClassArea).join(" "),
                        "lastname lastname firstname firstname middlename middlename",
                        "phone phone phone city city city",
                        Array(6).fill("education").join(" "),
                        Array(6).fill("job").join(" "),
                        Array(6).fill("experience").join(" "),
                        Array(6).fill("affiliation_bound").join(" "),
                    ]}>
                    {controls([
                        ["lastname"],
                        ["firstname"],
                        ["middlename"],
                        ["phone"],
                        ["city"],
                        ["education"],
                        ["job"],
                        ["experience"],
                        ["affiliation_bound"],
                    ])}
                    {scrollAnchor}
                </FormGrid>}

                {stage === 1 && <FormGrid
                    columns={Array(isMobile ? 1 : 2).fill("1fr").join(" ")}
                    areas={isMobile ? [
                        scrollAnchorClassArea,
                        "experience_moots",
                        "experience_femida_title",
                        "experience_femida_member",
                        "experience_femida_coach",
                        "experience_femida_judge_written", 
                        "experience_femida_judge_oral",
                        "judge_intention_title",
                        "judge_intention_written",
                        "judge_intention_oral",
                        "judge_written_works_nbr_title",
                        "judge_written_works_nbr",
                        "empty2",
                        "travel_compensation_required",
                    ] : [
                        Array(isMobile ? 1 : 2).fill(scrollAnchorClassArea).join(" "),
                        "experience_moots experience_moots",
                        "experience_femida_title experience_femida_title",
                        "experience_femida_member experience_femida_coach",
                        "experience_femida_judge_written experience_femida_judge_oral",
                        "judge_intention_title judge_intention_title",
                        "judge_intention_written judge_intention_oral",
                        "judge_written_works_nbr_title judge_written_works_nbr_title",
                        "judge_written_works_nbr empty1",
                        "empty2 empty2",
                        "travel_compensation_required travel_compensation_required",
                    ]}>
                    {controls([
                        ["experience_moots", { hint: data.experience_moots ? undefined : "Выберите из списка" }],
                        ["experience_femida_member"],
                        ["experience_femida_coach"],
                        ["experience_femida_judge_written"],
                        ["experience_femida_judge_oral"],
                        ["judge_intention_written"],
                        ["judge_intention_oral"],
                        ["judge_written_works_nbr"],
                        ["travel_compensation_required"],
                    ])}
                    <Typography variant="caption" style={{ gridArea: "experience_femida_title" }}>Опыт участия в Конкурсе "Хрустальная Фемида"</Typography>
                    <Typography variant="caption" style={{ gridArea: "judge_intention_title" }}>Вы хотели бы принять участие в качестве судьи</Typography>
                    <Typography variant="caption" style={{ gridArea: "judge_written_works_nbr_title" }}>Пожалуйста, укажите, сколько письменных работ Вы сможете оценить (как правило, мы просим судей проверить не менее двух работ: одну жалобу и один отзыв)</Typography>
                    <Typography style={{ gridArea: "empty2" }}> </Typography>
                    {scrollAnchor}
                </FormGrid>}

                {stage === 2 && <FormGrid
                    style={{ alignItems: 'flex-start'}}
                    columns={Array(isMobile ? 1 : 2).fill("1fr").join(" ")}
                    areas={isMobile ? [
                        scrollAnchorClassArea,
                        "email",
                        "password",
                        "passwordRepeat",
                        "personal_data_consent",
                    ] : [
                        Array(2).fill(scrollAnchorClassArea).join(" "),
                        "email empty",
                        "password passwordRepeat",
                        "personal_data_consent personal_data_consent",
                    ]}>
                    {controls([
                        ["email", { hint: "Для входа в систему и уведомлений" }],
                        ["password", { hint: "От 6 символов. Должен включать латинские буквы, цифры и символы" }],
                        ["passwordRepeat", { hint: "Чтобы не было ошибок" }],
                        ["personal_data_consent"],
                    ])}
                    {scrollAnchor}
                </FormGrid>}

                <ContactUsText respect />

                <TailButtons>
                    {stage !== 0 &&
                        <Button onClick={e => {
                            goPrevStage();
                            setTimeout(() => document.querySelector(`.${scrollAnchorClassArea}`)?.scrollIntoView(), 200);                            
                            }}>
                            назад
                        </Button>}
                    {stage === 0 && <div/>}
                    {!isLastStage &&
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={e => {
                                goNextStage();
                                setTimeout(() => document.querySelector(`.${scrollAnchorClassArea}`)?.scrollIntoView(), 200);                            
                                }}
                            disabled={!canGoNextStage}>
                            далее (шаг {stage+1} из 3)
                        </Button>}
                    {isLastStage &&
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={submit}
                            disabled={!canSubmit || isLoading}>
                            зарегистрироваться
                            {isLoading && <LoadingIndicator sizeVariant="s" />}
                        </Button>}
                </TailButtons>
            </RegistrationPageRightPane>
        </RegistrationPageWrapper>
    );
}

export const JudgeRegistrationPage = () => {
    return (
        <DictionariesProvider apiPath="/api/dictionaries">
            <JudgeRegistrationPageUnwrapped />
        </DictionariesProvider>
    )
}