import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { OpenItemProps } from '../../../toolympus/api/useNewItem';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { SearchField } from '../../../toolympus/components/primitives/SearchField';
import { TableForFields } from '../../../toolympus/components/schemed';
import { WithTextFilter } from '../../../toolympus/components/schemed/Filtering/useTextFilter';
import { LoadedData } from '../../../toolympus/hooks/useLoadedData';
import { JudgeWithStats } from './useJudgeAssignmentStats';
import { Schema } from '../../../toolympus/hooks/useSchema';

interface Props {
    data: LoadedData<JudgeWithStats[]> & WithTextFilter & { schema: Schema };
    selectJudge: (id: string) => Promise<any>;
    state: OpenItemProps<any>;
}

export const JudgeSelectionPopup = ({ data, selectJudge, state }: Props) => {
    return (
        <Dialog open={state.isOpen} onClose={state.cancel} maxWidth="md" fullWidth>
            <DialogTitle>
                <SearchField filter={data.filter} setFilter={data.setFilter} doSearch={() => { }} />
                {data.isLoading && <LoadingIndicator />}
            </DialogTitle>
            <DialogContent>
                <TableForFields
                    schema={data.schema}
                    data={data.data}
                    fields={[
                        ["_assign", { label: ' '}],
                        ["email"],
                        ["lastname"],
                        ["firstname"],
                        ["middlename"],
                        ["phone"],
                        ["judge_written_works_nbr"],
                        ["assigned"],
                        ["remaining"],
                    ]}
                    fieldElement={f => {
                        if(f === "_assign") {
                            return row => <Button variant="outlined" size="small" onClick={() => selectJudge(row._id)}>выбрать</Button>
                        }
                    }}
                    />
            </DialogContent>
        </Dialog>
    );
}
