import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, Typography, withTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { LoadingIndicator } from '../../../primitives/LoadingIndicator';
import { useFormats } from '../../../schemed';
import { utc } from '../../../timezone';
import { Round } from '../types';
import { useUser } from '../../../../userContext/UserContext';
import { RoundCSS, RoundsContainerCSS } from '../Common';
import { PartyRounds } from './usePartyRounds';
import { Link } from 'react-router-dom';
import { Videocam } from '@material-ui/icons';
import { useGridSettings } from '../GridSettingsContext';
import { ActionRow, OccupyFreeSpace } from '../../../primitives/ActionRow';
import { SearchField } from '../../../primitives/SearchField';
import { ClipboardButton } from '../../../primitives/Buttons';
import { Tooltip } from '../../../primitives/Tooltip';

export interface PartyRoundsListProps {
    data: PartyRounds;
    roundUrl?: (r: Round) => string;
    search?: boolean;
    hideConference?: boolean;
    hideEmptyState?: boolean;
}

const EmptyState = styled.div`
    width: 100%;
    min-width: 0;
    min-height: 48px;
    padding: 0.5rem 0.75rem;
    margin: 0.5rem 0;
    box-sizing: border-box;
    
    box-shadow: 0 0 5px 0 #00000020;
    text-align: center;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;    
`;

const StageWrapper = withTheme(styled.div`
    ${props => RoundsContainerCSS(props)}
    margin: 0.5rem 0 1.5rem;
`);

const RoundWrapper = withTheme(styled(Link)`
    color: inherit;
    text-decoration: none;

    ${props => RoundCSS(props)}

    & > .players-row {
        & > .me {
            border-bottom: 3px solid ${props => props.theme.palette.primary.main};
        }
    }
`);

interface RoundDisplayProps {
    round: Round;
    url?: string;
    hideConference?: boolean;
}

export const RoundDisplay = ({ round, url, hideConference }: RoundDisplayProps) => {
    const {1: player1, 2: player2} = round.players;
    const { formatDatetimeShort } = useFormats();
    const { user } = useUser();

    const gridSettings = useGridSettings();
    const getArbiterClass = gridSettings.get_arbiter_class || (() => undefined);

    const RoundPlayerInfo = gridSettings.round_player_info_component;

    
    return <RoundWrapper
        className="round"
        to={url || ""}
        onClick={e => {
            if(!url) {
                e.preventDefault();
            }
        }}>
        <div className="title-row">
            <Typography key="title" variant="caption">{round.title}</Typography>
            <Typography key="time" variant="caption">{round.time ? formatDatetimeShort(utc.toLocal(round.time)) : ""}</Typography>
        </div>

        <div className="players-row">
            <Typography key="p1" className={`player player1 ${player1 && player1._id === user?._id ? 'me' : ''}`}>
                {player1 ? player1.display_name : "-"}
            </Typography>

            <Typography key="vs" variant="caption"><FormattedMessage id="contests.rounds.round.labels.vs" /></Typography>
            
            
            <Typography key="p2" className={`player player2 ${player2 && player2._id === user?._id ? 'me' : ''}`}>
                {player2 ? player2.display_name : "-"}
            </Typography>
        </div>

        {RoundPlayerInfo && (
            <div className="players-info-row">
                <RoundPlayerInfo position={1} round={round} />
                <div />
                <RoundPlayerInfo position={2} round={round} />
            </div>
        )}

        <div className="judges-row">
            <Typography key="arbs" variant="caption">
                <FormattedMessage id="contests.rounds.round.labels.arbiters" />: 
                <> {Object.keys(round.arbiters).map((aIdx,i) => {
                    const arb = round.arbiters[aIdx as unknown as number];
                    return (<React.Fragment key={arb._id}>
                    <span className={getArbiterClass(arb, aIdx) || ""}>{arb.display_name}</span>
                    <span>{i === Object.keys(round.arbiters).length - 1 ? " " : ", "}</span>
                    </React.Fragment>
                    )})}
                </>
            </Typography>
        </div>

        <div className="description">
            {(round.description || "").split("\n").map((line, i) => <Typography key={i}>{line}</Typography>)}
        </div>

        {!hideConference && round.meeting_info?.kind === "zoom" && round.meeting_info?.join_url && <div className="conference-row">
            <Button
                onClick={e => { e.stopPropagation(); }}
                size="small"
                href={round.meeting_info?.join_url}
                target="_blank"
                rel="noreferrer noopener"
                color="primary"
                variant="contained"
                startIcon={<Videocam />}>
                <FormattedMessage id="contests.rounds.public.join_conference" />
            </Button>
            <Tooltip text_id="contests.rounds.public.copy_join_url">
                <ClipboardButton value={round.meeting_info?.join_url} />
            </Tooltip>
        </div>}
    </RoundWrapper>;
}

const roundToString = (r: Round) => `${r.title} ${r.description} ${Object.values(r.players).map(p => p.display_name).join(" ")} ${Object.values(r.arbiters).map(p => p.display_name).join(" ")}`.toLowerCase();

export const PartyRoundsList = (props: PartyRoundsListProps) => {
    const { byStages, isLoading, data } = props.data;
    const [filter, setFilter] = useState<string>("");

    return <>
        <ActionRow>
            {isLoading && <LoadingIndicator />}
            <OccupyFreeSpace />
            {props.search && data.length > 0 && <SearchField filter={filter} setFilter={setFilter} noButton />}
        </ActionRow>

        {!props.hideEmptyState && !isLoading && data.length === 0 && <EmptyState>
            <Typography><FormattedMessage id="contests.rounds.public.empty_state" /></Typography>
            </EmptyState>}

        {byStages.map(([stage, rounds]) => (
            <>
                <Typography key={`label_${stage}`} variant="h6"><FormattedMessage id={`contests.rounds.public.stage_names.${stage}`} /></Typography>
                <StageWrapper key={stage} className={`party-stage-rounds ${stage}`}>
                    {rounds
                        .filter(r => filter && filter.length ? roundToString(r).includes(filter.toLowerCase()) : true)
                        .map(r => <RoundDisplay
                            key={r._id}
                            round={r}
                            url={props.roundUrl ? props.roundUrl(r) : undefined}
                            hideConference={props.hideConference}
                            />)}
                </StageWrapper>
            </>
        ))}
        </>
}
