import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { Button, Typography, withTheme } from '@material-ui/core';
import { CheckBoxOutlined, AssignmentTurnedInOutlined } from '@material-ui/icons';
import { DocKey, DocumentNames, TeamDocuments as TD, useTeamDocuments } from './useTeamDocuments';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { Link, PseudoLink } from '../../toolympus/components/primitives/Common.styles';
import { createFileDropHandler } from '../../toolympus/api/files';

const DocumentWrapper = withTheme(styled.div<{ uploaded: boolean, enabled: boolean, target: boolean }>`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    cursor: ${props => props.enabled ? 'pointer' : 'default'};
    min-height: 80px;
    padding: 6px 0;

    background: ${props => props.target ? 'transparent' : (props.uploaded ? '#2B4F7010' : `#5B152030`)};
    border: 1px ${props => !props.uploaded && props.enabled ? 'dashed' : 'solid'} ${props => props.theme.palette.primary.main};
    border-radius: 8px;

    & > div {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        gap: 12px;

        &:nth-child(1) {
            padding: 6px 0;

            & > p {
                font-size: 20px;
            }

            & > svg {
                color: ${props => props.uploaded ? props.theme.palette.success.main : props.theme.palette.primary.main};
            }
        }
    
        &:nth-child(2) {
            & > :nth-child(2) {
                color: ${props => props.theme.palette.secondary.main};
            }
        }
    }

`);

const Hint = styled(Typography)`
    text-align: center;
`;

Hint.defaultProps = { variant: "caption" };

export const Document = (props: { data: TD, docKey: DocKey, score?: number, showScore?: boolean }) => {
    const [isDragTarget, setIsDragTarget] = useState<boolean>(false);
    const { data, docKey } = props;
    const document = data.documents[docKey];

    const isUploaded = !!document?.document_id;
    return (
        <DocumentWrapper
            uploaded={isUploaded}
            enabled={data.isUploadAllowed}
            target={isDragTarget}
            onClick={() => data.upload(docKey)}
            onDragEnter={() => setIsDragTarget(true)}
            onDragLeave={() => setIsDragTarget(false)}
            onDrop={createFileDropHandler(f => { setIsDragTarget(false); data.uploadFile(docKey, f); })}
            onDragOver={(e: any) => { e.preventDefault(); }}
            >
            <div>
                <Typography>{DocumentNames[docKey]}</Typography>
                {data.isLoading && <LoadingIndicator sizeVariant="s" />}
                {!data.isLoading && document?.document_id && <CheckBoxOutlined />}
            </div>
            {props.showScore && <div>
                <Typography>Оценка: {(props.score || 0.0).toFixed(2)}</Typography>
            </div>}
            <div>
                {!isUploaded && data.isUploadAllowed && <PseudoLink>загрузить</PseudoLink>}
                {isUploaded && <PseudoLink onClick={(e: any) => { e.stopPropagation(); data.download(docKey); }}>скачать</PseudoLink>}
                {isUploaded && data.isUploadAllowed && <PseudoLink onClick={(e: any) => { e.stopPropagation(); data.remove(docKey); }}>удалить</PseudoLink>}
            </div>
        </DocumentWrapper>
    )
}


const DocsWrapper = withTheme(styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    padding: 45px 0;

    & > ${Hint} {
        grid-column-start: span 2;
    }

    ${props => props.theme.breakpoints.down("sm")} {
        grid-template-columns: 1fr;
        
        & > ${Hint} {
            grid-column-start: span 1;
        }
    }
`);

export const TeamDocuments = () => {
    const data = useTeamDocuments();
    const isBothUploaded = data.documents.doc1?.document_id && data.documents.doc2?.document_id;

    const showScores = !!isBothUploaded && !!data.isScoresAvailable;

    return (
        <DocsWrapper>
            <Document key="doc1" data={data} docKey="doc1" score={data.team.doc1_score} showScore={false} />
            <Document key="doc2" data={data} docKey="doc2" score={data.team.doc2_score} showScore={false}  />
            {!data.isUploadAllowed && !isBothUploaded && <Hint>Срок подачи документов вышел.</Hint>}
            {isBothUploaded && !data.isScoresAvailable && <Hint>Процессуальные документы успешно загружены.</Hint>}
            {showScores && <>
                <Typography key="doc1score" style={{ placeSelf: 'center' }}>Оценка: {(data.team.doc1_score || 0).toFixed(2)}</Typography>
                <Typography key="doc2score" style={{ placeSelf: 'center' }}>Оценка: {(data.team.doc2_score || 0).toFixed(2)}</Typography>
                </>}
            {showScores && <Hint>
                <Typography variant="h6">Итоговая оценка за документы: {(data.team.documents_score || 0).toFixed(2)}</Typography>
                {data.isShowLinkToFinalsMembers && <p>
                    Пожалуйста, подтвердите участников устных раундов <Link to="/team/profile">на странице команды</Link>.
                    </p>}
                </Hint>}
            {isBothUploaded && data.isFeedbackAvailable && <Hint>
                <Button color="primary" variant="contained" startIcon={<AssignmentTurnedInOutlined />} onClick={() => data.getFeedback()}>скачать оценочный лист</Button>
            </Hint>}

            {data.isRoundsFeedbackAvailable && <Hint>
                <Button color="primary" variant="contained" startIcon={<AssignmentTurnedInOutlined />} onClick={() => data.getRoundsFeedback()}>скачать оценочный лист по устным раундам</Button>
            </Hint>}
        </DocsWrapper>
    );
}
