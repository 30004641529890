import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { cols } from '../../toolympus/components/primitives/Forms';
import { useMediaBreakpoint } from '../../toolympus/components/primitives/useMedia';
import { Partner, usePartnersInfo } from './usePartnersInfo';

interface Props {
    pad?: 'small' | 'normal';
    pageSize?: number;
}

const PartnersSection = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
`;

const Partners = styled.div<{ isActive?: boolean, pageSize?: number }>`
    display: grid;
    grid-template-columns: ${props => cols('1fr', props.pageSize || 3)};
    grid-column-gap: 50px;
    align-items: center;
    /* width: 100%; */
    max-width: 100%;
    height: ${({ isActive }) => isActive ? "100%" : 0};
    width: ${({ isActive }) => isActive ? "100%" : 0};
    transition: width 0.4s ease;
    overflow: hidden;

    & > a {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        max-height: 100%;
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;

        &.padded {
            padding: 20px 0;
        }
        
        & > img {
            max-height: 100%;
            max-width: 100%;
        }
    }
`;

export const PartnerLogo = (props: { partner: Partner }) => {
    const { url, title, classes, img_url, width } = props.partner;
    return (
        <a href={url} title={title} className={classes} target="_blank" rel="noopener noreferrer" style={{ gridColumnEnd: `span ${width || 1}` }}>
            <img src={img_url} alt={title} />
        </a>
    )
}

export const PartnersBlock = (props: Props) => {
    const isMobile = useMediaBreakpoint('down', 'xs');
    const pageSize = props.pageSize || (isMobile ? 1 : 3);
    const partnersInfo = usePartnersInfo({ pageSize });
    
    const nSections = partnersInfo.sections.length;
    const [activeBlockIdx, setActiveBlockIdx] = useState<number>(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setActiveBlockIdx(x => x+1 < nSections ? x+1 : 0);
        }, 4000);
        return () => clearInterval(intervalId);
    }, [nSections, pageSize])

    return (
        <PartnersSection>
            {partnersInfo.sections.map((s, i) => (
                <Partners key={i} isActive={i === activeBlockIdx} pageSize={pageSize}>
                    {s.partners.map(p => <PartnerLogo key={p.title} partner={p} />)}
                </Partners>
            ))}
        </PartnersSection>
    );
}
