import React, {  } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { DocumentAssessmentListData } from './useDocumentsAssessmentList';
import { FormattedMessage } from 'react-intl';
import { FormGrid } from '../../../toolympus/components/primitives/Forms';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { FieldType } from '../../../toolympus/hooks/useSchema';


interface Props {
    data: DocumentAssessmentListData["acceptFinals"];
}

export const AcceptFinalsPopup = (props: Props) => {
    const { item, update, errors, isEditing, isLoading, cancel, save } = props.data;
    return (
        <Dialog open={isEditing} onClose={cancel} fullWidth maxWidth="sm">
            <DialogTitle>
                Отобрать команды в итоговые раунды
            </DialogTitle>
            <DialogContent>
                {item && <FormGrid columns="1fr">
                        <FormControlsForFields
                            data={item}
                            onChange={(o,c) => update(c)}
                            fields={[
                                ['count'],
                            ]}
                            schema={{
                                count: { type: FieldType.number, label: 'Число команд', },
                            }}
                            errors={errors}
                        />
                    </FormGrid>}
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel}><FormattedMessage id="common.cancel" /></Button>
                <Button variant="contained" color="primary" onClick={save} disabled={isLoading}><FormattedMessage id="common.save" />{isLoading && <LoadingIndicator />}</Button>
            </DialogActions>
        </Dialog>
    )
}
