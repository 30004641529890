import React from 'react';
import { ChatBubbleOutline } from '@material-ui/icons';
import { MessageIconMenu } from '../../toolympus/components/Chats';
import { Chat } from '../../toolympus/components/Chats/types';

interface UnreadIndicatorProps {
    isThatChat?: (c: Chat) => boolean;
}

export const ChatsUnreadIndicator = (props: UnreadIndicatorProps) => {
    return (
        <MessageIconMenu isThatChat={props.isThatChat}>
            <ChatBubbleOutline style={{ marginLeft: '0.5rem' }} />
        </MessageIconMenu>
    );
}

export const OrgChatUnreadIndicator = () => {
    return <ChatsUnreadIndicator isThatChat={c => c.kind === "org"} />
}
