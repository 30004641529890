import React from "react";
import { Roles } from "../components/UserManagement/UserManagementContainer";
import { MenuRoute } from "../toolympus/components/frame";
import { StateControlPage } from "../components/Admin/Control/StateControlPage";
import { TeamListPage } from "../components/Admin/TeamListPage";
import { JudgeListPage } from "../components/Admin/JudgeListPage";
import { OrgChatWithOrgPage } from "../components/Chats/ChatWithOrgPage";
import { ChatListOrgPage } from "../components/Chats/ChatListOrgPage";
import { ChatsUnreadIndicator } from "../components/Chats/ChatsUnreadIndicator";
import { CasePage } from "../components/CasePage";
import { CaseQuestionsListPage } from "../components/CasePage/CaseQuestionsListPage";
import { DocumentsAssessmentPage } from "../components/Admin/DocumentsAssessment";
import { GridConfigurationContainer } from "../toolympus/components/Contests/Grid";
import { QueryConsoleContainer } from "../toolympus/components/QueryConsole";
import { SpeakersResults } from "../components/Admin/Finals/SpeakersResults";
import { SystemSubmenu } from "../toolympus/components/Contests/SystemSubmenu";
import { SecretaryListPage } from "../components/Admin/SecretaryListPage";
import { CriteriaESPCRating, CriteriaKSRFRating, CriteriaLangRating, CriteriaSPMRating, Doc1Rating, Doc2Rating } from "../components/Admin/Finals/Ratings";
import { Redirect } from "react-router-dom";
import { ManualPage } from "../components/Admin/ManualPage";
import { LiveHelpOutlined, MoveToInboxOutlined } from "@material-ui/icons";
import { ArchivesManagement } from "../components/Admin/Control/ArchivesManagement";


const customComponentsDescription: [string, string][] = [
    ["caselink", "(на странице Фабулы) кнопка для скачивания Фабулы"],
    ["questions", "(на странице Фабулы) блок вопросов по Фабуле"],
    ["scoring", "(на странице оценки документов) блок оценки"],
    ["documents", "(на странице процессуальных документов) блок загрузки документов"],
];

export const AdminRoutes: MenuRoute[] = [
    { 
        path: '/admin/state',
        title: 'Управление Конкурсом',
        component: StateControlPage,
    },
    { 
        path: '/admin/teams',
        title: 'Команды',
        component: TeamListPage,
    },
    { 
        path: '/admin/judges',
        title: 'Судьи',
        component: JudgeListPage,
    },
    { 
        path: '/admin/secretaries',
        title: 'Секретари',
        component: SecretaryListPage,
    },
    {
        path: '/admin/chat-org/:id',
        title: 'Чат',
        component: OrgChatWithOrgPage,
        hidden: true,
    },
    {
        path: '/admin/chat-org',
        title: 'Чаты с участниками',
        suffix: <ChatsUnreadIndicator />,
        component: ChatListOrgPage,
        alsoActivateForPath: p => p.startsWith('/admin/chat-org/'),
    },
    { 
        path: '/admin/case-questions',
        title: 'Вопросы по фабуле',
        component: () => <CaseQuestionsListPage />,
    },
    { 
        path: '/admin/documents-scoring',
        title: 'Оценка документов',
        component: () => <DocumentsAssessmentPage />,
    },
    { 
        path: '/admin/finals',
        title: 'Итоговые раунды',
        component: () => (
            <GridConfigurationContainer
                apiPath="/api/admin/finals"
                tagsApiPath="/api/conflict"
                extraTabs={{
                    components: {
                        speakers: SpeakersResults,
                        doc1: Doc1Rating,
                        doc2: Doc2Rating,
                        spm: CriteriaSPMRating,
                        ksrf: CriteriaKSRFRating,
                        espc: CriteriaESPCRating,
                        lang: CriteriaLangRating,
                        
                    },
                    tabs: [
                        ["speakers", "Рейтинг ораторов"],
                        ["doc1", "Рейтинг жалоб"],
                        ["doc2", "Рейтинг отзывов"],
                        ["spm", "Рейтинг СПМ"],
                        ["ksrf", "Рейтинг КСРФ"],
                        ["espc", "Рейтинг ЕСПЧ"],
                        ["lang", "Рейтинг Стиль и Язык"],
                    ],
                }} />),
    },
    { 
        path: '/admin/case',
        title: 'Фабула',
        component: () => <CasePage />,
    },
    { path: '/admin/queryconsole', title: 'Query Console', component: QueryConsoleContainer, hidden: true },

    
    { 
        path: '/system',
        title: 'Система',
        component: () => (
            <SystemSubmenu
                pages={[
                    { key: "public", label: "Публичные", apiPath: "/api/page-public" },
                    { key: "internal", label: "Внутренние", apiPath: "/api/page", customComponentsDescription },
                    { key: "manual", label: "Инструкции", apiPath: "/api/page-manual" },
                ]}
                files={[
                    { key: "public", label: "Публичные", apiPath: "/api/files-public" },
                    { key: "internal", label: "Внутренние", apiPath: "/api/files" },
                ]}
                notificationTasks={{ apiPath: "/api/notifications/task", emailsApiPath: "/api/emails", emailsPagePath: "/system/emails" }}
                emails={{ apiPath: "/emails" }}
                scoringSheets={{ apiPath: "/api/scoring/sheet" }}
                queryConsole={{ apiPath: "/api/query-console" }}
                users={{ allowInvites: false, allowPaswordReset: true, userRoles: Roles, allowPaswordChange: true }}
                dictionaries={{ apiPath: "/api/dictionaries" }}
                extraItems={[
                    {
                        key: "archives",
                        items: [{
                            component: () => <ArchivesManagement />,
                            key: "archives",
                            label: "",
                        }],
                        label: "Архивы",
                        icon: <MoveToInboxOutlined />,
                    },
                    {
                        key: "manuals",
                        items: [{
                            component: () => <Redirect to={"/manuals/start"} />,
                            key: "manuals",
                            label: "",
                        }],
                        label: "Инструкции",
                        icon: <LiveHelpOutlined />,
                    },
                ]}
                />),
        alsoActivateForPath: p => p.startsWith('/system/'),
    },
    { 
        path: '/manuals/:slug',
        title: 'Инструкции',
        component: ManualPage,
        hidden: true,
    },
];
