import styled from '@emotion/styled';
import { withTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingIndicator } from './LoadingIndicator';


export const Link = withTheme(styled(RouterLink)`
    text-decoration: none;
    color: ${props => props.theme.palette.primary.dark};
`);

export const PseudoLink = withTheme(styled.div`
    color: ${props => props.theme.palette.primary.main};
    cursor: pointer;
    font-weight: 500;
`);

export const PseudoLink2 = withTheme(styled.a<{ border?: boolean }>`
    color: ${props => props.theme.palette.primary.main};
    cursor: pointer;
    font-weight: normal;
    text-decoration: none;
    border-bottom: ${props => props.border ? 1 : 0}px dotted ${props => props.theme.palette.primary.main};
    padding-bottom: ${props => props.border ? 2 : 0}px;
`);
PseudoLink2.defaultProps = { role: "button" };


export const DownloadFileLink = (props: { downloadFile: () => Promise<void> } & React.ComponentProps<typeof PseudoLink2>) => {
    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const doDownload = () => {
        setIsDownloading(true);
        props.downloadFile()
            .then(() => {
                setIsDownloading(false);
            })
            .catch(e => {
                setIsDownloading(false);
                throw e;
            })
    }

    return <PseudoLink2 {...props} onClick={doDownload}>
        {props.children} {isDownloading && <LoadingIndicator sizeVariant="s" style={{ marginLeft: "0.25rem" }} />}
    </PseudoLink2>
}


export const ItemRow = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 0.25rem;
`;
