import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Typography, withTheme, } from '@material-ui/core';
import { CaptionAndLinksBlock, LeftPane, Link, Logo, RightPane } from './Common';
import { useContestState } from '../../useContestState';

const Wrapper = withTheme(styled.div`
    display: grid;
    grid-template-columns: 1fr 422px;
    width: 100%;
    height: 100vh;

    ${props => props.theme.breakpoints.down('md')} {
        display: flex;
        flex-flow: column-reverse;
        height: auto;
    }
`);

const Right = withTheme(styled(RightPane)`
    & > form {
        & > * {
            margin-bottom: 20px;
            width: 100%;
        }
    }
`);

const Registration = withTheme(styled(CaptionAndLinksBlock)`
    height: 180px;
`);

interface Props {
    children: ReactNode;
}

export const LoginLikePage = ({ children }: Props) => {
    const { data: state } = useContestState();
    return (
        <Wrapper>
            <LeftPane />
            <Right>
                <Logo />
                {children}

                <Registration>
                    {(state.registration_available || state.registration_available_judge) && <>
                    <Typography variant="h3">регистрация</Typography>
                    <Typography>Заполните форму регистрации
                        &nbsp;{state.registration_available && <Link to="/register-team">команд</Link>}
                        {state.registration_available && state.registration_available_judge && " или "}
                        {state.registration_available_judge && <Link to="/register-judge">судей</Link>}.</Typography>
                    {state.registration_available && <Typography>Регистрация команд завершается <br/><b>{state.team_registration_due_date}</b></Typography>}
                    </>}
                </Registration>
            </Right>
        </Wrapper>
    );
}