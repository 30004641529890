import { apiFetch, FetchTypes } from "../../api/core";
import { useLoadedData } from "../../hooks/useLoadedData";
import { Action } from "./types";

export interface UseActions {
    data: Action[];
    loading: boolean;
    onAction: (body: any, code: string) => void;
    reload: () => void;
}

export const useActions = (url: string, callback: () => void): UseActions => {
    const {data, isLoading: loading, reload} = useLoadedData<Action[]>(url, []);

    return {
        data,
        loading,
        reload,
        onAction: (body: any, code: string) => {
            return apiFetch(`${url}/${code}`, FetchTypes.POST, body)
                .then(callback)
                .then(reload);
        },
    };
}