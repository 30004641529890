import { usePage } from "../../toolympus/components/Pages"

export interface Partner {
    title: string;
    url: string;
    img_url: string;
    classes?: string;
    width?: number;
}

interface Section {
    title: string;
    partners: Partner[];
}

export interface PartnersInfo {
    sections: { partners: Partner[] }[];
}

interface Config {
    pageSize?: number;
}

export const usePartnersInfo = (config?: Config): PartnersInfo => {
    const { isLoading, page } = usePage('/api/page-public', 'partners');

    const pageSize = config?.pageSize || 3;

    if(isLoading || !page || page.components.length === 0) {
        return { sections: [] };
    }

    const partners = page.components[0].content_text.split("\n").map(text => {
        const [title, url, img_url, w, classes] = text.split(";").map(t => t.trim());
        const width = parseInt(w || "1");

        return {
            title,
            url,
            img_url,
            classes,
            width,
        }
    }).filter(p => p.title && p.title.length && p.img_url && p.img_url.length);

    const partitioned = partners.reduce((r, p) => {
        const lastGroup = r[r.length-1];
        if(lastGroup.reduce((totalW,pp) => totalW+(pp.width || 1), 0) + (p.width || 1) > pageSize) {
            return [...r, [p]];
        } else {
            return [...r.slice(0, r.length-1), [...lastGroup, p]];
        }
    }, [[]] as Partner[][]);

    return {
        sections: partitioned.map(partners => ({ title: "", partners })),
    }
}