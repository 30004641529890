import React from 'react';
import styled from '@emotion/styled';
import { IconButton, Typography, withTheme } from '@material-ui/core';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { PseudoLink } from '../../toolympus/components/primitives/Common.styles';
import { DocKey, DocumentAccess, DocumentNames } from '../Team/useTeamDocuments';
import { Check, CheckCircleOutlined, EditOutlined, Help, RadioButtonUnchecked } from '@material-ui/icons';
import { Team } from '../Public/useTeamRegistrationStageOne';
import { utc } from '../../toolympus/components/timezone';
import { useFormats } from '../../toolympus/components/schemed';


const DocWarnings = withTheme(styled.div`
    color: ${props => props.theme.palette.secondary.main};
    & > p {
        margin: 0.25em 0;
    }
`);

const Column = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
`;

const Row = styled(Column)`
    flex-flow: row;
    justify-content: center;
    gap: 0.5rem;
`;

interface DocumentInfoProps {
    label: string;
    document: DocumentAccess | null | undefined;
    download: (id: string) => void;
    showPenalties?: boolean;
    openPenaltyEdit?: (d: DocumentAccess) => void;
}

export const DocumentInfo = ({ label, document, download, showPenalties, openPenaltyEdit }: DocumentInfoProps) => {
    const warnings = (document?.info?.warnings || []) as string[];
    const { formatDatetimeShort } = useFormats();
    return <FormGrid columns="80px 30px 80px 120px 1fr" style={{ alignItems: 'center' }}>
        <Typography>{label}</Typography>
        {document && document?.document_id ?
            <CheckCircleOutlined />
            : <RadioButtonUnchecked />}
        {document && document?.document_id ?
            <PseudoLink onClick={() => download(document.document_id)}>скачать</PseudoLink>
            : <div/>}
        {document && document?.updated_at ?
            <Column>
                {showPenalties && document?.info?.late_penalty && <Row>
                    <Typography variant="caption">Штраф:</Typography> <Typography>{document.info.late_penalty}</Typography>
                    </Row>}
                <Typography>{formatDatetimeShort(utc.toLocal(document.updated_at))}</Typography>
            </Column>
            : <div/>}
        <Column style={{ alignItems: 'flex-start' }}>
            {showPenalties && <Row
                                style={{ justifyContent: 'flex-start', cursor: 'pointer'}}
                                onClick={openPenaltyEdit && document ? () => openPenaltyEdit(document) : undefined}>
                    <Typography variant="caption">Штраф:</Typography> <Typography>{document?.info?.technical_penalty || '-'}</Typography>
                    <IconButton size="small"><EditOutlined /></IconButton>
                </Row>}
            {document?.info?.comment && <Typography variant="caption" style={{ fontStyle: 'italic' }}>{document?.info?.comment}</Typography>}
            {warnings.length > 0 ?
                <DocWarnings>
                    {warnings.map(w => <p key={w}>{w}</p>)}
                </DocWarnings>
                : <div/>}
        </Column>
    </FormGrid>
}


const DocLinkWrapper = withTheme(styled(PseudoLink)`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
`);

interface DocLinkProps {
    document: DocumentAccess | null | undefined;
    downloadDocument: (id: string) => void;
    openDetails: () => void;
}

export const DocLink = ({ document, downloadDocument, openDetails }: DocLinkProps) => {
    const warnings = document?.info?.warnings || [];
    if(document && document.document_id) {
        return <DocLinkWrapper onClick={() => downloadDocument(document.document_id)}>
            <Check />
            {warnings.length > 0 &&
                <IconButton size="small" color="secondary" onClick={e => { e.stopPropagation(); openDetails(); }}>
                    <Help />
                </IconButton>}
        </DocLinkWrapper>
    } else {
        return null;
    }
}

export const getTeamDocumentName = (kind: DocKey, team: Team | undefined | null) => {
    return `Команда_${team?.number}_${DocumentNames[kind]}.docx`;
}

