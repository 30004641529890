import { Button, Dialog as DialogMUI, DialogActions, DialogContent, DialogContentProps, DialogProps, DialogTitle, IconButton } from '@material-ui/core';
import { FullscreenExitOutlined, FullscreenOutlined } from '@material-ui/icons';
import React, { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ActionRow, OccupyFreeSpace } from './ActionRow';
import { Tooltip } from './Tooltip';

interface DialogPropsX extends Omit<DialogProps, "onClose" | "open"> {
    dialogTitle: ReactNode;
    children?: ReactNode;
    saveLabel?: ReactNode;
    save: () => void;
    isOpen: boolean;
    close: () => void;
    noFullscreen?: boolean;
    noSubmitOnEnter?: boolean;
}

interface DialogPropsXX extends Omit<DialogPropsX, "save" | "saveLabel"> {
    actions?: ReactNode;
    titleActions?: ReactNode;
    contentWrapper?: React.ComponentType<DialogContentProps>;
}

export const Dialog = (props: DialogPropsXX) => {
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

    const Content = props.contentWrapper || DialogContent;

    return (
        <DialogMUI fullScreen={isFullscreen} fullWidth {...props} open={props.isOpen} onClose={() => props.close()}>
            <DialogTitle>
                <ActionRow firstItemNoMargin itemMarginTop="0" lastItemMarginRight="0">
                    {props.dialogTitle}
                    
                    <OccupyFreeSpace />
                    {props.titleActions}
                    {!props.noFullscreen &&
                        <Tooltip text_id="common.fullscreen">
                            <IconButton size="small" onClick={() => setIsFullscreen(x => !x)}>
                                {isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                            </IconButton>
                        </Tooltip>}
                </ActionRow>
            </DialogTitle>

            <Content>
                {props.children}
            </Content>

            {props.actions && <DialogActions>
                {props.actions}
            </DialogActions>}
        </DialogMUI>
    );
}

export const SimpleDialog = (props: DialogPropsX) => {
    return (
        <Dialog {...props}
            onKeyUp={e => {
                if(e.key === "Enter" && !props.noSubmitOnEnter) {
                    props.save();
                }
            }}
            actions={<>
                <Button onClick={() => props.close()}><FormattedMessage id="common.cancel" /></Button>
                <Button color="primary" variant="contained" onClick={props.save}>{props.saveLabel || <FormattedMessage id="common.save" />}</Button>
            </>}>
            {props.children}
        </Dialog>
        
    );
}

export interface DialogState {
    isOpen: boolean;
    open: () => void;
    close: () => void;
}

export const useDialogState = (isOpenDefault?: boolean) => {
    const [isOpen, setIsOpen] = useState<boolean>(isOpenDefault || false);

    return {
        isOpen,
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
    }
}
