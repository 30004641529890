import {
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Dialog as Dlg,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    IconButton,
} from "@material-ui/core";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { FieldType, Schema } from "../../../hooks/useSchema";
import { FormControl, FormControlsForFields, PlaceholderField } from "../../schemed";
import { EmailItem } from "../types";
import { ExpandMore, ShortText, Code } from '@material-ui/icons';
import { LoadingIndicator } from "../../primitives/LoadingIndicator";
import { FormattedMessage } from "react-intl";
import { FormGrid, SectionTitle } from "../../primitives/Forms";
import { DeleteButton } from "../../primitives/DeleteButton";
import { ActionRow, OccupyFreeSpace } from "../../primitives/ActionRow";
import { Tooltip } from "../../primitives/Tooltip";
import { Dialog as DialogX } from "../../primitives/Dialogs";

interface Props {
    data: {
        schema: Schema;
        email: EmailItem;
        isNew: boolean;
        onClose: (email?: EmailItem) => void;
        onChange: (email: EmailItem) => void;
        create: (body: EmailItem) => void;
        update: (body: EmailItem) => void;
        emailDelete: (code: string) => void;
        test: (code: string, data: string, to?: string) => void;
        preview: (code: string) => Promise<{ body_html: string }>;
        testLoading: boolean;
    }
}

const Dialog = styled(Dlg)`
    .MuiDialogContent-root {
        min-height: 75vh;
    }
`;

export const Popup = ({ data: { email, schema, testLoading, onClose, onChange, create, update, emailDelete, test, preview, isNew } }: Props) => {
    const [testEmail, setTestEmail] = useState<{ params: string, to?: string }>({ params: "" });
    const [showHtmlBody, setShowHtmlBody] = useState<boolean>(false);
    const [previewData, setPreviewData] = useState<{ body_html: string } | null>(null);

    const onChangeCtrl = (oldData: EmailItem, changes: Partial<EmailItem>) => {
        const data = { ...oldData, ...changes };
        onChange({ ...data, code: data.code.replace(/ /g, "-") })
    };

    return <>
        <Dialog onClose={() => onClose()} open={true} fullWidth maxWidth="lg">
            <DialogTitle><FormattedMessage id="emails.popup.title" /></DialogTitle>
            <DialogContent>
                <FormGrid columns="1fr 2fr max-content" style={{ marginBottom: "1rem" }}>
                    <FormControlsForFields
                        fields={[
                            ['code', { disabled: !isNew }],
                            ['title'],
                            ['active'],
                            ['base_template'],
                            ['autowrap_html_body'],
                            [PlaceholderField],
                            ['description', { autoRows: true,  wrapperStyle: { gridColumn: "span 3" } }],
                        ]}
                        schema={schema}
                        data={email}
                        onChange={onChangeCtrl}
                    />

                </FormGrid>

                {!testLoading ? <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                    >
                        <Typography><FormattedMessage id="emails.popup.testEmail"/></Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <FormGrid columns="3fr 2fr max-content" style={{ width: "100%", alignItems: "end" }}>
                            <FormControl
                                key="params"
                                field="params"
                                row={testEmail}
                                schema={{ type: FieldType.textlong, label: <FormattedMessage id="emails.popup.testEmailTextTip"/> }}
                                onChange={(_, changes) => setTestEmail({ ...testEmail, ...changes })}
                                extraProps={{ autoRows: true }}
                            />
                            <FormControl
                                key="to"
                                field="to"
                                row={testEmail}
                                schema={{ type: FieldType.text, label: <FormattedMessage id="emails.popup.to"/> }}
                                onChange={(_, changes) => setTestEmail({ ...testEmail, ...changes })}
                            />
                            <Button onClick={() => test(email.code, testEmail.params, testEmail.to)}>
                                <FormattedMessage id="emails.popup.send"/>
                            </Button>
                        </FormGrid>
                    </ExpansionPanelDetails>
                </ExpansionPanel> : <LoadingIndicator />}

                
                <FormGrid columns="1fr">
                    <ActionRow firstItemNoMargin itemMarginTop="0" lastItemMarginRight="0">
                        <SectionTitle>
                            <FormattedMessage id="emails.popup.emailContent" />
                        </SectionTitle>

                        <OccupyFreeSpace />
                        <Button size="small" onClick={() => { preview(email.code).then(x => setPreviewData(x)); }} >
                            <FormattedMessage id="emails.popup.preview_html" />
                        </Button>
                        <Tooltip key="text" text_id="emails.popup.textHint">
                            <IconButton size="small" color={showHtmlBody ? "default" : "primary"} onClick={() => setShowHtmlBody(false)}><ShortText /></IconButton>
                        </Tooltip>
                        <Tooltip key="html" text_id="emails.popup.htmlHint">
                            <IconButton size="small" color={showHtmlBody ? "primary" : "default"} onClick={() => setShowHtmlBody(true)}><Code /></IconButton>
                        </Tooltip>
                    </ActionRow>
                    <FormControlsForFields
                        fields={[
                            ['subject'],
                            showHtmlBody ?
                                ['body_html', { autoRows: true }]
                                : ['body', { autoRows: true }],
                        ]}
                        schema={schema}
                        data={email}
                        onChange={onChangeCtrl}
                    />

                </FormGrid>

            </DialogContent>
            <DialogActions>
                {!isNew && <DeleteButton
                    title={<FormattedMessage id="emails.popup.delete"/>}
                    remove={() => { emailDelete(email.code); return Promise.resolve(); }}
                    preventGoBack
                    button={<Button color="secondary"><FormattedMessage id="common.delete"/></Button>}
                    />}
                <Button color="primary" onClick={() => onClose()}><FormattedMessage id="common.cancel"/></Button>
                <Button color="primary" variant="contained" onClick={() => isNew ? create(email) : update(email)}><FormattedMessage id="common.save"/></Button>
            </DialogActions>
        </Dialog>

        <DialogX
            isOpen={previewData !== null}
            close={() => setPreviewData(null)}
            actions={<Button onClick={() => setPreviewData(null)}><FormattedMessage id="common.close" /></Button>}
            dialogTitle={<FormattedMessage id="emails.popup.preview_html" />}
            >
                {previewData?.body_html && <div dangerouslySetInnerHTML={{ __html: previewData.body_html }} />}
        </DialogX>
    </>
}