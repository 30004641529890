import { Dialog, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import React, { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SimpleButton } from '../primitives/Buttons';
import { UserCreateButtonView } from './UserManagementForm.styles';

interface UserPopupProps {
    isOpen: boolean;
    onClose: VoidFunction;
    onSave: (users: string[]) => void;
    title: string | ReactNode;
}

const UserPopup: React.FC<UserPopupProps> = (props: UserPopupProps) => {
    const [emails, setEmails] = useState<string>('');

    const onSave = () => {
        const parsedEmails = emails.split(/[\s;]+/);
        props.onSave(parsedEmails);
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth='md'
            fullWidth
        >
            <DialogTitle>
                {props.title}
            </DialogTitle>
            <DialogContent>
                <TextField
                    label={<FormattedMessage id="userManagement.userPopup.email"/>}
                    fullWidth
                    onChange={(ev) => setEmails(ev.target.value || '')}
                />
                <div>
                    <Typography variant='caption'>* <FormattedMessage id="userManagement.userPopup.hint"/></Typography>
                </div>
                <UserCreateButtonView>
                    <SimpleButton label={<FormattedMessage id="userManagement.userPopup.submit"/>} action={onSave} />
                </UserCreateButtonView>
            </DialogContent>
        </Dialog>
    )
}

export default UserPopup