import React from 'react';
import styled from '@emotion/styled';
import { Button, TextField, Typography, withTheme, } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useLoginForm } from '../../toolympus/hooks/auth';
import { CaptionAndLinksBlock, Link, } from './Common';
import { checkRole } from '../../toolympus/userContext/UserContext';
import { LoginLikePage } from './LoginLikePage';

const LoginBlock = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const ForgotPasswordLink = withTheme(styled(Link)`
    font-weight: 300;
    
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.75px;
`);

export const LoginPage = () => {
    const { data: credentials, update: onChangeCredentials, send: submit } = useLoginForm("/api/login", "/", {
        redirectPathFromUser: u => {
            if(checkRole(u, "team")) {
                return '/team/info';
            } else if(checkRole(u, "team-application")) {
                return '/team/profile';
            } else if(checkRole(u, "judge-application") || checkRole(u, "judge")) {
                return '/judge/info';
            } else if(checkRole(u, "secretary")) {
                return '/secretary/rounds';
            } else {
                return '/admin/state';
            }
        }
    });

    return (
        <LoginLikePage>
            <form onSubmit={e => { e.preventDefault(); submit(); }}>
                <CaptionAndLinksBlock>
                    <Typography variant="h3">личный кабинет</Typography>
                </CaptionAndLinksBlock>
                <TextField
                    label={<FormattedMessage id="login.email"/>}
                    name='email'
                    variant='outlined'
                    value={credentials.login}
                    onChange={e => onChangeCredentials({ ...credentials, login: e.target.value || '' })} />
                <TextField
                    label={<FormattedMessage id="login.password"/>}
                    name='password'
                    type='password'
                    variant='outlined'
                    value={credentials.password}
                    onChange={e => onChangeCredentials({ ...credentials, password: e.target.value || '' })} />
                <LoginBlock>
                    <ForgotPasswordLink to="/password-reset">Забыли пароль?</ForgotPasswordLink>
                    <Button type='submit' variant='contained' color='primary'><FormattedMessage id="login.submit"/></Button>
                </LoginBlock>
            </form>
        </LoginLikePage>);
}