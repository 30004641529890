import { Button } from '@material-ui/core';
import React from 'react';
import { ActionRow, OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { TagManagementForm } from '../../../toolympus/components/Tags';
import { useConflictsControl } from './useConflictsControl';

interface Props {
    
}

export const ConflictsControlPage = (props: Props) => {
    const data = useConflictsControl();
    const { prefill, isLoading } = data;

    return (
        <>
            <ActionRow firstItemNoMargin>
                <OccupyFreeSpace />
                <Button color="primary" variant="contained" onClick={() => prefill()} disabled={isLoading}>предзаполнить конфликты</Button>
            </ActionRow>

            <TagManagementForm data={data} />
        </>
    );
}
