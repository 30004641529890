import React from 'react';
import styled from '@emotion/styled';
import { Button, Chip, Divider, withTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useItemWithControls } from '../../../api/useWithControls';
import { ActionRow, OccupyFreeSpace } from '../../primitives/ActionRow';
import { SaveButton } from '../../primitives/Buttons';
import { FormGrid } from '../../primitives/Forms';
import { LoadingIndicator } from '../../primitives/LoadingIndicator';
import { GridConfiguration, GridConfigurationData } from './useGridConfiguration';
import { DeleteButton } from '../../primitives/DeleteButton';
import { useGridSettings } from './GridSettingsContext';

const ProblemsContainer = withTheme(styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;

    & .MuiChip-root {
        background: ${props => props.theme.palette.warning.main};
        color: ${props => props.theme.palette.warning.contrastText};
    }
`);

interface Props {
    data: GridConfigurationData;
}

export const GridConfigurationForm = (props: Props) => {
    const { data, update, schema, isLoading, hasChanges, save, launch, cancel } = props.data;
    const { arbiter_links_enabled: links_enabled, third_place_game_allowed } = useGridSettings();

    const { controls } = useItemWithControls({ ...props.data, update, }, { schema: schema });

    const hasPlayoff = (c: GridConfiguration) => !!c.has_playoff;
    const hasRating = (c: GridConfiguration) => !!c.has_rating_rounds;

    return (<>
        <ActionRow itemMarginTop="0">
            {isLoading && <LoadingIndicator />}
            <OccupyFreeSpace />
            {hasChanges && <SaveButton action={save} />}
            {data.is_launched &&
                <DeleteButton
                    preventGoBack
                    remove={cancel.run}
                    title={<FormattedMessage id="contests.rounds.config.labels.cancel_confirmation_title" />}
                    hint={<FormattedMessage id="contests.rounds.config.labels.cancel_confirmation_explanation" />}
                    button={<Button color="secondary"><FormattedMessage id="contests.rounds.config.labels.cancel" /></Button>}
                    />}
            <Button color="primary" {...launch.buttonProps}>
                <FormattedMessage id={data.is_launched ? "contests.rounds.config.labels.is_launched" : "contests.rounds.config.labels.launch"} />
            </Button>
        </ActionRow>
        <ProblemsContainer>
            {(data.problems || []).map(p => <Chip key={p} label={<FormattedMessage id={p} />} />)}
        </ProblemsContainer>
        <FormGrid key="main" columns="1fr 1fr" forceEvenColumns>
            <FormGrid columns="1fr">
                {controls([
                    ["has_rating_rounds", { disabled: data.is_launched }],
                    ["rating_group_kind", { visibleIf: hasRating, labelIdPrefix: "contests.rounds.config.labels.rating_group_kind_values" }],
                    ["rating_grouping_rule", { visibleIf: hasRating, labelIdPrefix: "contests.rounds.config.labels.rating_grouping_rule_values" }],
                    ["rating_player_rounds_cnt", { disabled: data.is_launched, visibleIf: hasRating }],
                    ["rating_round_title", { visibleIf: hasRating, hint: <FormattedMessage id="contests.rounds.config.labels.round_title_hint" /> }],
                    ["players_accepted_cnt", { disabled: data.is_launched, visibleIf: hasRating }],
                    ["rating_rounds_cnt", { visibleIf: hasRating, disabled: true }],
                ])}
            </FormGrid>
            
            <FormGrid columns="1fr">
                {controls([
                    ["has_playoff", { disabled: data.is_launched }],
                    ["playoff_stages_cnt", { disabled: data.is_launched, visibleIf: hasPlayoff }],
                    ["playoff_team_matching_rule", { visibleIf: hasPlayoff, labelIdPrefix: "contests.rounds.config.labels.rating_grouping_rule_values" }],
                    ["playoff_has_third_place_game", { disabled: data.is_launched, visibleIf: x => !!third_place_game_allowed && hasPlayoff(x) }],
                    ["playoff_round_title_po", { visibleIf: hasPlayoff, hint: <FormattedMessage id="contests.rounds.config.labels.round_title_hint" /> }],
                    ["playoff_round_title_final", { visibleIf: hasPlayoff }],
                    ["playoff_round_title_third", { visibleIf: (c: GridConfiguration) => hasPlayoff(c) && c.playoff_has_third_place_game }],
                    ["playoff_teams_required", { visibleIf: hasPlayoff, disabled: true, }],
                ])}
            </FormGrid>
        </FormGrid>

        <Divider />

        <FormGrid key="links" columns="1fr 1fr" forceEvenColumns>
            {links_enabled && controls([
                ["arbiter_access_url", { hint: <FormattedMessage id="contests.rounds.config.labels.access_url_hint" />}],
                ["player_access_url", { hint: <FormattedMessage id="contests.rounds.config.labels.access_url_hint" />}],
            ])}
        </FormGrid>

        <FormGrid key="more" columns="1fr 1fr" forceEvenColumns noMargin>
            {controls([
                ["avoid_player_conflicts"],
            ])}
        </FormGrid>
    </>);
}
