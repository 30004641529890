import React, { Fragment, useState } from 'react';
import styled from '@emotion/styled';
import { Button, IconButton, Typography, withTheme } from '@material-ui/core';
import { Add, DeleteOutlined } from '@material-ui/icons';
import { ActionRow, OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { SearchField } from '../../../toolympus/components/primitives/SearchField';
import { SimpleActions } from '../../../toolympus/components/StatusAction';
import { DocumentAssessmentListData, DocumentScoring, TeamAssess, useAssignmentActions, useDocumentsAssessmentList } from './useDocumentsAssessmentList';
import { DocumentInfo } from '../DocumentsControls';
import { DocumentAccess, DocumentNames } from '../../Team/useTeamDocuments';
import { PenaltyEditPopup } from './PenaltyEditPopup';
import { JudgeSelectionPopup } from './JudgeSelectionPopup';
import { DeleteButton } from '../../../toolympus/components/primitives/DeleteButton';
import { AcceptFinalsPopup } from './AcceptFinalsPopup';

const ListWrapper = styled.ul`
    list-style: none;
`;

const ItemWrapper = withTheme(styled.li`
    border-bottom: 1px solid ${props => props.theme.palette.grey[200]};
    padding: 0.5rem 0;

    & > div:first-child {
        display: grid;
        grid-template-columns: 30px 50px 50px repeat(5, 1fr);
        gap: 0.5rem;
        align-items: baseline;
        cursor: pointer;
    }
`);

const Details = styled.div`
    padding: 0.5rem 1rem;
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    & > :last-child {
        align-self: flex-end;
    }
`;

const JudgesWrapper = styled.div`
    padding: 0.25rem 0.5rem;
    gap: 0.25rem 0.5rem;
    display: grid;
    grid-template-columns: max-content max-content max-content;
    justify-items: flex-start;
    align-items: center;
`;

interface DocInfoProps {
    team: TeamAssess;
    document: DocumentAccess;
    download: DocumentAssessmentListData["download"];
    assignments: DocumentScoring[];
    openPenaltyEdit?: (d: DocumentAccess) => void;
    openAddJudge?: (d: DocumentAccess) => void;
    removeJudge?: (d: DocumentAccess, jid: string) => Promise<any>;
}

const DocInfo = ({ team, document, download, assignments, openPenaltyEdit, openAddJudge, removeJudge }: DocInfoProps) => {
    return <>
        <DocumentInfo
            key={document.document_kind}
            label={DocumentNames[document.document_kind as 'doc1' | 'doc2']}
            document={document}
            download={() => download(team, document)}
            showPenalties
            openPenaltyEdit={openPenaltyEdit}
            />
        <Typography variant="caption">Судьи</Typography>
        <JudgesWrapper>
            {assignments.map(a => (<Fragment key={a._id}>
                <Typography variant="caption">{a.judge_name}</Typography>
                <Typography variant="caption">{a.score_total || "-"}</Typography>
                <DeleteButton
                    button={<IconButton size="small"><DeleteOutlined /></IconButton>}
                    remove={() => {
                        if(removeJudge) {
                            return removeJudge(document, a.judge_id);
                        }
                        return Promise.resolve({});
                    }}
                    preventGoBack
                    title="Снять судью?"
                    hint={`Судья ${a.judge_name} будет снят с оценки документа команды ${team.number}. Если он(а) уже проставил(а) оценки, они будут удалены.`}
                    />
            </Fragment>))}
            <IconButton size="small" onClick={() => openAddJudge && openAddJudge(document)}><Add /></IconButton>
        </JudgesWrapper>
    </>
}

interface TeamProps {
    idx: number;
    team: TeamAssess;
    isExpanded: boolean;
    toggleExpanded: (v: boolean) => void;
    data: DocumentAssessmentListData;
}

const calcScoresDiff = (scores: number[]) => {
    if(scores.length !== 2) {
        return undefined;
    } else {
        return Math.abs(scores[0] - scores[1]);
    }
}

const TeamDisplay = (props: TeamProps) => {
    const {
        idx,
        team,
        isExpanded,
        toggleExpanded,
        data,
    } = props;

    const scoreClean = (team.documents.doc1?.score_avg || 0) + (team.documents.doc2?.score_avg || 0);

    const doc1Assignments = (team.documents.doc1?.scoring || []);
    const doc2Assignments = (team.documents.doc2?.scoring || []);

    const doc1Scores = (team.documents.doc1?.scoring || []).filter(s => !!s.score_total).map(s => s.score_total);
    const doc2Scores = (team.documents.doc2?.scoring || []).filter(s => !!s.score_total).map(s => s.score_total);

    const totalPenalty = (team.documents?.doc1?.info?.technical_penalty || 0) +
        (team.documents.doc1?.info?.late_penalty || 0) +
        (team.documents?.doc2?.info?.technical_penalty || 0) +
        (team.documents.doc2?.info?.late_penalty || 0);

    const doc1ScoreDiff = calcScoresDiff(doc1Scores);
    const doc2ScoreDiff = calcScoresDiff(doc2Scores);

    const needsThird = (doc1ScoreDiff && doc1ScoreDiff >= 20) || (doc2ScoreDiff && doc2ScoreDiff >= 20);
    const notAssigned2 = doc1Assignments.length < 2 || doc2Assignments.length < 2;
    const allAssessed = !notAssigned2 && !needsThird && doc1Assignments.length === doc1Scores.length && doc2Assignments.length === doc2Scores.length;

    const openPenaltyEdit = (doc: DocumentAccess) => data.penaltyEdit.startEditing({
        _id: doc._id,
        kind: doc.document_kind,
        team_number: team.number,
        points: doc.info.technical_penalty,
        comment: doc.info.comment,
    });

    const openAddJudge = (doc: DocumentAccess) => data.judgeAssignment.open(doc);

    return <ItemWrapper onClick={() => toggleExpanded(true)} style={{
        background: allAssessed ? '#00cc0020' : notAssigned2 ? '#cc000020' : needsThird ? '#cc990020' : 'transparent',
    }}>
        <div>
            <Typography variant="caption">{idx+1}.</Typography>
            <Typography>{team.number}</Typography>

            <Typography style={{ fontWeight: 500 }}>{(team.score_result || 0).toFixed(2)}</Typography>

            <Typography><Typography variant="caption">Штрафы:</Typography> {totalPenalty}</Typography>
            <Typography><Typography variant="caption">Оценка:</Typography> {scoreClean.toFixed(2)}</Typography>
            <Typography><Typography variant="caption">Назначено:</Typography> {doc1Assignments.length} | {doc2Assignments.length}</Typography>
            <Typography><Typography variant="caption">Оценено:</Typography> {doc1Scores.length} | {doc2Scores.length}</Typography>
            <Typography><Typography variant="caption">Расхождение:</Typography> {doc1ScoreDiff || '-'} | {doc2ScoreDiff || '-'}</Typography>
        </div>
        {isExpanded && (<Details>
            <DocInfo
                team={team} document={team.documents.doc1} assignments={doc1Assignments}
                download={data.download}
                openPenaltyEdit={openPenaltyEdit}
                openAddJudge={openAddJudge}
                removeJudge={(d,jid) => data.judgeAssignment.removeJudge(d, jid)}
                />
            <DocInfo
                team={team} document={team.documents.doc2} assignments={doc2Assignments}
                download={data.download}
                openPenaltyEdit={openPenaltyEdit}
                openAddJudge={openAddJudge}
                removeJudge={(d,jid) => data.judgeAssignment.removeJudge(d, jid)}
                />

            <Button size="small" onClick={e => { e.stopPropagation(); toggleExpanded(false); }}>закрыть</Button>
        </Details>)}
    </ItemWrapper>
}


export const DocumentsAssessmentList = () => {
    const data = useDocumentsAssessmentList();
    const actions = useAssignmentActions(data);

    const [isTeamExpanded, setIsTeamExpanded] = useState<Record<string,boolean>>({});

    return (<>
        <ActionRow itemMarginTop="0" alignItems="baseline">
            <Button onClick={() => setIsTeamExpanded(data.data.reduce((r,t) => ({ ...r, [t._id]: true }), {}))}>
                раскрыть все
            </Button>
            <OccupyFreeSpace/>
            {data.isLoading && <LoadingIndicator />}
            <SimpleActions isLoading={false} actions={actions} />
            <SearchField filter={data.filter} setFilter={data.setFilter} doSearch={() => { }} noButton small />
        </ActionRow>
        
        <ListWrapper>
            {data.data.map((t,i) => (
                <TeamDisplay
                    key={t._id}
                    idx={i}
                    team={t}
                    isExpanded={isTeamExpanded[t._id]}
                    toggleExpanded={v => setIsTeamExpanded(x => ({ ...x, [t._id]: v }))}
                    data={data}
                    />
            ))}
        </ListWrapper>
        <PenaltyEditPopup data={data.penaltyEdit} />
        <AcceptFinalsPopup data={data.acceptFinals} />
        <JudgeSelectionPopup
            data={data.judgeAssignment.judges}
            selectJudge={(jid) => {
                if(data.judgeAssignment.item) {
                    return data.judgeAssignment.assignJudge(data.judgeAssignment.item, jid);
                }
                return Promise.resolve({});
            }}
            state={data.judgeAssignment}
            />
    </>);
}
