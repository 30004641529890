import { Button, List, ListItem, ListItemText } from '@material-ui/core';
import { DescriptionOutlined } from '@material-ui/icons';
import React from 'react';
import styled from '@emotion/styled';
import { downloadFile } from '../../toolympus/api/core';
import { Component, PageContainer } from '../../toolympus/components/Pages';
import { FormWrapper } from '../../toolympus/components/primitives/Forms';
import { FormControl, useFormats } from '../../toolympus/components/schemed';
import { MarkdownDisplay } from '../../toolympus/components/schemed/Markdown';
import { utc } from '../../toolympus/components/timezone';
import { FieldType } from '../../toolympus/hooks/useSchema';
import { UserCaseQuestionsData, useUserCaseQuestions } from './useUserCaseQuestions';

const CaseLinkWrapper = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    cursor: pointer;

    & > :first-child {
        margin-right: 0.25em;
    }
`;

const CaseLink = (c: Component) => {
    const downloadCase = () => {
        downloadFile('/api/files/alias/case', 'ХрустальнаяФемида2021.pdf');
    }

    return (
        <CaseLinkWrapper onClick={downloadCase}>
            <DescriptionOutlined fontSize="large" />
            <MarkdownDisplay text={c.content_text} />
        </CaseLinkWrapper>
    )
}

const QuestionsBlockWrapper = styled.div`
`;

const QuestionForm = styled.div`
    display: flex;
    flex-flow: row;
    gap: 12px;
    align-items: flex-end;

    & > :nth-child(1) {
        flex-grow: 1;
    }
    & > :nth-child(2) {
        height: max-content;
    }
`;

const QuestionsBlock = ({ questions }: { questions: UserCaseQuestionsData}) => {
    const { newQuestion, updateNewQuestion, data: qs, canSubmit, submitQuestion } = questions;
    const formats = useFormats();
    return (
        <QuestionsBlockWrapper>
            {qs.length > 0 && <List>
                {qs.map(({ question, created_datetime }) => (
                    <ListItem>
                        <ListItemText
                            primary={question}
                            secondary={formats.formatDatetimeShort(utc.toLocal(created_datetime))}
                            />
                    </ListItem>
                ))}
            </List>}

            <QuestionForm>
                <FormControl
                    field="question"
                    schema={{ type: FieldType.textlong, label: "Вопрос" }}
                    row={{ question: newQuestion }}
                    onChange={(o,c) => updateNewQuestion(c.question)}
                    />
                <Button variant="contained" color="primary" onClick={submitQuestion} disabled={!canSubmit}>
                    Отправить
                </Button>
            </QuestionForm>
        </QuestionsBlockWrapper>
    )
}

export const CasePage = () => {
    const questions = useUserCaseQuestions();

    const RenderQuestionsBlock = () => <QuestionsBlock questions={questions} />;

    return (
        <FormWrapper>
            <PageContainer apiPath="/api/page" slug="case" customComponents={{ 'caselink': CaseLink, 'questions': RenderQuestionsBlock }}  />
        </FormWrapper>
    );
}
