import React, { useMemo } from 'react';
import { GridSettingsProvider } from '../../toolympus/components/Contests/Grid';
import { PartyRoundsList } from '../../toolympus/components/Contests/Grid/Parties/PartyRoundsList';
import { usePartyRounds } from '../../toolympus/components/Contests/Grid/Parties/usePartyRounds';
import { Round } from '../../toolympus/components/Contests/Grid/types';
import { PageContainer } from '../../toolympus/components/Pages';
import { FormWrapper } from '../../toolympus/components/primitives/Forms';
import { MarkdownDisplay } from '../../toolympus/components/schemed/Markdown';
import { FemidaGridSettings } from '../Admin/Finals/FemidaGridSettings';
import { JudgeRoundSpeakers } from '../Admin/Finals/JudgeRoundsPage';
import { SectionTitle } from '../Common.styles';
import { JudgesContactList } from './JudgesContactList';
import { useJudgeContactList } from './useJudgeContactList';
import { useSecretaryProfile } from './useSecretaryProfile';

/**
 * Rounds list page for secretaries - based on `PublicRoundsListContainer`
 */
export const SecretaryRoundsPage = () => {
    const data = usePartyRounds("/api/admin/finals", "public");
    const profile = useSecretaryProfile();
    
    const dataWithOnlyOwnRounds = useMemo(() => {
        const myRounds = profile.data.rounds || [];
        const myRoundsOnly = (r: Round) => myRounds.includes(r._id);
        return {
            data: data.data.filter(myRoundsOnly),
            byStages: data.byStages.reduce<[string, Round[]][]>((result, [stage, rounds]) => [...result, [stage, rounds.filter(myRoundsOnly)]], []),
        }
    }, [data.data, data.byStages, profile.data])

    const judges = useJudgeContactList();

    const myRoundsJudgesIds = useMemo(() => {
        return dataWithOnlyOwnRounds.data.map(r => Object.values(r.arbiters).map(a => a._id)).flat();
    }, [dataWithOnlyOwnRounds]);

    const rounds = <>
        <PartyRoundsList data={{ ...data, ...dataWithOnlyOwnRounds }} search hideConference />
        {judges.data.length > 0 && <SectionTitle title="Контакты судей" titleVariant="h6" subtitle="кликните по e-mail или телефону, чтобы скопировать" />}
        <JudgesContactList judges={judges.data.filter(j => myRoundsJudgesIds.find(jMyId => jMyId === j._id))} />
    </>;


    return (
        <GridSettingsProvider settings={{
            ...FemidaGridSettings,
            round_player_info_component: JudgeRoundSpeakers,
            }}>
            <FormWrapper>
                <PageContainer
                    apiPath="/api/page"
                    slug="secretary-rounds"
                    customComponents={{
                        rounds: () => rounds,
                        guide: () => <MarkdownDisplay text={profile.data.guide || ""} />,
                    }}
                />
            </FormWrapper>
        </GridSettingsProvider>
    );
}
