import { Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { LoadingIndicator } from '../../components/primitives/LoadingIndicator';
import { EmailConfirmation, EmailConfirmationConfig, useEmailConfirmation } from './useEmailConfirmation';

interface Props {
    data: EmailConfirmation;
}

const Line = styled.div`
    display: flex;
    flex-flow: row;

    & > :first-child {
        margin-right: 1rem;
    }
`;

export const EmailConfirmationBlock = (props: Props) => {
    const { isLoading, isSuccess, isFailure } = props.data;
    return (
        <>
            {isLoading && 
                <Line>
                    <LoadingIndicator />
                    <Typography><FormattedMessage id="emailConfirmation.confirming" /></Typography>
                </Line>}
            {isSuccess && <Line>
                    <Typography><FormattedMessage id="emailConfirmation.success" /></Typography>
                </Line>}
            {isFailure && <Line>
                    <Typography><FormattedMessage id="emailConfirmation.failure" /></Typography>
                </Line>}
        </>
    );
}

interface ContainerProps {
    apiPath: string;
    config?: EmailConfirmationConfig;
}

export const EmailConfirmationContainer = ({ apiPath, config }: ContainerProps) => {
    const data = useEmailConfirmation(apiPath, config);
    return <EmailConfirmationBlock data={data} />
}
