import { usePage } from "../../toolympus/components/Pages";

export interface TitleTexts {
    caption: string;
    title: string;
    subtitle: string;

    year_caption: string;
    year_title: string;
    year_description: string;
}

export const useTitleTexts = (): TitleTexts => {
    const { isLoading, page } = usePage('/api/page-public', 'title-text');

    if(isLoading || !page || page.components.length < 6) {
        return {
            caption: "Конкурс",
            title: "«Хрустальная Фемида»",
            subtitle: "Всероссийский конкурс по конституционному правосудию среди студенческих команд высших юридических учебных заведений (юридических факультетов) России",
    
            year_caption: "2021/2022",
            year_title: "«Золотой ключик»,\nили Дело о реновации",
            year_description: "Конкурсное задание ставит перед командами проблему соответствия Конституции условий переселения жителей домов, включённых в программу реновации жилищного фонда, в современные квартиры, а также конституционности понятия «равнозначности» предоставляемого жилья.\n\nКомандам предстоит проанализировать конституционные и международно-правовые стандарты в этой сфере, практику зарубежных государств, а также подходы национальных судебных органов и органов международного правосудия к решению вопросов изъятия частной собственности и её возмещения.",
        }
    } else {
        const [caption, title, subtitle, year_caption, year_title, year_description] = page.components.map(c => c.content_text);
        return {
            caption,
            title,
            subtitle,
    
            year_caption,
            year_title,
            year_description,
        }
    }

    
}
