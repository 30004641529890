import { useState } from "react";
import { useValidationErrors, ValidationErrors } from "../components/schemed";
import { apiFetch, FetchTypes, FetchTypesX } from "./core";

interface BaseProps<ItemType, ResultType> {
    isEditing: boolean;
    item: ItemType | null;
    update: (changes: Partial<ItemType>) => void;
    save: () => Promise<ResultType>;
    cancel: () => void;
    isLoading: boolean;
    errors: ValidationErrors;
}

export interface NewItemProps<ItemType, ResultType> extends BaseProps<ItemType, ResultType> {
    startEditing: (defaults?: Partial<ItemType>) => void;
}

interface NewItemConfig<ItemType> {
    onChange?: (o: ItemType, changes: Partial<ItemType>) => Partial<ItemType>;
    httpMethod?: FetchTypesX;
}

export const useNewItem = <ItemType, ResultType, >(apiPath: string, defaultValue: ItemType, cfg?: NewItemConfig<ItemType>): NewItemProps<ItemType, ResultType> => {
    const [item, setItem] = useState<ItemType | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const errors = useValidationErrors();

    return {
        item,
        isEditing: !!item,
        startEditing: (defaults) => setItem({ ...defaultValue, ...defaults }),
        update: c => {
            if(item) {
                const changes = cfg?.onChange ? cfg.onChange(item, c) : c;
                setItem({ ...item, ...changes })
            }
        },
        cancel: () => setItem(null),
        save: () => {
            if(item) {
                setIsLoading(true);
                return apiFetch<ResultType>(apiPath, cfg?.httpMethod || "POST", item)
                    .then(r => {
                        setIsLoading(false);
                        setItem(null)
                        return r;
                    })
                    .catch(e => {
                        setIsLoading(false);
                        errors.handleErrors(e);
                        throw e;
                    });
            } else {
                return Promise.reject({ response: { error_code: "error.general"}});
            }
        },
        isLoading,
        errors,

    };
}

export interface EditItemProps<ItemType> extends BaseProps<ItemType, ItemType> {
    startEditing: (item: ItemType) => void;
    setItem: (item: ItemType) => void;
}

export const useEditItem = <ItemType, >(apiPath: string, idField: keyof ItemType): EditItemProps<ItemType> => {
    const [item, setItem] = useState<ItemType | null>(null);
    const [changes, setChanges] = useState<Partial<ItemType>>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const errors = useValidationErrors();

    return {
        item,
        setItem,
        isEditing: !!item,
        startEditing: (item: ItemType) => { setItem(item); setChanges({}); },
        update: c => {
            if(item) {
                setItem({ ...item, ...c });
                setChanges({ ...changes, ...c });
            }
        },
        cancel: () => { setItem(null); setChanges({}); },
        save: () => {
            if(item) {
                setIsLoading(true);
                return apiFetch<ItemType>(`${apiPath}/${item[idField]}`, FetchTypes.PUT, changes)
                    .then(r => {
                        setIsLoading(false);
                        setItem(null);
                        setChanges({});
                        return r;
                    })
                    .catch(e => {
                        setIsLoading(false);
                        errors.handleErrors(e);
                        throw e;
                    });
            } else {
                return Promise.reject({ response: { error_code: "error.general"}});
            }
        },
        isLoading,
        errors,

    };
}


export interface OpenItemProps<ItemType> {
    item: ItemType | null;
    isOpen: boolean;
    cancel: () => void;
    open: (item: ItemType) => void;
}

export const useOpenItem = <ItemType,>(): OpenItemProps<ItemType> => {
    const [item, setItem] = useState<ItemType | null>(null);

    return {
        item,
        isOpen: !!item,
        cancel: () => setItem(null),
        open: item => setItem(item),
    }
}
