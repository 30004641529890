import { apiFetch, FetchTypes } from "../../../api/core";
import { Action, useAction } from "../../../api/useAction";
import { CrudItemData, useCrudItem } from "../../../api/useSimpleCrud";
import { Schema, useSingleSchema } from "../../../hooks/useSchema";

export interface Stage {
    config_code: string;
    kind: string;
    code: string;
    sort_order: number;
    is_launched: boolean;
    is_finished: boolean;
    problems?: Record<string, any>;
}

export interface GridConfiguration {
    code: string;
    has_playoff: boolean;
    playoff_stages_cnt: number;
    playoff_teams_required: number;
    playoff_team_matching_rule: string;
    playoff_has_third_place_game: boolean;
    playoff_round_title_final: string;
    playoff_round_title_third: string;
    playoff_round_title_po: string;

    has_rating_rounds: boolean;
    rating_group_kind: string;
    rating_grouping_rule: string;
    rating_player_rounds_cnt: number;
    rating_round_title: string;
    players_accepted_cnt: number;
    rating_rounds_cnt: number;
    problems: string[];
    is_launched: boolean;
    stages: Stage[];
}

export interface GridConfigurationData extends CrudItemData<GridConfiguration> {
    apiPath: string;
    schema: Schema;
    launch: Action<any>;
    cancel: Action<any>;
}

export const useGridConfiguration = (apiPath: string): GridConfigurationData => {
    const data = useCrudItem<GridConfiguration>(apiPath, {
        defaultValue: { stages: [] as Stage[] } as GridConfiguration,
    });

    const { schema } = useSingleSchema(`${apiPath}/uiconfig`);

    const launch = useAction(
        () => apiFetch<any>(`${apiPath}/launch`, FetchTypes.POST).then(() => data.reload()),
        !data.data.is_launched && !data.hasChanges);
    const cancel = useAction(
        () => apiFetch<any>(`${apiPath}/launch`, FetchTypes.DELETE).then(() => data.reload()),
        !data.data.is_launched);

    return {
        ...data,
        apiPath,
        schema,
        launch,
        cancel,
    }
}