import { useState } from "react";
import { apiFetch, FetchTypes } from "../../toolympus/api/core";
import { useValidationErrors } from "../../toolympus/components/schemed";
import { FieldType, mergeSchema, useSchema } from "../../toolympus/hooks/useSchema";
import { RegistrationData } from "./useTeamRegistrationStageOne";

export interface Secretary {
    email: string;
    _id: string;
    lastname: string;
    firstname: string;
    middlename: string;
    phone: string;
    university: string;
    affiliation: string;
    guide?: string;
    experience_secretary: string;
    personal_data_consent: boolean;
    approved: boolean;
    rounds?: string[];
    created_datetime: string;
}

interface SecretaryRegistration extends Omit<Secretary, '_id' | 'created_datetime' | 'approved'> {
    password: string;
    passwordRepeat: string;
};


interface SecretaryRegistrationData extends RegistrationData<SecretaryRegistration> {
    isSubmitted: boolean;
}

export const SecretarySchemaChanges = {
    password: { type: FieldType.password },
    passwordRepeat: { type: FieldType.password, label: "Пароль ещё раз" },
    university: { label: "Название ВУЗа полностью" },
    affiliation: {
        label: "Аффилированность с ВУЗами или командами",
        type: FieldType.textlong,
    },
    experience_secretary: {
        label: "Опыт участия в Конкурсе в качестве судебного секретаря",
        type: FieldType.textlong,
    },

    personal_data_consent: { label: "Я даю согласие на обработку персональных данных" },
};

export const useSecretaryRegistration = (): SecretaryRegistrationData => {
    const { secretary: secretarySchema } = useSchema();

    const schema = mergeSchema(secretarySchema, SecretarySchemaChanges);

    const [data, setData] = useState<SecretaryRegistration>({
        email: "",
        password: "",
        passwordRepeat: "",
        lastname: "",
        firstname: "",
        middlename: "",
        phone: "",
        university: "",
        affiliation: "",
        experience_secretary: "",
        personal_data_consent: false,
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const errors = useValidationErrors();

    const canSubmit = (
        Object.entries(data).every(([k,v]) => v && v !== "")
        && data.password === data.passwordRepeat
    );

    const submit = () => {
        setIsLoading(true);
        errors.clearErrors();
        return apiFetch<string>('/api/register/secretary', FetchTypes.POST, data)
            .then(() => {
                setIsLoading(false);
                setIsSubmitted(true);
            })
            .catch(e => {
                errors.captureErrors(e);
                setIsLoading(false);
                throw e;
            })
    }

    return {
        schema,
        data,
        update: c => setData({ ...data, ...c }),
        canSubmit,
        submit,
        isLoading,
        isSubmitted,
        errors,
    }
}