import React, { useEffect } from 'react';
import { useScoredRound } from '../../../toolympus/components/Contests/Grid/Parties/ScoredRoundContext';
import { ScoringControlProps } from '../../../toolympus/components/Contests/ScoringSheets';
import { Select } from '../../../toolympus/components/schemed/Select';
import { FieldType } from '../../../toolympus/hooks/useSchema';
import { RoundSpeakers } from './useTeamRoundSpeakers';



export const SpeakerSelect = (props: ScoringControlProps) => {
    const { data: { scores, update }, criteria } = props;
    const { round, score } = useScoredRound();
    
    
    const team = round?._id && score?.position ? round.players[score.position] : null;
    const speakers = ((round?._id && score?.position && round.info?.speakers && round.info?.speakers[score.position]) || {}) as RoundSpeakers;

    const speakersX = Object.values(speakers).map(m => { const name = `${m.firstname} ${m.lastname}`; return { label: name, value: `${name}, ${team?.display_name}`}})

    useEffect(() => {
        if(!scores[criteria?._id || ""]) {
            const speaker = score?.score_label === "Выступление 1" ? speakersX[0]?.value : speakersX[1]?.value;
            if(speaker) {
                update({ [criteria?._id || ""]: speaker });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Select
                field="speaker"
                row={{ speaker: scores[criteria?._id || ""]}}
                schema={{ type: FieldType.select,  label: " ", values: speakersX, valueDict: speakersX.reduce((r,x) => ({ ...r, [x.value]: x.label }), {})}}
                onChange={(o,c) => update({ [criteria?._id || ""]: c.speaker })}
                />
}
