import React from 'react';
import { Rating, RatingTable } from '../../../toolympus/components/Contests/Grid/RatingTable';
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData"

interface SpeakerResult extends Rating {
    speaker: string;
}

interface Props {
    isActive?: boolean;
}

export const SpeakersResults = ({ isActive }: Props) => {
    const data = useLoadedData<SpeakerResult[]>("/api/admin/finals/speaker-stats", [], isActive);

    return <RatingTable data={{
        ...data,
        data: data.data.map(s => ({ ...s, _id: s.speaker, display_name: s.speaker })),
        setData: x => data.setData(x.map(r => ({ ...r, speaker: r.display_name })))}
    }
    />
}