import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { apiFetch, apiUploadFile, downloadFile, FetchTypes } from "../../toolympus/api/core";
import { openFileUploader } from "../../toolympus/api/files";
import { CrudItemData, useCrudItem } from "../../toolympus/api/useSimpleCrud";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useSchema, Schema, mergeSchema } from "../../toolympus/hooks/useSchema";
import { DefaultUser } from "../../toolympus/userContext/UserContext";
import { useContestState } from "../../useContestState";
import { Team, TeamSchemaChanges } from "../Public/useTeamRegistrationStageOne";

interface Person {
    lastname: string;
    firstname: string;
    middlename: string;
    email: string;
    phone: string;
    is_contact: boolean;
}

interface TeamMember extends Person {
    study_year: number;
    participates_finals?: boolean;
}

interface Coach extends Person {
    position: string;
}

export interface TeamProfileData extends CrudItemData<Team> {
    updateMember: (idx: number, changes: Partial<TeamMember>) => void;
    addMember: () => void;
    updateCoach: (idx: number, changes: Partial<Coach>) => void;

    markFixesDone: () => void;

    downloadPdf: () => void;
    uploadPdf: () => void;

    teamSchema: Schema;
    memberSchema: Schema;
    coachSchema: Schema;

    isFinalsMembersSelectionAvailable: boolean;
    isMembersForFinalsSet: boolean;
    isMembersForFinalsChanged: boolean;
    membersForFinals: TeamMember[];
    updateMemberForFinal: (idx: number, willParticipate: boolean) => void;
    saveMembersForFinals: () => void;
}

export const useTeamProfileStatus = (user: DefaultUser | null) => {
    const isTeam = (user?.roles || []).includes("team");
    const teamProfile = useLoadedData<Team>("/api/team/my", { } as Team, isTeam);

    return {
        number: teamProfile.data.number || "",
        isFinalsParticipant: !!teamProfile.data.finals_stage,
    }
}

export const useTeamProfile = (): TeamProfileData => {
    const { enqueueSnackbar } = useSnackbar();
    const data = useCrudItem<Team>('/api/team/my', {
        defaultValue: {} as Team,
        prepareChanges: c => ({
                ...c,
                members: c.members ? c.members.filter(m => m.lastname && m.lastname.length) : undefined,
                coaches: c.coaches ? c.coaches.filter(c => c.lastname && c.lastname.length) : undefined,
            }),
    });
    
    const team = data.data;

    team.number = team.number || "не присвоен";

    if(!team.members || team.members.length < 4) {
        team.members = [...(team.members || []), ...Array(4).fill(1).map(() => ({}))].slice(0,4);
    }
    if(!team.coaches || team.coaches.length < 2) {
        team.coaches = [...(team.coaches || []), ...Array(2).fill(1).map(() => ({}))].slice(0,2);
    }

    const { team: teamSchema, member: memberSchema, coach: coachSchema, } = useSchema();

    
    const state = useContestState();

    const updateMember = (idx: number, changes: Partial<TeamMember>) => {
        let updated = team.members.slice();
        let updatedCoaches = undefined;
        if(changes.is_contact) {
            console.log('is_contact upd');
            updated = updated.map(m => ({ ...m, is_contact: false }));
            updatedCoaches = team.coaches.map(c => ({ ...c, is_contact: false }));
        }
        updated.splice(idx, 1, { ...updated[idx], ...changes });
        data.update(updatedCoaches ? { members: updated, coaches: updatedCoaches } : { members: updated });
    }

    const updateCoach = (idx: number, changes: Partial<Coach>) => {
        let updated = team.coaches.slice();

        let updatedMembers = undefined;
        if(changes.is_contact) {
            updated = updated.map(m => ({ ...m, is_contact: false }));
            updatedMembers = team.members.map(c => ({ ...c, is_contact: false }));
        }
        updated.splice(idx, 1, { ...updated[idx], ...changes });
        data.update(updatedMembers ? { coaches: updated, members: updatedMembers } : { coaches: updated });
    }

    const addMember = () => {
        if(team.members.length < 6) {
            data.update({ members: [...team.members, {}]});
        }
    }

    const downloadPdf = () => {
        downloadFile(`/api/team/my/pdf`, "team.pdf");
    }

    const uploadPdf = () => {
        openFileUploader(f => apiUploadFile(`/api/team/my/pdf/scan/${data.data._id}`, FetchTypes.PUT, "file", f)
            .then(() => data.reload()));
    }

    const markFixesDone = () => {
        const ensureSaved = data.hasChanges ? data.save : () => Promise.resolve(data.data);
        ensureSaved()
            .then(() => apiFetch<Team>('/api/team/my/fixed', FetchTypes.POST, null))
            .then(t => data.reload());
    }

    const hasContact = () => {
        const fromMembers = team.members.find(m => m.is_contact);
        const fromCoaches = team.coaches.find(m => m.is_contact);

        return fromMembers || fromCoaches;
    }

    const save = () => {
        if(!hasContact()) {
            enqueueSnackbar('Необходимо выбрать контактное лицо', { variant: 'error', autoHideDuration: 3000 });
            return Promise.resolve(team);
        }
        return data.save();
    }

    const membersLoaded = (team.members || []).filter(m => m.lastname && m.lastname.length);
    const isFinalsMembersSelectionAvailable = !!state.data.finals_members_update_enabled && !!team.finals_stage;
    const isMembersForFinalsSet = membersLoaded.every(m => m.participates_finals !== null && m.participates_finals !== undefined);

    const [membersForFinals, setMembersForFinals] = useState<TeamMember[]>([]);

    const isMembersForFinalsChanged = membersForFinals.map((m,i) => m.participates_finals !== membersLoaded[i].participates_finals).some(x => x);

    useEffect(() => {
        if(team._id) {
            setMembersForFinals(membersLoaded.map(m => ({ ...m, participates_finals: m.participates_finals || false })))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team._id, team.members]);

    const updateMemberForFinal = (idx: number, participates_finals: boolean) => {
        setMembersForFinals(ms => ms.map((m,i) => i === idx ? { ...m, participates_finals } : m));
    }

    const saveMembersForFinals = () => {
        apiFetch<Team>("/api/team/my/finals-members", FetchTypes.PUT, membersForFinals)
            .then(() => data.reload());
    }


    return {
        ...data,
        save,
        data: team,
        updateMember,
        addMember,
        updateCoach,
        teamSchema: mergeSchema(teamSchema, TeamSchemaChanges),
        memberSchema,
        coachSchema,
        markFixesDone,
        downloadPdf,
        uploadPdf,

        isFinalsMembersSelectionAvailable,
        isMembersForFinalsSet,
        isMembersForFinalsChanged,
        membersForFinals,
        updateMemberForFinal,
        saveMembersForFinals,
    }
}