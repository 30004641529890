import React from 'react';
import { IconButton } from '@material-ui/core';
import { ForumOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';

export const GoToChatButton = ({ to }: { to: string}) => {
    return (
        <Link to={to}>
            <IconButton size="small">
                <ForumOutlined />
            </IconButton>
        </Link>
    );
}
