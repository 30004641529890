import React from 'react';
import { RegistrationsListPage } from '../../toolympus/components/Contests/Registrations';
import { PseudoLink } from '../../toolympus/components/primitives/Common.styles';
import { Tooltip } from '../../toolympus/components/primitives/Tooltip';
import { TableRowButtons } from '../../toolympus/components/schemed';
import { GoToChatButton } from './Common';
import { RoundsDictionaryProvider } from './RoundsDictionaryProvider';
import { SecretaryReviewPopup } from './SecretaryReviewPopup';
import { useSecretaryList } from './useSecretaryList';

export const SecretaryListPageUnwrapped = () => {
    const data = useSecretaryList();

    return (<>
        <RegistrationsListPage
            data={data}
            fields={{
                defaultFields: [
                    "email",
                    "lastname",
                    "firstname",
                    "middlename",
                    "__approval",
                    "university",
                    "affiliation",
                    "experience_secretary",
                    "phone",
                    "created_datetime",
                    "chat_button"
                ],
                extraSettings: {
                    created_datetime: { utcToLocal: true, },
                    chat_button: { label: "Чат" },
                },
                outOfSchemaFields: [
                    "chat_button",
                ],
                schema: data.schema,
                storageKey: "_femida_secretary_keys",
            }}
            title="Секретари"
            itemDescription={t => `${t.lastname} ${t.firstname} (${t.email})`}
            fieldElement={f => {
                if(f === "email" || f === "lastname") {
                    return (row, _, orig) => <PseudoLink onClick={() =>  data.review.open(row._id)}>{orig}</PseudoLink>
                }
                if(f === "chat_button") {
                    return row => <TableRowButtons>
                                <Tooltip text="Чат" withWrapper>
                                    <GoToChatButton to={`/admin/chat-org/${row._id}`} />
                                </Tooltip>
                            </TableRowButtons>;
                }
            }}
            />
            <SecretaryReviewPopup data={data.review} />
        </>);
}

export const SecretaryListPage = () => {
    return (
        <RoundsDictionaryProvider>
            <SecretaryListPageUnwrapped />
        </RoundsDictionaryProvider>
    )
}
