import React, { ReactNode, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Message, ChatBubbleOutline } from '@material-ui/icons';
import { useLoadedData } from '../../hooks/useLoadedData';
import { Settings, Comment, useComments } from './useComments';
import { CommentsDialog } from './CommentsForm';

export interface TableComments {
    buttonForRecord: (recordId: string) => ReactNode;
    popup: ReactNode;
}

type AllComments = Record<string, Comment[]>;


export const useTableComments = (apiPrefix: string, entity: string, settings?: Settings): TableComments => {
    const allComments = useLoadedData<AllComments>(`${apiPrefix}/${entity}`, {});
    const [activeRecordId, setActiveRecordId] = useState<string | null>(null);
    const activeRecordComments = useComments(apiPrefix, entity, activeRecordId || '', { ...settings, suppressLoad: activeRecordId === null });

    const buttonForRecord = (recordId: string) => (
        <IconButton onClick={() => { setActiveRecordId(recordId) }} size="small">
            {allComments.data[recordId] && allComments.data[recordId].length > 0 ? <Message /> : <ChatBubbleOutline />}
        </IconButton>
    );

    const popup = (<CommentsDialog
                        data={activeRecordComments}
                        isOpen={activeRecordId !== null}
                        setIsOpen={() => { setActiveRecordId(null); allComments.reload(); }} />);


    return {
        buttonForRecord,
        popup,
    };
}
