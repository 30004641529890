import React from 'react';
import { Paper, Typography, TypographyProps, withTheme } from '@material-ui/core';
import styled from '@emotion/styled';

export const PageWrapper = withTheme(styled(Paper)`
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow-y: auto;

    padding: 44px 40px;
    box-sizing: border-box;

    ${props => props.theme.breakpoints.down("md")} {
        padding: 10px;
    }

    .message .text p:not(:last-child) {
        margin-bottom: 1rem;
    }
`);

PageWrapper.defaultProps = { elevation: 0 };

export const Divider = styled.div`
    margin: 10px 0;
    background: rgba(0,0,0,0.22);
    height: 2px;
    width: 100%;
`;

export const Blank = styled.div`
    height: 22px;
    width: 100%;
`;

interface SectionTitleProps {
    title: string;
    subtitle?: string;
    titleVariant?: TypographyProps["variant"];
}

const SectionTitleWrapper = styled.div`
    margin: 32px 0 0;

    & > * {
        display: inline-block;
    }

    & > h4 {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 0;
    }

    & > span {
        margin-left: 12px;
        font-size: 12px;
        font-weight: 300;
        font-style: italic;
    }
`;

export const SectionTitle = ({ title, subtitle, titleVariant }: SectionTitleProps) => {
    return (
        <SectionTitleWrapper>
            <Typography variant={titleVariant || "h4"}>{title}</Typography>
            <Typography variant="caption">{subtitle}</Typography>
        </SectionTitleWrapper>
    )
}

