import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, Typography } from '@material-ui/core';
import { Code, DeleteOutlined, FileCopyOutlined, InfoOutlined, ShortText } from '@material-ui/icons';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useItemWithControls } from '../../api/useWithControls';
import { ActionRow, OccupyFreeSpace } from '../primitives/ActionRow';
import { useConfirmationDialog } from '../primitives/ConfirmationDialog';
import { FormGrid } from '../primitives/Forms';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { Dialog as DialogTooly } from '../primitives/Dialogs';
import { Tooltip } from '../primitives/Tooltip';
import { TargetDisplay } from './TargetDisplay';
import { TargetsSelection } from './TargetsSelection';
import { NotificationTask, NotificationTasksData } from './useNotificationTasks';
import { FormControl } from '../schemed';
import { FieldType } from '../../hooks/useSchema';
import { NotificationTasksListConfig } from './NotificationTaskList';

interface Props extends NotificationTasksListConfig {
    data: NotificationTasksData;
}

export const NotificationTaskPopup = (props: Props) => {
    const {
        isCreating,
        isEditing,
        cancelEdit,
        hasChanges,
        save,
        remove,

        schema,
        update,
        cloneEdited,
        execute,
        testExecute,
        isExecuting,

        targets,
    } = props.data;

    const [isTargetsOpen, setIsTargetsOpen] = useState<boolean>(false);
    const [isTestOpen, setIsTestOpen] = useState<boolean>(false);
    const [testEmail, setTestEmail] = useState<string>("");
    const [showHtmlBody, setShowHtmlBody] = useState<boolean>(false);

    const task = props.data.newTask || props.data.editTask || null;

    const { controls } = useItemWithControls<Partial<NotificationTask>>(
        { data: task || {} as Partial<NotificationTask>, update: update },
        { schema });

    const editedTask = props.data.editTask;

    const placeholders = targets.getPlaceholders((task?.targets || []));

    const confirmationDialog = useConfirmationDialog();


    return (<>
        <Dialog key="task" open={isCreating || isEditing} onClose={cancelEdit} fullWidth maxWidth="md">
            <DialogTitle>
                <ActionRow firstItemNoMargin>
                    <FormattedMessage id="notifications.task.controls.title_one" />

                    <OccupyFreeSpace />

                    {editedTask && !editedTask.is_executed && 
                        <Tooltip text_id="common.delete">
                            <IconButton onClick={() => remove(editedTask)} size="small">
                                <DeleteOutlined />
                            </IconButton>
                        </Tooltip>}

                    {!isCreating &&
                        <Tooltip text_id="common.copy">
                            <IconButton onClick={cloneEdited} size="small">
                                <FileCopyOutlined />
                            </IconButton>
                        </Tooltip>}

                    {editedTask && !editedTask.is_executed && 
                        <Button
                            color="primary"
                            onClick={() => setIsTestOpen(true)}
                            disabled={isExecuting || hasChanges || editedTask.targets.length === 0}
                            >
                            {isExecuting ? <LoadingIndicator sizeVariant="s" /> : <FormattedMessage id="notifications.task.controls.test_execute" />}
                        </Button>}

                    {editedTask && !editedTask.is_executed && 
                        <Button
                            color="primary"
                            onClick={() => confirmationDialog.open({
                                execute: () => execute(editedTask as NotificationTask),
                                title: <FormattedMessage id="notifications.task.controls.execute_confirmation" />,
                                confirmationLabel: <FormattedMessage id="notifications.task.controls.execute" />,
                            })}
                            disabled={isExecuting || hasChanges || editedTask.targets.length === 0}
                            >
                            {isExecuting ? <LoadingIndicator sizeVariant="s" /> : <FormattedMessage id="notifications.task.controls.execute" />}
                        </Button>}
                </ActionRow>
            </DialogTitle>
            {task && <DialogContent>
                <FormGrid columns="1fr" noMargin>
                    {controls([
                        ["title", { disabled: task.is_executed }],
                    ])}

                    <ActionRow firstItemNoMargin itemMarginTop="0" alignItems="end">
                        {controls([
                            ["email_template", { disabled: task.is_executed, wrapperStyle: { flex: "1 0 auto"} }],
                        ])}
                        <Tooltip text_id="notifications.task.controls.email_template_hint">
                            
                            <a href={props.emailsPagePath || ""}
                                target="_blank"
                                rel="noreferrer noopener"
                                style={{ color: "inherit", textDecoration: "none"}}>
                                <InfoOutlined style={{ marginBottom: "4px" }} color="action" />
                            </a>
                        </Tooltip>
                        <OccupyFreeSpace />
                        <Tooltip key="text" text_id="emails.popup.textHint">
                            <IconButton size="small" color={showHtmlBody ? "default" : "primary"} onClick={() => setShowHtmlBody(false)}><ShortText /></IconButton>
                        </Tooltip>
                        <Tooltip key="html" text_id="emails.popup.htmlHint">
                            <IconButton size="small" color={showHtmlBody ? "primary" : "default"} onClick={() => setShowHtmlBody(true)}><Code /></IconButton>
                        </Tooltip>
                    </ActionRow>
                    
                    {controls([
                        [showHtmlBody ? "message_html" : "message", { autoRows: true,  disabled: task.is_executed }],
                    ])}

                    {placeholders.availableOnAll.length > 0 && <FormHelperText>
                        <FormattedMessage id="notifications.task.controls.substitutions_hint" values={{ params: placeholders.availableOnAll.map(s => `{{${s}}}`).join(" ") }} />
                    </FormHelperText>}
                </FormGrid>
                
                <FormGrid columns="2fr 1fr">
                    {controls([
                        ["comment"],
                        ["executed_time", { disabled: true, utcToLocal: true, visibleIf: (t: NotificationTask) => t.is_executed }],
                    ])}
                </FormGrid>

                <FormGrid columns="1fr">
                    {(!isCreating || !!task.targets?.length) ?
                        <TargetDisplay
                            disabled={task.is_executed || isCreating}
                            targets={task.targets || []}
                            removeTarget={t => targets.removeTarget(t)}
                            openConfiguration={() => setIsTargetsOpen(true)} />
                        :
                        <Typography variant="caption"><FormattedMessage id="notifications.task.controls.save_to_edit_targets" /></Typography>}

                </FormGrid>
            </DialogContent>}

            <DialogActions>
                <Button onClick={cancelEdit}>
                    <FormattedMessage id="common.close" />
                </Button>
                <Button
                    onClick={save}
                    variant="contained"
                    color="primary"
                    disabled={!isCreating && !hasChanges}>
                    <FormattedMessage id="common.save" />
                </Button>
            </DialogActions>
        </Dialog>

        <DialogTooly
            isOpen={isTestOpen}
            close={() => setIsTestOpen(false)}
            dialogTitle={<FormattedMessage id="notifications.task.controls.test_execute" />}
            noFullscreen
            actions={<>
                <Button
                    color="primary"
                    onClick={() => {
                        if(editedTask) {
                            testExecute(editedTask, { email: testEmail});
                            setIsTestOpen(false);
                        }
                    }}
                    >
                    <FormattedMessage id="notifications.task.controls.execute" />
                </Button>
            </>}>

            <Typography><FormattedMessage id="notifications.task.controls.test_execute_hint" /></Typography>
            <FormControl
                field="email"
                row={{ email: testEmail }}
                onChange={(o,c) => setTestEmail(c.email)}
                schema={{ type: FieldType.text, label_id: "notifications.task.controls.test_execute_email_label" }}
                />
        </DialogTooly>

        <TargetsSelection
            isOpen={isTargetsOpen}
            close={() => setIsTargetsOpen(false)}
            targets={targets}
            />
    </>);
}
