import React, { ReactNode } from 'react';
import { Badge, Hidden, List, ListItem, ListItemText } from '@material-ui/core';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { MessengerContainer, ChatsList, stripMessageTextFormatting } from './components';
import { Chat, ChatProps } from './Chat';
import { Chat as ChatT } from './types';
import { useMessager } from './MessagerContext';
import { cropText } from '../primitives/TextHelpers';

export interface IUserRole {
    name: string;
    key: string;
}

export interface IDefaultUser {
    _id: string;
    email: string;
    invited_datetime?: string;
    last_login?: string;
    roles: string[];
    confirmed: boolean;
}

interface ChatListProps extends ChatProps {
    cropLastMessageInList?: number;
    chatListHeader?: ReactNode;
    chatListFilter?: (chat: ChatT) => boolean;
}

export const ChatList = (props: ChatListProps) => {
    const { chats: {list, loading, selectedChat, setSelectedChat} } = useMessager();

    const chatList = <ChatsList>
        <List>
            {props.chatListHeader}
            {list.filter(c => !props.chatListFilter || props.chatListFilter(c)).map(chat => (
                <ListItem button key={chat._id} onClick={() => setSelectedChat(chat)}>
                    <ListItemText 
                        primary={chat.title}
                        secondary={cropText(stripMessageTextFormatting(chat.last_message.text), props.cropLastMessageInList)}
                    />
                    <Badge color="secondary" invisible={!chat.has_unread}>
                        <span />
                    </Badge>
                </ListItem>
            ))}
        </List>
    </ChatsList>;

    return loading? 
        <LoadingIndicator />
        :
        <>
            <Hidden smDown>
                <MessengerContainer chatsWidth={400}>
                    {chatList}
                    {!!selectedChat && <Chat {...props}/>}
                </MessengerContainer>
            </Hidden>
            <Hidden mdUp>
                {selectedChat ? <Chat {...props}/> : chatList}
            </Hidden>
        </>
        ;
}

