import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { PageContainer } from '../../../Pages';
import { ScoringComponentMapping } from '../../ScoringSheets';
import { PartyRoundsList, PartyRoundsListProps } from './PartyRoundsList';
import { ScoreRoundAuthedContainer } from './ScoreRoundAuthedContainer';
import { useArbiterRounds } from './useArbiterRounds';


interface ContainerProps extends Omit<PartyRoundsListProps, 'data'> {
    apiPath: string;
    pageApiPath: string;
    pageSlug: string;
    scorePageSlug: string;
    scoringCustomComponents?: ScoringComponentMapping;
}

/**
 * Grid rounds list page for arbiters.
 * The rounds will be plugged in place of the page component of custom type `rounds`
 * @param props 
 * @returns 
 */
export const ArbiterRoundsListContainer = (props: ContainerProps) => {
    let { path } = useRouteMatch();
    const data = useArbiterRounds(props.apiPath);
    const rounds = <PartyRoundsList {...props} data={data} roundUrl={r => `${path}/${r._id}`} />

    return (
        <Switch>
            <Route exact path={path}>
                <PageContainer
                    apiPath={props.pageApiPath}
                    slug={props.pageSlug}
                    customComponents={{
                        rounds: () => rounds,
                    }}
                />
            </Route>
            <Route path={`${path}/:id`}>
                <ScoreRoundAuthedContainer
                    apiPath={props.apiPath}
                    pageApiPath={props.pageApiPath}
                    pageSlug={props.scorePageSlug}
                    customComponents={props.scoringCustomComponents}
                    goBackButton
                    />
            </Route>
        </Switch>
    )
}
