import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';

interface ItemViewProps {
    marginTop?: string;
    marginLeft?: string;
    firstItemNoMargin?: boolean;
    lastChildMarginRight?: string;
    alignItems?: CSSProperties["alignItems"];
}

const ItemView = styled.div<ItemViewProps>`
    display: flex;
    flex-flow: row;
    align-items: ${props => props.alignItems || 'center'};
    width: 100%;

    & > * {
        margin-left: ${(props: ItemViewProps) => props.marginLeft || '1rem'};
        margin-top: ${(props: ItemViewProps) => props.marginTop || '0.5rem'};
    }
    & > :first-child {
        ${props => props.firstItemNoMargin ? "margin-left: 0;" : ""}
    }
    & > :last-child {
        margin-right: ${(props: ItemViewProps) => props.lastChildMarginRight || '1rem'};
    }
`;

export const OccupyFreeSpace = styled.div`
    flex: 1px 1 1;
`;

interface ActionRowProps {
    itemMarginTop?: string;
    itemMarginLeft?: string;
    firstItemNoMargin?: boolean;
    lastItemMarginRight?: string;
    children?: any;
    alignItems?: CSSProperties["alignItems"];
    style?: Partial<CSSProperties>;
    wrapperProps?: Omit<React.HTMLAttributes<HTMLDivElement>, "style">;
}

export const ActionRow: React.FC<ActionRowProps> = (props: ActionRowProps) => {
    return (
        <ItemView
            {...(props.wrapperProps || {})}
            marginLeft={props.itemMarginLeft}
            marginTop={props.itemMarginTop}
            lastChildMarginRight={props.lastItemMarginRight}
            firstItemNoMargin={props.firstItemNoMargin}
            alignItems={props.alignItems}
            style={props.style || undefined}
        >
            {props.children}
        </ItemView>
    )
}