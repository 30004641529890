import React from 'react';
import styled from '@emotion/styled';
import { Button, Typography } from '@material-ui/core';
import { Chat, SpecificChat, useSpecificChat } from '../../toolympus/components/Chats';
import { Chat as ChatT } from '../../toolympus/components/Chats/types';
import { FormControl } from '../../toolympus/components/schemed';
import { FieldType } from '../../toolympus/hooks/useSchema';
import { PageWrapper } from '../Common.styles';
import { useParams } from 'react-router-dom';

export const TeamChatWithOrgPage = () => {
    return (
        <SingleChatWithOrg tip="Здесь вы можете задать вопрос Организатору Конкурса. Вся переписка будет вестить и сохраняться на этой странице." />
    );
}

export const JudgeChatWithOrgPage = () => {
    return (
        <SingleChatWithOrg tip="Здесь Вы можете задать вопрос Организатору Конкурса. Вся переписка будет вестить и сохраняться на этой странице." />
    );
}

const StartChatWrapper = styled.div`
    & > div {
        display: flex;
        flex-flow: row;
        gap: 12px;
        align-items: flex-end;

        & > :nth-child(1) {
            flex-grow: 1;
        }
        & > :nth-child(2) {
            height: max-content;
        }
    }
`;

interface SpecificChatPageProps {
    data: SpecificChat;
    createTip: string;
    allowMarkdownEditor?: boolean;
    allowEditMessage?: boolean;
}

const SpecificChatPage = ({ data, createTip, allowMarkdownEditor, allowEditMessage }: SpecificChatPageProps) => {
    const { messageForCreate, setMessageForCreate, chat, startChat } = data;
    
    return <PageWrapper>
        {chat && <Chat hideTitleRow allowMarkdownEditor={allowMarkdownEditor} allowEditMessage={allowEditMessage} />}
        {!chat && (<StartChatWrapper>
            <Typography>{createTip}</Typography>
            <div>
                <FormControl
                    field="message"
                    schema={{ type: FieldType.textlong, label: "Сообщение" }}
                    row={{ message: messageForCreate }}
                    onChange={(o,c) => setMessageForCreate(c.message)}
                    />
                <Button variant="contained" color="primary" onClick={startChat} disabled={messageForCreate.length < 10}>
                    Отправить
                </Button>
            </div>
        </StartChatWrapper>)}
    </PageWrapper>;
}

const isOrgChat = (c: ChatT) => c.kind === "org";

interface SingleChatWithOrgProps {
    tip?: string;
}

const SingleChatWithOrg = ({ tip }: SingleChatWithOrgProps) => {
    const data = useSpecificChat(isOrgChat, {
        kindForStart: "org",
        participantsForStart: [],
    });

    return <SpecificChatPage
        data={data}
        createTip={tip || "На этой странице вы можете задать вопрос организатору Конкурса. Вся переписка будет вестить и сохраняться на этой странице."} />;
}

export const OrgChatWithOrgPage = () => {
    const { id } = useParams<{ id: string }>();
    const data = useSpecificChat(c => isOrgChat(c) && c.participants && !!c.participants.find(p => p._id === id), {
        kindForStart: "org",
        participantsForStart: [id],
    });

    return <SpecificChatPage
        data={data}
        createTip="Отправьте сообщение, чтобы начать чат с участником. Вся переписка будет вестить и сохраняться на этой странице. Участник получит уведомление о новом сообщении на Email."
        allowMarkdownEditor
        />;
}
