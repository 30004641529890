import React from 'react';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import ErrorBoundary from './toolympus/components/app/ErrorBoundary';
import AppRouter from './AppRouter';
import { GlobalStyles, theme } from './theme';
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import LocalizationProvider from './toolympus/components/localization/LocalizationProvider';
import { LoginPage } from './components/Public/LoginPage';
import { FormatsProvider } from './toolympus/components/schemed';
import { TeamRegistrationPage } from './components/Public/TeamRegistrationPage';
import { JudgeRegistrationPage } from './components/Public/JudgeRegistrationPage';
import { SchemaProvider } from './toolympus/hooks/useSchema';
import { femidaMessages } from './components/femidaMessages';
import { PublicInfoPage } from './components/Public/PublicInfoPage';
import { PasswordResetPage } from './components/Public/PasswordResetPage';
import { PasswordRestorePage } from './components/Public/PasswordRestorePage';
import { InviteSignupPage } from './components/Public/InviteSignupPage';
import { GridSettingsProvider } from './toolympus/components/Contests/Grid';
import { SecretaryRegistrationPage } from './components/Public/SecretaryRegistrationPage';
import { FemidaGridSettings } from './components/Admin/Finals/FemidaGridSettings';

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <StylesProvider injectFirst >
                <LocalizationProvider locale="ru" defaultLocale="ru" messages={femidaMessages}>
                    <SchemaProvider>
                        <FormatsProvider>
                            <Router>
                                <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                                    <ErrorBoundary>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <GridSettingsProvider settings={FemidaGridSettings}>
                                                <GlobalStyles />
                                                <Switch>
                                                    <Route path='/info' component={PublicInfoPage} />
                                                    <Route path='/register-team' component={TeamRegistrationPage} />
                                                    <Route path='/register-judge' component={JudgeRegistrationPage} />
                                                    <Route path='/register-secretary' component={SecretaryRegistrationPage} />
                                                    <Route path='/password-reset' component={PasswordResetPage} />
                                                    <Route path='/password-restore' component={PasswordRestorePage} />
                                                    <Route path='/invite-signup' component={InviteSignupPage} />
                                                    <Route path='/login' component={LoginPage} />
                                                    <Route path='/' component={AppRouter} />
                                                </Switch>
                                            </GridSettingsProvider>
                                        </MuiPickersUtilsProvider>
                                    </ErrorBoundary>
                                </SnackbarProvider>
                            </Router>
                        </FormatsProvider>
                    </SchemaProvider>
                </LocalizationProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}

export default App;